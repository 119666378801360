import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  buttonSave: {
    minHeight: 43,
    fontSize: 14,
    fontWeight: 'bold',
    opacity: 1,
    '& svg': {
      marginRight: 5,
    },
    '&:hover, &:focus': {
      backgroundColor: theme.palette.common.lightbluehover,
      opacity: 0.8,
    },
    '&:disabled': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.common.lightblue,
      opacity: 0.5,
    },
  },
  issues: {
    color: theme.palette.common.red,
    position: 'absolute !important' as any,
    right: 355,
    top: -3,
  },
  unsavedChanges: {
    position: 'absolute !important' as any,
    right: 355,
    top: -3,
  },
}));
