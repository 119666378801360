import isHotkey from 'is-hotkey';
import React, { useCallback, useMemo } from 'react';
import { createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, Slate, withReact } from 'slate-react';
import { BsJustifyLeft, BsJustifyRight, BsTextCenter, BsJustify, BsTypeBold, BsTypeItalic, BsTypeUnderline, BsCode, BsChatQuote, BsListOl, BsListUl } from 'react-icons/bs';
import { Descendant } from 'slate/dist/interfaces/node';
import { useFormatMessage } from 'apps/intl';
import { deserialize, ElementTypes, EMPTY_TEXT_EDITOR, HOTKEYS, MarkFormatTypes, serialize, TextAlignTypes, toggleMark } from '../../models/TextEditor.model';
import { BlockButton } from '../BlockButton/BlockButton';
import { Element } from '../Element/Element';
import { Leaf } from '../Leaf/Leaf';
import { MarkButton } from '../MarkButton/MarkButton';
import { Toolbar } from '../Toolbar/Toolbar';
import { ReactComponent as H1SVG } from '../../assets/type-h1.svg';
import { ReactComponent as H2SVG } from '../../assets/type-h2.svg';
import { useStyles } from './TextEditor.style';

export function TextEditor({ onHTMLChange, html }: {
  onHTMLChange: (html: string) => void;
  html: string;
}) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  return (
    <div className={classes.editor}>
      <Slate
        editor={editor}
        value={html ? deserialize(new DOMParser().parseFromString(html, 'text/html').body) as Descendant[] : EMPTY_TEXT_EDITOR}
        onChange={(descendant) => {
          const isAstChange = editor.operations.some(
            (op) => op.type !== 'set_selection',
          );
          if (isAstChange) {
            onHTMLChange(
              descendant
                .map((n) => serialize(n))
                .join('')
                .toString(),
            );
          }
        }}
      >
        <Toolbar>
          <MarkButton format={MarkFormatTypes.Bold} icon={<BsTypeBold fontSize={18} />} />
          <MarkButton format={MarkFormatTypes.Italic} icon={<BsTypeItalic fontSize={18} />} />
          <MarkButton format={MarkFormatTypes.Underline} icon={<BsTypeUnderline fontSize={18} />} />
          <MarkButton format={MarkFormatTypes.Code} icon={<BsCode fontSize={18} />} />
          <BlockButton format={ElementTypes.HeadingOne} icon={<H1SVG fontSize={18} />} />
          <BlockButton format={ElementTypes.HeadingTwo} icon={<H2SVG fontSize={18} />} />
          <BlockButton format={ElementTypes.BlockQuote} icon={<BsChatQuote fontSize={18} />} />
          <BlockButton format={ElementTypes.NumberedList} icon={<BsListOl fontSize={18} />} />
          <BlockButton format={ElementTypes.BulletedList} icon={<BsListUl fontSize={18} />} />
          <BlockButton format={TextAlignTypes.Left} icon={<BsJustifyLeft fontSize={18} />} />
          <BlockButton format={TextAlignTypes.Center} icon={<BsTextCenter fontSize={18} />} />
          <BlockButton format={TextAlignTypes.Right} icon={<BsJustifyRight fontSize={18} />} />
          <BlockButton format={TextAlignTypes.Justify} icon={<BsJustify fontSize={18} />} />
        </Toolbar>
        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder={formatMessage('TextEditor.placeholder')}
          spellCheck
          onKeyDown={(event) => {
            // eslint-disable-next-line no-restricted-syntax
            for (const hotkey in HOTKEYS) {
              if (isHotkey(hotkey, event)) {
                event.preventDefault();
                const mark = HOTKEYS[hotkey];
                toggleMark(editor, mark);
              }
            }
          }}
        />
      </Slate>
    </div>
  );
}
