import { makeStyles } from '@material-ui/core/styles';

export const drawerWidth = 220;
export const whiteDrawerWidth = 96;

export const useStyles = makeStyles((theme) => ({
  metamapLogo: {
    height: 40,
    width: whiteDrawerWidth,
  },
  drawerWhite: {
    width: whiteDrawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    backgroundColor: theme.palette.common.white,
    '& .MuiDrawer-paper': {
      overflowX: 'hidden',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    '& .MuiDrawer-paperAnchorDockedLeft': {
      borderRight: 0,
    },
  },
  grid: {
    flexWrap: 'nowrap !important' as 'nowrap',
    height: '100%',
  },
  contentTop: {
    flex: '1 0 auto',
  },
  contentBottom: {
    flex: '0 0 auto',
  },
}));
