import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { FiCalendar, FiCheckCircle, FiX } from 'react-icons/fi';
import { VscWarning } from 'react-icons/vsc';
import { formatDate } from 'lib/date';
import { selectUserHistory } from 'apps/ProfileInsights/store/ProfileInsights.selectors';
import { useFormatMessage } from 'apps/intl';
import { useSelector } from 'react-redux';
import { Loadable } from 'models/Loadable.model';
import { useStyles } from './UserHistory.styles';
import { IProfileHistoryResponse, IProfileHistory } from '../../model/ProfileInsights.model';

export const UserHistory = () => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const userHistoryData = useSelector<any, Loadable<IProfileHistoryResponse>>(selectUserHistory);
  const userHistory: IProfileHistory = userHistoryData?.value?.userHistory;

  return (
    <Box mt={2}>
      <Box>
        <Typography
          variant="subtitle1"
          className={classes.title}
        >
          {formatMessage('ProfileInsights.userHistory.firstVerified')}
        </Typography>
        <Box mt={1} display="flex" alignItems="center">
          <FiCalendar className={classes.firstVerified} />
          <Typography variant="subtitle2" className={classes.subTitle}>
            {formatDate(
              userHistory?.dateOfFirstVerification,
            )}
          </Typography>
        </Box>
      </Box>
      <Box mt={2}>
        <Typography
          variant="subtitle1"
          className={classes.title}
        >
          {formatMessage(
            'ProfileInsights.userHistory.overalVerificationResult',
          )}
          {' '}
          -
          {' '}
          {userHistory?.totalVerifications}
          {' '}
          {formatMessage(
            'ProfileInsights.userHistory.total',
          )}
        </Typography>
        <Box mt={1} display="flex" justifyContent="space-between">
          <Box display="flex" flexDirection="column">
            <Typography
              variant="subtitle2"
              className={classes.title}
            >
              {formatMessage('ProfileInsights.userHistory.verified')}
            </Typography>
            <Box mt={1} display="flex" alignItems="center">
              <FiCheckCircle className={classes.success} size={20} />
              <Typography variant="subtitle2" className={classes.subTitle}>
                {userHistory?.totalVerified}
                {' '}
                {formatMessage('ProfileInsights.userHistory.verified.times')}
              </Typography>
            </Box>
          </Box>

          <Box display="flex" flexDirection="column">
            <Typography
              variant="subtitle2"
              className={classes.title}
            >
              {formatMessage('ProfileInsights.userHistory.toalManualReview')}
            </Typography>
            <Box mt={1} display="flex" alignItems="center">
              <VscWarning className={classes.manalReview} size={20} />
              <Typography variant="subtitle2" className={classes.subTitle}>
                {userHistory?.totalManualReview}
                {' '}
                {formatMessage('ProfileInsights.userHistory.verified.times')}
              </Typography>
            </Box>
          </Box>

          <Box display="flex" flexDirection="column">
            <Typography
              variant="subtitle2"
              className={classes.title}
            >
              {formatMessage('ProfileInsights.userHistory.totalRejected')}
            </Typography>
            <Box mt={1} display="flex" alignItems="center">
              <FiX className={classes.rejected} size={20} />
              <Typography variant="subtitle2" className={classes.subTitle}>
                {userHistory?.totalRejected}
                {' '}
                {formatMessage('ProfileInsights.userHistory.verified.times')}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      {userHistory?.totalNumberOfMerchants > 0 && (
        <Box mt={2}>
          <Typography
            variant="subtitle1"
            className={classes.title}
          >
            {formatMessage('ProfileInsights.userHistory.verifiedWith')}
          </Typography>
          <Box mt={1} display="flex" alignItems="center">
            <FiCheckCircle className={classes.success} />
            <Typography variant="subtitle2" className={classes.subTitle}>
              {userHistory?.totalNumberOfMerchants}
              {' '}
              {formatMessage('ProfileInsights.userHistory.verifiedWith.subText')}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};
