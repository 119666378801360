import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectUserEmail, selectUserFirstName, selectUserLastName } from 'apps/user/state/user.selectors';
import { selectUserCountry } from '../../../state/merchant/merchant.selectors';
import { ChiliPiperConfiguration } from '../models/ChiliPiper.model';
import { useFormatMessage } from 'apps/intl';

export function useChiliPiper(): () => void {
  const formatMessage = useFormatMessage();
  const userEmail = useSelector(selectUserEmail);
  const userFirstName = useSelector(selectUserFirstName) || ' ';
  const userLastName = useSelector(selectUserLastName) || ' ';
  const country = useSelector(selectUserCountry);
  const userCountryCode = (country?.length && country[0]) || ' ';

  const shouldLoadChiliPiper = process.env.NODE_ENV === 'production';

  const chiliPiperSubmit = useCallback(() => {
    if (shouldLoadChiliPiper) {
    // @ts-ignore
      window.ChiliPiper.submit(ChiliPiperConfiguration.Domain, ChiliPiperConfiguration.Router, {
        lead: {
          FirstName: userFirstName,
          LastName: userLastName,
          Email: userEmail,
          Country: formatMessage(`Countries.${userCountryCode}`),
        },
      });
    }
  }, [userFirstName, userLastName, userEmail, userCountryCode, shouldLoadChiliPiper, formatMessage]);
  return chiliPiperSubmit;
}
