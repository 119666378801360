import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { FiMail, FiPhone, FiUserCheck, FiSettings, FiFileText, FiCreditCard, FiBriefcase, FiMapPin, FiDollarSign, FiUser, FiPenTool, FiFlag, FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { CircledContent } from 'apps/ui';
import { useFormatMessage } from 'apps/intl';
import { IProfileMeritResponse, ProfileMeritTypes, VERIFIED_MERITS_PER_PAGE } from 'apps/ProfileInsights/model/ProfileInsights.model';
import { Loadable } from 'models/Loadable.model';
import { useStyles } from './UserMerits.styles';
import { selectUserMerits } from '../../store/ProfileInsights.selectors';
import { BankLogo } from '../../../BankAccountData/components/BankLogo/BankLogo';

export const UserMerits = () => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();

  const userMeritData = useSelector<any, Loadable<IProfileMeritResponse>>(selectUserMerits);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentMeritList, setCurrentMeritList] = useState<ProfileMeritTypes[]>([]);

  const paginatedMeritList = useMemo<ProfileMeritTypes[][]>(() => {
    if (!userMeritData.isLoaded) return [];

    const knownMerits: ProfileMeritTypes[] = userMeritData.value?.uniqueMerits.filter((merit) => Object.values(ProfileMeritTypes).includes(merit));

    if (knownMerits.length < userMeritData.value?.uniqueMerits.length) {
      knownMerits.push(ProfileMeritTypes.Other);
    }

    const paginatedMerits: ProfileMeritTypes[][] = knownMerits.reduce((acc, merit, idx) => {
      const index = Math.floor(idx / VERIFIED_MERITS_PER_PAGE);
      const pages = acc;
      pages[index] = pages[index] || [];
      pages[index].push(merit);
      return pages;
    }, []);

    return paginatedMerits;
  }, [userMeritData]);

  useEffect(() => {
    if (paginatedMeritList.length) {
      setCurrentMeritList(paginatedMeritList[currentPage - 1]);
    }
  }, [paginatedMeritList, currentPage, currentMeritList]);

  // Update Mapping
  const getMeritIcon = (merit: ProfileMeritTypes) => {
    switch (merit) {
      case ProfileMeritTypes.PhoneCheck:
        return <FiPhone />;
      case ProfileMeritTypes.EmailCheck:
        return <FiMail />;
      case ProfileMeritTypes.CustomDocument:
        return <FiSettings />;
      case ProfileMeritTypes.DocumentVerification:
        return <FiFileText />;
      case ProfileMeritTypes.CreditCheck:
        return <FiCreditCard />;
      case ProfileMeritTypes.LocationIntelligence:
        return <FiMapPin />;
      case ProfileMeritTypes.BiometricVerification:
        return <FiUserCheck />;
      case ProfileMeritTypes.AntiMoneyLaundering:
        return <FiDollarSign />;
      case ProfileMeritTypes.FaceMatch:
        return <FiUser />;
      case ProfileMeritTypes.BankAccountData:
        return <BankLogo />;
      case ProfileMeritTypes.ESignature:
        return <FiPenTool />;
      case ProfileMeritTypes.GovernmentCheck:
        return <FiFlag />;
      default:
        return <FiUserCheck />;
    }
  };

  const totalPages = paginatedMeritList.length;
  const isPreviousPageDisabled = currentPage === 1 || totalPages === 1;
  const isNextPageDisabled = totalPages === 1 || currentPage === totalPages;

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  return (
    <Box>
      <Box className={classes.merits} mt={2}>
        {currentMeritList.map((merit) => (
          <Box display="flex" alignItems="center" flexDirection="column" key={merit}>
            <CircledContent width="32px" height="32px">
              {getMeritIcon(merit)}
            </CircledContent>
            <Typography className={classes.title}>
              {formatMessage(`ProfileInsights.profileMerit.${merit}`)}
            </Typography>
          </Box>
        ))}
      </Box>

      <Box mt={2} display="flex" justifyContent="center" alignItems="center" className={classes.titleBoldMain}>
        <Box mr={1}>
          <IconButton disabled={isPreviousPageDisabled} onClick={handlePreviousPage}>
            <FiChevronLeft className={classNames(classes.paginationIcon, { [classes.paginationIconDisabled]: isPreviousPageDisabled })} />
          </IconButton>
        </Box>
        <Typography variant="h6">{currentPage}</Typography>
        <Box ml={0.5} mr={0.5}>
          <Typography variant="h6">{formatMessage('ProfileInsights.userVerification.of')}</Typography>
        </Box>
        <Typography variant="h6">
          {totalPages}
        </Typography>
        <Box ml={1}>
          <IconButton disabled={isNextPageDisabled} onClick={handleNextPage}>
            <FiChevronRight className={classNames(classes.paginationIcon, { [classes.paginationIconDisabled]: isNextPageDisabled })} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};
