import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { flowBuilderSaveAndPublishForSdkCustomisation, selectFlowBuilderChangeableFlow } from 'apps/flowBuilder';
import { useFormatMessage } from 'apps/intl';
import { difference } from 'lib/object';
import { QATags } from 'models/QA.model';
import { MerchantTagsTypes } from 'models/Merchant.model';
import { selectMerchantTags } from 'state/merchant';
import { AntTab, AntTabs, useStyles } from './SDKCustomization.style';
import { TabPanel } from './TabPanel';
import { ColorCustomization } from './ColorCustomization';
import { LogoCustomization } from './LogoCustomization';
import { TypographyCustomization } from './TypographyCustomization';
import { ReactComponent as InfoWarningIcon } from '../../assets/info-warning.svg';
import { ReactComponent as TagsBetaIcon } from '../../assets/tags-beta.svg';

export function SDKCustomizationTabs() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const tags = useSelector(selectMerchantTags);
  const changeableFlow = useSelector(selectFlowBuilderChangeableFlow);
  const defaultAppearance = {
    properties: [],
    isDisplayedPoweredByMetamap: true,
  };
  const [appearance, setAppearance] = useState({
    ...defaultAppearance,
    ...(changeableFlow.appearance ?? {}),
  });

  const [value, setTabValue] = useState(0);

  const isSaveChangesBtnDisabled = useMemo(() => {
    const diff = difference(appearance, defaultAppearance);
    if (Object.keys(diff).length > 0) {
      return false;
    }
    return true;
  }, [appearance, defaultAppearance]);

  const handleSetToDefaultClick = () => setAppearance(defaultAppearance);

  const handleSaveChangesClick = () => dispatch(flowBuilderSaveAndPublishForSdkCustomisation(formatMessage, {
    ...appearance,
    isDisplayedPoweredByMetamap: tags.includes(MerchantTagsTypes.CanHideMetamapLogo) ? appearance.isDisplayedPoweredByMetamap : true,
  }, changeableFlow.name));

  return (
    <Box
      height="100%"
      className={classes.root}
    >
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        className={classes.tabsWrapper}
      >
        <AntTabs
          value={value}
          onChange={(event, newValue) => setTabValue(newValue)}
          aria-label="sdk customisation"
        >
          <AntTab
            data-qa={QATags.FlowBuilder.PreviewModal.SDKCustomisationForm.tabs.colorAndStyling}
            label={formatMessage('FlowBuilder.previewModal.SDKCustomisationForm.tabs.ColorAndStyling')}
            icon={(value === 0) ? <TagsBetaIcon className={classes.tagsBeta} /> : null}
          />
          <AntTab
            data-qa={QATags.FlowBuilder.PreviewModal.SDKCustomisationForm.tabs.logo}
            label={formatMessage('FlowBuilder.previewModal.SDKCustomisationForm.tabs.Logo')}
          />
          <AntTab
            data-qa={QATags.FlowBuilder.PreviewModal.SDKCustomisationForm.tabs.typography}
            label={formatMessage('FlowBuilder.previewModal.SDKCustomisationForm.tabs.Typography')}
          />
        </AntTabs>
        <Box flexGrow={1}>
          <TabPanel value={value} index={0}>
            <ColorCustomization
              appearance={appearance}
              onChange={setAppearance}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <LogoCustomization
              appearance={appearance}
              onChange={setAppearance}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <TypographyCustomization
              appearance={appearance}
              onChange={setAppearance}
            />
          </TabPanel>
        </Box>
        <Box
          className={classes.bottomSectionContainer}
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
        >
          {(value === 0) && (
            <Box className={classes.freeTrialBanner}>
              <InfoWarningIcon className={classes.freeTrialBannerInfoIcon} />
              <Box component="span" className={classes.freeTrialBannerText}>
                {formatMessage('FlowBuilder.previewModal.SDKCustomisationForm.missingFeaturesDisclaimer')}
              </Box>
            </Box>
          )}
          <Box>
            <Button
              disableFocusRipple
              disableTouchRipple
              disableRipple
              type="button"
              className={classes.bottomSectionSetToDefaultBtn}
              onClick={handleSetToDefaultClick}
              data-qa={QATags.FlowBuilder.PreviewModal.SDKCustomisationForm.cta.setToDefault}
            >
              {formatMessage('FlowBuilder.previewModal.SDKCustomisationForm.cta.SetToDefault')}
            </Button>
            <Button
              disableFocusRipple
              disableTouchRipple
              disableRipple
              variant="outlined"
              type="button"
              className={(value === 0 && isSaveChangesBtnDisabled) ? `${classes.disabled} ${classes.bottomSectionSaveChangesBtn}` : classes.bottomSectionSaveChangesBtn}
              onClick={handleSaveChangesClick}
              disabled={(value === 0) ? isSaveChangesBtnDisabled : false}
              data-qa={QATags.FlowBuilder.PreviewModal.SDKCustomisationForm.cta.saveChanges}
            >
              {formatMessage('FlowBuilder.previewModal.SDKCustomisationForm.cta.SaveChanges')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
