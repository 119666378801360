import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  profileInsightButton: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.whiteblue,
    width: '100%',
    height: 52,
    fontSize: 14,
  },
  labelIcon: {
    flexShrink: 0,
    width: 17,
    height: 17,
    marginRight: 10,
    '& path': {
      stroke: theme.palette.primary.main,
    },
  },
}));
