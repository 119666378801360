import React from 'react';
import Box from '@material-ui/core/Box';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import classNames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import { useStyles } from './PreviewGalery.styles';
import { ReactComponent as LeftIcon } from '../../assets/icon-left.svg';
import { ReactComponent as RightIcon } from '../../assets/icon-right.svg';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';

SwiperCore.use([Pagination, Navigation]);

export function PreviewGallery({ screenshots }: {
  screenshots: Array<string>;
}) {
  const classes = useStyles();

  return (
    <Swiper
      navigation={{
        nextEl: '.navigation-right',
        prevEl: '.navigation-left',
        disabledClass: classes.navigationControlHidden,
        hiddenClass: classes.navigationControlHidden,
      }}
      slidesPerView="auto"
      slidesPerGroup={1}
      centeredSlides={false}
      spaceBetween={0}
      className={classes.swiper}
      allowTouchMove={false}
      grabCursor={false}
    >
      {screenshots?.map((screenshot) => (
        !!screenshot.length
        && (
          <SwiperSlide className={classes.swiperSlide} key={screenshot}>
            <CardActionArea>
              <CardMedia
                component="img"
                image={screenshot}
                height="540"
                className={classes.card}
              />
            </CardActionArea>
          </SwiperSlide>
        )
      ))}
      <Box className={classes.controlsContainer}>
        <Box className={classNames(classes.navigationControlLeft, 'navigation-left')}>
          <LeftIcon />
        </Box>
        <Box className={classNames(classes.navigationControlRight, 'navigation-right')}>
          <RightIcon />
        </Box>
      </Box>
    </Swiper>
  );
}
