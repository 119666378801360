import { MerchantTagsTypes } from 'models/Merchant.model';
import { selectMerchantTags } from 'state/merchant';
import { createSelector } from 'reselect';
import { Loadable } from 'models/Loadable.model';
import { IProfileInsightsStore, ProfileInsightsSliceTypes, PROFILE_INSIGHTS_STORE_KEY } from './ProfileInsights.store';
import { IProfileContactReponse, IProfileHistoryResponse, IProfileMeritResponse, IProfileVerificationHistoryResponse, IProfileRiskAssessmentResponse } from '../model/ProfileInsights.model';

export const profileInsightStore = (state: {
  IDENTITY_PROFILE_STORE_KEY: IProfileInsightsStore;
}): IProfileInsightsStore => state[PROFILE_INSIGHTS_STORE_KEY];

export const selectProfileInsightModel = createSelector<
  [typeof profileInsightStore],
  string
>(
  profileInsightStore,
  (store) => store[ProfileInsightsSliceTypes.ProfileInsightId],
);

export const selectProfileInsightState = createSelector<
  [typeof profileInsightStore],
  boolean
>(
  profileInsightStore,
  (store) => store[ProfileInsightsSliceTypes.ProfileInsightSideBar],
);

export const selectUserHistory = createSelector<
  [typeof profileInsightStore],
  Loadable<IProfileHistoryResponse>
>(
  profileInsightStore,
  (store) => store[ProfileInsightsSliceTypes.ProfileInsightUserHistory],
);

export const selectUserMerits = createSelector<
  [typeof profileInsightStore],
  Loadable<IProfileMeritResponse>
>(
  profileInsightStore,
  (store) => store[ProfileInsightsSliceTypes.ProfileInsightUserMerits],
);

export const selectUserContact = createSelector<
  [typeof profileInsightStore],
  Loadable<IProfileContactReponse>
>(
  profileInsightStore,
  (store) => store[ProfileInsightsSliceTypes.ProfileInsightUserContact],
);

export const selectUserVerificationHistory = createSelector<
  [typeof profileInsightStore],
  Loadable<IProfileVerificationHistoryResponse>
>(
  profileInsightStore,
  (store) => store[ProfileInsightsSliceTypes.ProfileInsightVerificationHistory],
);

export const selectCanUseReusage = createSelector<[typeof selectMerchantTags], boolean>(selectMerchantTags, (tags) => tags.includes(MerchantTagsTypes.CanUseReusage));

// Premium insight selectors
export const selectCanSeePremiumInsights = createSelector<[typeof selectMerchantTags], boolean>(selectMerchantTags, (tags) => tags.includes(MerchantTagsTypes.CanSeePremiumInsights));

export const selectProfileRiskAssessment = createSelector<[typeof profileInsightStore], Loadable<IProfileRiskAssessmentResponse>>(
  profileInsightStore,
  (store) => store[ProfileInsightsSliceTypes.ProfileInsightRiskAssessment],
);

export const selectCanUseADFraudScore = createSelector<[typeof selectMerchantTags], boolean>(selectMerchantTags, (tags) => tags.includes(MerchantTagsTypes.CanUseAlterationDetectionFraudScore));
