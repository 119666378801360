import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  subTitle: {
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '17px',
    display: 'inline-block',
    marginLeft: 8,
    color: theme.palette.common.black90,
  },
  title: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14px',
    color: theme.palette.common.black75,
  },
  subTitle2: {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '14px',
  },
  firstVerified: {
    color: theme.palette.common.darkBlue,
  },
  success: {
    color: theme.palette.common.green,
  },
  manalReview: {
    color: theme.palette.common.yellow,
  },
  rejected: {
    color: theme.palette.common.red,
  },
}));
