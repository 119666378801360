import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Routes } from 'models/Router.model';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FiChevronLeft } from 'react-icons/fi';
import { navigation, NavigationEventTypes } from 'apps/layout';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { getGoBackToListLink } from 'models/Identity.model';
import { Loadable } from 'models/Loadable.model';
import { IFlow } from 'models/Flow.model';
import { flowNameValidator } from 'lib/validations';
import { selectMerchantFlowsModel } from 'state/merchant/merchant.selectors';
import { toggleUnsavedChanges } from '../../store/FlowBuilder.action';
import { FlowHeader } from '../FlowHeader/FlowHeader';
import { useStyles } from './FlowInfoHeader.styles';

export function FlowInfoHeader({ newFlowName, setNewFlowName, handleIntegrate }: {
  newFlowName?: string;
  setNewFlowName?: (name: string) => void;
  handleIntegrate?: () => void;
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const location = useLocation();
  const merchantFlowsModel = useSelector<any, Loadable<IFlow[]>>(selectMerchantFlowsModel);
  const goBackToListLink = useMemo(() => getGoBackToListLink(location, Routes.flow.root), [location]);

  const validator = useCallback((text: string) => {
    const duplicate = merchantFlowsModel.value.find((item) => item.name === text.trim());
    return flowNameValidator({ hasDuplicate: !!duplicate, name: text });
  }, [merchantFlowsModel.value]);

  const handleSubmit = (name: string) => {
    setNewFlowName(name);
    dispatch(toggleUnsavedChanges(true));
  };

  const handleCancel = () => {
    setIsEditable(false);
  };

  return (
    <Box className={classes.container}>
      <Grid container alignItems="center" wrap="nowrap">
        <Link
          to={goBackToListLink}
          className={classes.link}
          onClick={(event) => {
            const interrupted = navigation.trigger(NavigationEventTypes.BeforeNavigation, () => history.push(goBackToListLink));
            if (interrupted) {
              event.preventDefault();
            }
          }}
        >
          <FiChevronLeft />
        </Link>
        <Box pr={0.8} width="100%">
          <FlowHeader
            canEdit
            isFlowPage
            isEditable={isEditable}
            newFlowName={newFlowName}
            setIsEditable={setIsEditable}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            validator={validator}
            handleIntegrate={handleIntegrate}
          />
        </Box>
      </Grid>
    </Box>
  );
}
