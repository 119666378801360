import { ProductBaseFlowBuilder } from 'apps/flowBuilder/services/ProductBaseFlowBuilder.service';
import { IFlow } from 'models/Flow.model';
import { Product, ProductInputTypes, ProductSettings, ProductTypes } from 'models/Product.model';
import { VerificationResponse } from 'models/VerificationOld.model';
import { VerificationPatternTypes } from 'models/VerificationPatterns.model';
import { FiUser } from 'react-icons/fi';
import { getStepStatus, IStep, StepStatus } from 'models/Step.model';
import { VerificationPatternsConfigType } from 'models/VerificationPatternsConfigs.model';
import { FacematchSourceTypes } from 'models/Facematch.model';
import { FacematchCheckSettingsTypes, IFacematchCheckStepData, FACEMATCH_DEFAULT_APPROVE_THRESHOLD, FACEMATCH_DEFAULT_REJECT_THRESHOLD, getFacematchSourcesError } from '../models/Facematch.model';
import { FacematchSettings } from '../components/FacematchSettings/FacematchSettings';
import { FacematchVerification } from '../components/FacematchVerification/FacematchVerification';

export class Facematch extends ProductBaseFlowBuilder implements Product {
  verificationPatternsConfig: VerificationPatternsConfigType;

  id = ProductTypes.Facematch;
  order = 700;

  inputs = [
    ProductInputTypes.ImageSources,
  ];

  checks = [];

  constructor({ patternsConfig, disabled }: { patternsConfig: VerificationPatternsConfigType, disabled?: boolean }) {
    super({ disabled });
    const result = {};
    if (patternsConfig) {
      Object.entries(patternsConfig)
        .forEach(([patternName, patternConfigs]) => {
          const isFacematchSource = patternConfigs.some((patternConfig) => patternConfig.isFacematchSource);
          if (isFacematchSource) {
            result[patternName] = patternConfigs;
          }
        });
    }
    this.verificationPatternsConfig = result;
  }

  icon = FiUser;
  component = FacematchSettings;
  componentVerification = FacematchVerification;
  requiredProductType = ProductTypes.BiometricVerification;

  parser(flow: IFlow, productsInGraph: ProductTypes[]): ProductSettings<FacematchCheckSettingsTypes> {
    const { verificationPatterns, verificationSteps, facematchServiceConfig: { sources, approveThreshold, rejectThreshold } } = flow;

    return {
      [FacematchCheckSettingsTypes.FacematchEnabled]: {
        value: verificationPatterns[VerificationPatternTypes.Facematch],
      },
      [FacematchCheckSettingsTypes.FacematchSettings]: {
        value: rejectThreshold,
      },
      [FacematchCheckSettingsTypes.RejectThreshold]: {
        value: rejectThreshold,
      },
      [FacematchCheckSettingsTypes.ApproveThreshold]: {
        value: approveThreshold,
      },
      [FacematchCheckSettingsTypes.Sources]: {
        value: sources,
        error: getFacematchSourcesError(flow, productsInGraph),
      },
      [FacematchCheckSettingsTypes.DocumentTypes]: {
        value: verificationSteps,
      },
      [FacematchCheckSettingsTypes.CountriesGovChecks]: {
        value: verificationPatterns,
      },
      [FacematchCheckSettingsTypes.PatternsConfig]: {
        value: this.verificationPatternsConfig,
      },
    };
  }

  serialize(settings: ProductSettings<FacematchCheckSettingsTypes>): Partial<IFlow> {
    return {
      verificationPatterns: {
        ...(settings[FacematchCheckSettingsTypes.CountriesGovChecks]?.value || {}),
        [VerificationPatternTypes.Facematch]: settings[FacematchCheckSettingsTypes.FacematchEnabled].value,
      },
      facematchServiceConfig: {
        approveThreshold: settings[FacematchCheckSettingsTypes.ApproveThreshold].value,
        rejectThreshold: settings[FacematchCheckSettingsTypes.RejectThreshold].value,
        sources: settings[FacematchCheckSettingsTypes.Sources].value,
      },
    };
  }

  getVerification(verification: VerificationResponse): IStep<IFacematchCheckStepData> {
    const facematchStep = verification.steps.find((step) => step.id === VerificationPatternTypes.Facematch);

    return { ...facematchStep, checkStatus: getStepStatus(facematchStep) };
  }

  isInFlow(flow: IFlow): boolean {
    return flow?.verificationPatterns?.[VerificationPatternTypes.Facematch];
  }

  onAdd(): Partial<IFlow> {
    return {
      facematchServiceConfig: {
        sources: [
          { type: FacematchSourceTypes.Biometrics },
          { type: FacematchSourceTypes.MerchantDatabase },
        ],
        approveThreshold: FACEMATCH_DEFAULT_APPROVE_THRESHOLD,
        rejectThreshold: FACEMATCH_DEFAULT_REJECT_THRESHOLD,
      },
      verificationPatterns: {
        [VerificationPatternTypes.Facematch]: true,
      },
    };
  }

  onAddEmpty(): Partial<IFlow> {
    return {
      facematchServiceConfig: {
        sources: [],
        approveThreshold: FACEMATCH_DEFAULT_APPROVE_THRESHOLD,
        rejectThreshold: FACEMATCH_DEFAULT_REJECT_THRESHOLD,
      },
      verificationPatterns: {
        [VerificationPatternTypes.Facematch]: true,
      },
    };
  }

  onRemove(): Partial<IFlow> {
    return {
      facematchServiceConfig: {
        sources: [],
        approveThreshold: FACEMATCH_DEFAULT_APPROVE_THRESHOLD,
        rejectThreshold: FACEMATCH_DEFAULT_REJECT_THRESHOLD,
      },
      verificationPatterns: {
        [VerificationPatternTypes.Facematch]: false,
      },
    };
  }

  hasFailedCheck(verification: VerificationResponse): boolean {
    const facematchStep = verification?.steps.find((step) => step.id === VerificationPatternTypes.Facematch);
    const facematchStepStatus = getStepStatus(facematchStep);
    return [StepStatus.Incomplete, StepStatus.Failure].includes(facematchStepStatus);
  }
}
