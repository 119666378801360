import React from 'react';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { useFormatMessage } from 'apps/intl';
import { QATags } from 'models/QA.model';
import { ProfileInsightFeedbackURL } from '../../model/ProfileInsights.model';
import { useStyles } from './FeedbackLink.styles';

export const FeedbackLink = () => {
  const formatMessage = useFormatMessage();
  const classes = useStyles();
  return (
    <Box p={1} className={classes.feedbackLink}>
      <Link data-qa={QATags.ProfileInsights.FeedbackLink} href={ProfileInsightFeedbackURL} target="_blank">
        {formatMessage('ProfileInsights.feedback')}
      </Link>
    </Box>
  );
};
