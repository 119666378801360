import { Loadable } from 'models/Loadable.model';
import { createTypesSequences, TypesSequences } from 'state/store.utils';
import { IProfileContactReponse, IProfileHistoryResponse, IProfileMeritResponse, IProfileVerificationHistoryResponse, IProfileRiskAssessmentResponse } from '../model/ProfileInsights.model';

export const PROFILE_INSIGHTS_STORE_KEY = 'profileInsights';

export enum ProfileInsightsSliceTypes {
  ProfileInsightSideBar = 'profileInsightSideBar',
  ProfileInsightId = 'profileInsightId',
  ProfileInsightUserHistory = 'profileInsightUserHistory',
  ProfileInsightUserContact='profileInsightUserContact',
  ProfileInsightUserMerits='profileInsightUserMerits',
  ProfileInsightVerificationHistory='profileInsightVerificationHistory',
  ProfileInsightRiskAssessment = 'profileInsightRiskAssessment',
}

export interface IProfileInsightsStore {
  [ProfileInsightsSliceTypes.ProfileInsightSideBar]: boolean;
  [ProfileInsightsSliceTypes.ProfileInsightId]: string;
  [ProfileInsightsSliceTypes.ProfileInsightUserMerits]: Loadable<IProfileMeritResponse>;
  [ProfileInsightsSliceTypes.ProfileInsightUserHistory]: Loadable<IProfileHistoryResponse>;
  [ProfileInsightsSliceTypes.ProfileInsightUserContact]: Loadable<IProfileContactReponse>;
  [ProfileInsightsSliceTypes.ProfileInsightVerificationHistory]: Loadable<IProfileVerificationHistoryResponse>;
  [ProfileInsightsSliceTypes.ProfileInsightRiskAssessment]: Loadable<IProfileRiskAssessmentResponse>;
}

export const ProfileInsightsActionTypes: TypesSequences<typeof ProfileInsightsSliceTypes> & {
  OPEN_PROFILE_INSIGHT: string;
  CLOSE_PROFILE_INSIGHT: string;
  ADD_PROFILE_INSIGHT_ID: string;
  CLEAR_PROFILE_INSIGHT_ID: string;
} = {
  ...createTypesSequences(ProfileInsightsSliceTypes),
  OPEN_PROFILE_INSIGHT: 'OPEN_PROFILE_INSIGHT',
  CLOSE_PROFILE_INSIGHT: 'CLOSE_PROFILE_INSIGHT',
  ADD_PROFILE_INSIGHT_ID: 'ADD_PROFILE_INSIGHIT_ID',
  CLEAR_PROFILE_INSIGHT_ID: 'CLEAR_PROFILE_INSIGHT_ID',
};
