import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import { appPalette } from 'apps/theme/app.palette';
import React, { ReactNode } from 'react';
import { IconType } from 'react-icons';
import { useFormatMessage } from 'apps/intl';
import { useMerchantPlan } from 'apps/merchant';
import { FiActivity, FiAlertCircle, FiLoader } from 'react-icons/fi';
import { FreshChatWidget } from 'apps/freshdesk';
import { WarningSize, WarningTypes } from '../../models/Warning.model';
import { useStyles } from './Warning.styles';

const IconMap = {
  [WarningTypes.Warning]: FiAlertCircle,
  [WarningTypes.ImportantWarning]: FiAlertCircle,
  [WarningTypes.Success]: FiActivity,
  [WarningTypes.Error]: FiActivity,
  [WarningTypes.Notify]: FiLoader,
  [WarningTypes.Checking]: FiLoader,
};

const ColorMap = {
  [WarningTypes.Warning]: {
    color: appPalette.yellow,
    titleColor: appPalette.yellow,
    iconColor: appPalette.yellow,
  },
  [WarningTypes.ImportantWarning]: {
    color: appPalette.red,
    titleColor: appPalette.red,
    iconColor: appPalette.red,
  },
  [WarningTypes.Error]: {
    color: appPalette.red,
    titleColor: appPalette.red,
    iconColor: appPalette.red,
  },
  [WarningTypes.Success]: {
    color: appPalette.green,
    titleColor: appPalette.black75,
    iconColor: appPalette.green,
  },
  [WarningTypes.Notify]: {
    color: appPalette.whiteblue,
    titleColor: appPalette.lightblue,
    iconColor: appPalette.lightblue,
  },
};

export function Warning({ title = '', label, type = WarningTypes.Warning, size = WarningSize.Normal, isLabelColored = false, bordered = false, className = '', filled, linkLabel = '', link = '', isIconExist = true, icon, meritName }: {
  title?: string;
  label: string | ReactNode;
  type?: WarningTypes;
  size?: WarningSize;
  filled?: boolean;
  className?: string;
  isLabelColored?: boolean;
  bordered?: boolean;
  linkLabel?: string;
  link?: string;
  isIconExist?: boolean;
  icon?: IconType;
  meritName?: string;
}) {
  const Icon = icon || IconMap[type];
  const formatMessage = useFormatMessage();
  const style = ColorMap[type];
  const classes = useStyles({ bordered, filled, color: style.color });
  const { isFreemium } = useMerchantPlan();

  const onClickActivateMerit = () => {
    FreshChatWidget.createNewMessage(formatMessage('Intercom.enable.merit.message', { messageValues: { name: meritName } }));
  };

  const isFreshDeskInit = FreshChatWidget.isFreshDeskInit();

  return (
    <Box className={`${classes.root} ${className}`}>
      {isIconExist && (
        <Box className={classes.icon}>
          <Icon size={size} color={style.iconColor} />
        </Box>
      )}
      <Box className={classes.content}>
        {title && (
          <Typography variant="h4" gutterBottom style={{ color: style.titleColor }}>
            {title}
          </Typography>
        )}
        {isFreemium ? (
          <Box style={{ color: isLabelColored && style.titleColor }}>
            {label}
          </Box>
        ) : (
          <>
            <Box style={{ color: isLabelColored && style.titleColor }}>
              {isFreshDeskInit && !!meritName ? (
                <Box className={classes.intercomBox}>
                  <Box onClick={onClickActivateMerit} className={classes.supportLink}>{formatMessage('Footer.support')}</Box>
                  {formatMessage('Product.warnings.intercomInfo')}
                </Box>
              ) : label}
            </Box>
            {linkLabel && (!isFreshDeskInit || !meritName) && (
              <Box style={{ color: isLabelColored && style.titleColor }}>
                <Link underline="always" href={link}>{linkLabel}</Link>
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
}
