import { notification } from 'apps/ui';
import { downloadBlob } from 'lib/file';
import { QATags } from 'models/QA.model';
import React, { useCallback } from 'react';
import { FiDownload } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { downloadCSV } from 'state/identities/identities.actions';
import { useFormatMessage } from 'apps/intl';
import { selectCsvIsDownloading } from '../../state/Csv.selectors';
import { csvDownloadingFinished, csvDownloadingStarted } from '../../state/Csv.action';
import { ButtonSide } from './DownloadCSV.styles';

export function DownloadCSV() {
  const dispatch = useDispatch();
  const formatMessage = useFormatMessage();
  const isDownloading = useSelector<any, boolean>(selectCsvIsDownloading);

  const handleDownloadCSV = useCallback(async () => {
    if (!isDownloading) {
      dispatch(csvDownloadingStarted());
      const notificationId = notification.spinner(formatMessage('csv.loading'));
      const response = await dispatch(downloadCSV()) as any;
      dispatch(csvDownloadingFinished());
      notification.dismiss(notificationId);
      if (!response) {
        return;
      }
      const blob = new Blob([response.data]);
      downloadBlob(blob, 'mati-verifications.zip');
    }
  }, [dispatch, formatMessage, isDownloading]);

  return (
    <ButtonSide
      variant="contained"
      onClick={handleDownloadCSV}
      startIcon={<FiDownload />}
      disabled={isDownloading}
      data-qa={QATags.VerificationList.DownloadCsv}
    >
      {formatMessage('identities.download-all-csv')}
    </ButtonSide>
  );
}
