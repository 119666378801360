import { MerchantTagsTypes } from 'models/Merchant.model';
import { useSelector } from 'react-redux';
import { selectMerchantTags } from 'state/merchant';

export function useIsMerchantTagEnabled(tagsOrTag: MerchantTagsTypes | MerchantTagsTypes[]): boolean[] {
  const merchantTags = useSelector(selectMerchantTags);
  if (typeof tagsOrTag === 'string') {
    return [merchantTags.includes(tagsOrTag)];
  }
  return tagsOrTag.map((tag) => merchantTags.includes(tag));
}
