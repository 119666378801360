import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  merits: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '33px',
    padding: '12px 0px',
  },
  title: {
    marginTop: 8,
    fontWeight: 500,
    fontSize: 14,
    textAlign: 'center',
    color: theme.palette.common.darkgrey,
  },
  paginationIcon: {
    width: 20,
    height: 20,
  },
  paginationIconDisabled: {
    color: theme.palette.common.whiteblue,
  },
  titleBoldMain: {
    fontSize: 15,
    fontWeight: 'bold',
    color: theme.palette.common.black75,
  },
}));
