import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  name: {
    maxWidth: '90%',
    wordBreak: 'break-word',
  },
  field: {
    flexWrap: 'nowrap',
    '& .MuiIconButton-root': {
      minHeight: 'auto',
      fontSize: 20,
    },
    '&:hover': {
      color: theme.palette.common.lightblue,
    },
  },
  editField: {
    cursor: 'pointer',
    alignItems: 'center',
    width: 556,
    '& button': {
      display: 'none',
    },
    '&:hover': {
      '& button': {
        display: 'block',
      },
    },
  },
  button: {
    marginRight: 4,
    padding: 0,
  },
  settingsButton: {
    borderColor: theme.palette.common.whiteblue,
    color: theme.palette.common.lightblue,
    minWidth: 120,
    height: 43,
    minHeight: 43,
    marginRight: 10,
    fontSize: 14,
    fontWeight: 'bold',
    '& svg': {
      marginRight: 5,
    },
  },
  integrateButton: {
    borderColor: theme.palette.common.whiteblue,
    color: theme.palette.common.lightblue,
    minWidth: 120,
    height: 43,
    minHeight: 43,
    marginLeft: 10,
    fontSize: 14,
    fontWeight: 'bold',
    '& svg': {
      marginRight: 5,
    },
  },
  historyButton: {
    borderColor: theme.palette.common.whiteblue,
    color: theme.palette.common.lightblue,
    minWidth: 120,
    height: 43,
    minHeight: 43,
    marginLeft: 10,
    fontSize: 14,
    fontWeight: 'bold',
    '& svg': {
      marginRight: 5,
    },
  },
  upgradeButton: {
    minHeight: 43,
    fontSize: 14,
    fontWeight: 'bold',
    marginRight: 5,
  },
  modal: {
    [theme.breakpoints.up('lg')]: {
      width: 725,
    },
  },
}));
