import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  colorItemLabel: {
    '&.MuiInputLabel-root': {
      fontWeight: 500,
      lineHeight: 'normal',
      fontSize: '16px',
      fontFamily: 'Inter, Lato, "Helvetica Neue", "sans-serif"',
      color: theme.palette.common.black,
    },
  },
  m0: {
    margin: 0,
    width: '100%',
  },
}));
