import { WebSdkPreview } from 'apps/WebSDKPreview';
import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Button, IconButton } from '@material-ui/core';
import { useFormatMessage } from 'apps/intl';
import { PageLoader } from 'apps/layout';
import { Modal, useOverlay } from 'apps/overlay';
import QRCode from 'qrcode.react';
import { CopyTextToClipboard, notification } from 'apps/ui';
import { useDispatch, useSelector } from 'react-redux';
import { permalinkUrl } from 'lib/client/urls';
import { selectClientIdModel } from 'state/merchant/merchant.selectors';
import { LimiterSubscriptionStatus, PermissionSubscriptionStatusTypes, useHasPermissionSubscription } from 'apps/Permissions';
import { FiChevronLeft } from 'react-icons/fi';
import { selectFlowBuilderProductsInGraph } from '../store/FlowBuilder.selectors';
import { ReactComponent as OpenLinkIcon } from '../assets/icon-open-link.svg';
import { ReactComponent as MobileIcon } from '../assets/icon-mobile.svg';
import { useStyles } from './TestItModal.styles';
import { PreviewModal } from '../components/PreviewModal/PreviewModal';
import { flowBuilderGetTemporaryFlowId, flowBuilderPreview, flowBuilderSubscribeToTemporaryWebhook } from '../store/FlowBuilder.action';

export function TestItModal({ onClose }) {
  const formatMessage = useFormatMessage();
  const classes = useStyles();
  const dispatch = useDispatch();
  const clientIdModel = useSelector(selectClientIdModel);
  const productsInGraph = useSelector(selectFlowBuilderProductsInGraph);
  const [createOverlay, closeOverlay] = useOverlay();
  const canCopyDirectLink = useHasPermissionSubscription(PermissionSubscriptionStatusTypes.canCopyDirectLink);
  const [temporaryFlowId, setTemporaryFlowId] = useState<Nullable<string>>(null);
  const [showQRCode, setShowQRCode] = useState<boolean>(false);
  const clientId = clientIdModel.value;
  const link: string = permalinkUrl({
    clientId: canCopyDirectLink ? clientId : '',
    flowId: canCopyDirectLink ? temporaryFlowId : '',
  });
  const isLinkLoaded: boolean = clientIdModel.isLoaded && !!clientId && !!temporaryFlowId;

  useEffect(() => {
    (async () => {
      if (!temporaryFlowId) {
        const newTemporaryFlowId = await dispatch(flowBuilderGetTemporaryFlowId()) as any as string;
        await dispatch(flowBuilderSubscribeToTemporaryWebhook(newTemporaryFlowId));
        setTemporaryFlowId(newTemporaryFlowId);
      }
    })();
  }, [dispatch, temporaryFlowId]);

  const handleClick = async () => {
    onClose();
    createOverlay(<PreviewModal onClose={closeOverlay} />);
    try {
      await dispatch(flowBuilderPreview(productsInGraph));
    } catch (e) {
      notification.error(formatMessage('Error.common'));
    }
  };

  const handleOnClick = (event) => {
    event.stopPropagation();
    setShowQRCode(!showQRCode);
  };

  return (
    <Modal
      onClose={onClose}
      className={classes.modal}
    >
      <Grid onClick={() => setShowQRCode(false)}>
        <Grid>
          <Button
            className={classes.backButton}
            color="default"
            onClick={handleClick}
          >
            <FiChevronLeft />
          </Button>
        </Grid>
        <Grid item container className={classes.container}>
          <Grid item>
            <Box className={classes.titleWrapper}>
              <Typography variant="h3" gutterBottom>
                {formatMessage('FlowBuilder.testItModal.title')}
              </Typography>
              <Box className={classes.subtitle}>
                {formatMessage('FlowBuilder.testItModal.subtitle')}
              </Box>
            </Box>
          </Grid>
          <Grid item container className={classes.buttonWrapper}>
            <Grid item className={classes.btnContainer} alignItems="center">
              {isLinkLoaded && (
                <LimiterSubscriptionStatus permission={PermissionSubscriptionStatusTypes.canCopyDirectLink}>
                  <CopyTextToClipboard
                    withCopy={canCopyDirectLink}
                    withCopyText={canCopyDirectLink}
                    text={link}
                    tooltipText={formatMessage('FlowBuilder.testItModal.linkCopied')}
                  >
                    <Box px={2} py={1.5} className={classes.link}>
                      {link}
                    </Box>
                  </CopyTextToClipboard>
                </LimiterSubscriptionStatus>
              )}
              <Box display="flex" position="absolute" top={0} right={10} bottom={11}>
                <IconButton
                  className={classes.openLinkButton}
                  onClick={() => window.open(link, '_blank', 'noopener')}
                >
                  <OpenLinkIcon />
                </IconButton>
              </Box>
            </Grid>
            <Grid item className={classes.qrCodeButton}>
              <Button
                className={showQRCode ? classes.active : classes.button}
                color="default"
                variant="outlined"
                onClick={(e) => handleOnClick(e)}
              >
                <MobileIcon />
                {formatMessage('FlowBuilder.testItModal.openOnMobile')}
              </Button>
              {showQRCode && (
                <Box className={classes.qrCodeContainer}>
                  <Typography>{formatMessage('FlowBuilder.testItModal.qrCode')}</Typography>
                  <QRCode value={link} />
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item container className={classes.previewContainer}>
          <LimiterSubscriptionStatus classNameRoot={classes.preview} permission={PermissionSubscriptionStatusTypes.canUsePreviewButton}>
            {isLinkLoaded ? (
              <WebSdkPreview merchantToken={clientIdModel.value} flowId={temporaryFlowId} onClose={onClose} />
            ) : (
              <PageLoader />
            )}
          </LimiterSubscriptionStatus>
        </Grid>
      </Grid>
    </Modal>
  );
}
