import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import classnames from 'classnames';
import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useStyles } from './TopMenuItem.styles';
import { navigation } from '../../services/Navigation.service';
import { NavigationEventTypes } from '../../models/Navigation.model';

export function TopMenuItem({ children, className, label, isNoLink = false, show = true, to, handler, icon, qa, isActive = true, id }: {
  children?: React.ReactNode;
  className?: string;
  label?: React.ReactNode;
  isNoLink?: boolean;
  show?: boolean;
  to?: string;
  handler?: () => void;
  icon?: React.ReactNode;
  qa: string;
  isActive?: boolean;
  id: string;
}) {
  const classes = useStyles();
  const history = useHistory();

  const newMenuItem = (
    <MenuItem
      disableRipple
      divider={false}
      className={classnames(classes.menuItem, className)}
      onClick={handler}
      data-qa={qa}
      id={id}
    >
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        {icon && (
          <Box mb={1} display="flex" fontSize={24}>
            {icon}
          </Box>
        )}
        <Box className={classes.textBox} display="flex">
          {label}
        </Box>
        {children}
      </Box>
    </MenuItem>
  );

  if (isNoLink || !to) {
    return !!show && newMenuItem;
  }

  return !!show && (
    <NavLink
      exact
      to={to}
      className={classes.link}
      activeClassName={isActive ? classes.active : null}
      data-qa={qa}
      onClick={(event) => {
        const interrupted = navigation.trigger(NavigationEventTypes.BeforeNavigation, () => history.push(to));
        if (interrupted) {
          event.preventDefault();
        }
      }}
    >
      {newMenuItem}
    </NavLink>
  );
}
