export const QATags = {
  Dashboard: {
    Loader: 'dashboard-loader',
  },

  Menu: {
    Logo: 'menu-logo',
    RollUpButton: 'menu-rollUp',
    Metrics: 'menu-metrics',
    VerificationList: 'menu-verificationList',
    CustomersList: 'menu-customer-verificationList',
    Product: 'menu-verificationFlows',
    Workflow: 'menu-workflows',
    Templates: 'menu-tenplates',
    ForDevelopers: 'menu-forDevelopers',
    FAQ: 'menu-faq',
    InviteTeammate: 'menu-inviteTeammate',
    Account: 'menu-account',
    LanguageSelect: 'menu-languageSelect',
    Logout: 'menu-logout',
    WhatsNew: 'menu-whats-new',
    Notifications: 'menu-notifications',
    ProductBoard: 'menu-roadmap',
    StatusPage: 'status-page',
    Settings: 'settings',
    MyPlan: 'my-plan',
    Upgrade: 'upgrade',
    Automations: 'automations',
  },

  Modal: {
    CloseButton: 'modal-button-close',
    Delete: {
      ConfirmDeleteButton: 'modal-button-confirm-delete',
    },
  },

  MyPlan: {
    RequestBandwidth: 'request-bandwidth',
    Back: 'back',
  },

  Notifications: {
    Panel: {
      CardBeamerUnread: 'notifications-panel-card-beamer-unread',
      CardBeamerRead: 'notifications-panel-card-beamer-read',
      CardStatusPageUnread: 'notifications-panel-card-statusPage-unread',
      CardStatusPageRead: 'notifications-panel-card-statusPage-read',
      CloseButton: 'notifications-panel-button-close',
      LinkStatusPage: 'notifications-panel-link-statusPage',
      TabBeamer: 'notifications-panel-tab-beamer',
      TabStatusPage: 'notifications-panel-tab-statusPage',
    },
  },

  StatusPage: {
    Banner: {
      Self: 'statusPage-banner',
      LinkDetails: 'statusPage-banner-link-details',
      ButtonNext: 'statusPage-banner-button-next',
    },
  },

  Auth: {
    EmailVerification: {
      Resend: 'email-verification-resend',
      GoBackButton: 'email-verification-button-go-back',
    },
    SignIn: {
      EmailInput: 'login-input-email',
      PasswordInput: 'login-input-password',
      SubmitButton: 'login-button-submit',
    },
    Recovery: {
      EmailInput: 'recovery-input-email',
      ResetPasswordSubmit: 'recovery-button-reset-password-submit',
      GoBackButton: 'recovery-button-go-back',
      ForgotPasswordButton: 'recovery-button-forgot-password',
    },
    SignUp: {
      EmailInput: 'sign-up-input-email',
      PasswordInput: 'sign-up-input-password',
      SignUpSubmitButton: 'sign-up-button-submit',
      LoginButton: 'sign-up-button-login',
    },
    ChangePassword: {
      CurrentPasswordInput: 'change-password-input-current',
      NewPasswordInput: 'change-password-input-new-password',
      RepeatPasswordInput: 'change-password-input-repeat',
      SubmitButton: 'change-password-button-submit',
      CancelButton: 'change-password-button-cancel',
    },
    Logout: {
      CancelButton: 'logout-button-cancel',
      ConfirmButton: 'logout-button-confirm',
    },
  },

  BankAccountVerification: {
    Buttons: {
      ExcelReport: 'bank-account-download-excel-report',
    },
    CashFlowChart: {
      ChartTypeSelect: 'cash-flow-chart-type-select',
    },
    PdfViewer: {
      Close: 'bank-account-pdf-view-close',
      Download: 'bank-account-pdf-view-download',
      Next: 'bank-account-pdf-view-next',
      Previous: 'bank-account-pdf-view-previous',
    },
    Table: 'bankAccountVerification-table',
  },

  Filter: {
    DatePicker: {
      YearsFromSelect: 'years-from-select',
      YearsToSelect: 'years-to-select',
      Periods: 'filter-buttons-periods',
    },
    byFlow: 'filter-block-by-flow',
    byStatus: 'filter-block-by-status',
    byAgents: 'filter-block-by-agents',
    byActionTypes: 'filter-block-by-action-types',
    ClearAllButton: 'filter-button-clear-all',
    ApplyFilterButton: 'filter-button-apply',
  },

  Analytics: {
    FilterButton: 'analytics-button-open-filter',
    WidgetContainer: 'analytics-widget-container',
    PlanWidgetContainer: 'analytics-plan-widget-container',
    BandwidthCalculatorWidgetContainer: 'analytics-bandwidth-calculator-widget-container',
  },

  Collaborators: {
    FirstNameInput: 'collaborators-input-first-name',
    LastNameInput: 'collaborators-input-last-name',
    EmailInput: 'collaborators-input-email',
    SendButton: 'collaborators-button-send',
    DeleteMemberButton: 'collaborators-button-delete-member',
    TeamTable: 'collaborators-team-table',
    Role: {
      Agent: 'collaborators-radiobutton-role-agent',
      Admin: 'collaborators-radiobutton-role-admin',
      Auditor: 'collaborators-radiobutton-role-auditor',
    },
  },

  Flows: {
    CreateNewFlowButton: 'flows-button-create',
    Create: {
      FlowNameInput: 'flows-create-input-name',
      CreateButton: 'flows-create-button-success',
      CancelButton: 'flows-create-button-denied',
    },
    Table: 'flows-table',
    Flow: 'flow',
    CountrySwitch: 'country-switch',
    FlowIssueNotificationDesktop: 'desktop-flow-issue',
    FlowIssueNotification: 'flow-issue',
    SaveButtonIssue: 'save-button-issue',
  },

  VerificationList: {
    Filter: 'verificationList-button-openFilter',
    DownloadCsv: 'verificationList-button-downloadCsv',
    Search: 'verificationList-input-search',
    Table: 'verificationList-table',
  },

  VerificationHistory: {
    Buttons: {
      Filter: 'verificationHistory-button-openFilter',
      BackToList: 'verificationHistory-button-back',
    },
    Table: 'verificationHistory-table',
  },

  VerificationHooks: {
    Table: 'verificationHooks-table',
  },

  Product: {
    Tab: {
      Configuration: 'product-tab-configuration',
      GovChecks: 'product-tab-gov-checks',
    },
  },

  MatiButton: {
    ColorPickerButton: 'product-color-picker-button',
  },

  Integration: {
    FlowSelector: 'integration-selector-flows',
    Doc: {
      Mati: 'integration-doc-button-mati',
      Banner: 'integration-doc-button-banner',
    },
    ClientId: {
      Value: 'integration-client-id-value',
      Copy: 'integration-client-id-copy',
    },
    FlowId: {
      Value: 'integration-flow-id-value',
      Copy: 'integration-flow-id-copy',
    },
    ClientSecret: {
      Value: 'integration-client-secret-value',
      Copy: 'integration-client-secret-copy',
    },
    Tabs: {
      Api: 'integration-tabs-api',
      Sdk: 'integration-tabs-sdk',
      Web: 'integration-tabs-web',
      Mobile: 'integration-tabs-mobile',
      Native: 'integration-tabs-native',
      Ios: 'integration-tabs-ios',
      Android: 'integration-tabs-android',
      Hybrid: 'integration-tabs-hybrid',
      ReactNative: 'integration-tabs-reactNative',
      Xamarin: 'integration-tabs-xamarin',
      Cordova: 'integration-tabs-cordova',
      CordovaIonic: 'integration-tabs-cordovaIonic',
      DirectLink: 'integration-tabs-directLink',
    },
  },

  Webhook: {
    Configure: 'webhook-button-configure',
    GenerateSecret: 'webhook-button-generateSecret',
    Hide: 'webhook-button-hide',
    Url: 'webhook-input-url',
    Secret: 'webhook-input-secret',
    Save: 'webhook-button-save',
    Doc: 'webhook-button-documentation',
  },

  Verification: {
    Date: 'verification-date',
    Flow: 'verification-flow',
    Source: 'verification-source',
    Number: {
      Value: 'verification-number-value',
      Copy: 'verification-number-copy',
    },
    StatusSelector: {
      Button: 'verification-statusSelector-button',
      Content: 'verification-statusSelector-content',
    },
    Buttons: {
      DownloadPdf: 'verification-button-downloadPdf',
      Data: 'verification-button-data',
      BackToList: 'verification-button-backToList',
      Delete: 'verification-button-delete',
      History: 'verification-button-history',
      Hooks: 'verification-button-hooks',
      RetriesBlocker: 'verification-retry-blocker',
    },
    Data: {
      Json: {
        Value: 'verification-data-json-value',
        Copy: 'verification-data-json-copy',
      },
      ResourceUrl: {
        Value: 'verification-data-resourceUrl-value',
        Copy: 'verification-data-resourceUrl-copy',
      },
      Close: 'verification-data-button-close',
    },
    Icons: {
      Rejected: 'verification-icon-rejected',
      Verified: 'verification-icon-verified',
      ReviewNeeded: 'verification-icon-reviewNeeded',
    },
  },

  Document: {
    Change: {
      EditData: 'document-change-button-editData',
      Save: 'document-change-button-save',
      Cancel: 'document-change-button-cancel',
      Form: 'document-change-form',
    },
  },

  NewSettings: {
    Tabs: {
      Primary: {
        Account: 'settings-tab-primary-account',
        Team: 'settings-tab-primary-team',
      },
      Secondary: {
        User: 'settings-tab-secondary-user',
        Organization: 'settings-tab-secondary-organization',
        Security: 'settings-tab-secondary-security',
        Compliance: 'settings-tab-secondary-compliance',
        Members: 'settings-tab-secondary-members',
      },
    },
    RouterPromptModal: {
      cancelChangesButton: 'settings-router-prompt-modal-button-cancel-changes',
      saveChangesButton: 'settings-router-prompt-modal-button-save-changes',
    },
    CancelButton: 'settings-button-cancel',
    SaveChangesButton: 'settings-button-save-changes',
    Account: {
      User: {
        Avatar: 'settings-account-user-icon-avatar',
        Form: {
          FullName: {
            Label: 'settings-account-user-form-label-full-name',
            Input: 'settings-account-user-form-input-full-name',
          },
          Email: {
            Label: 'settings-account-user-form-label-email',
            Text: 'settings-account-user-form-text-email',
          },
          ChangePassword: {
            Label: 'settings-account-user-form-label-change-password',
            Button: 'settings-account-user-form-button-change-password',
          },
          LinkAccount: {
            Label: 'settings-account-user-form-label-link-account',
            Button: 'settings-account-user-form-button-link-account',
          },
        },
      },
      Organization: {
        Form: {
          DisplayName: {
            Label: 'settings-account-organization-form-label-display-name',
            Input: 'settings-account-organization-form-input-display-name',
          },
          LegalName: {
            Label: 'settings-account-organization-form-label-legal-name',
            Input: 'settings-account-organization-form-input-legal-name',
          },
          LegalAddress: {
            Label: 'settings-account-organization-form-label-display-name',
            Input: 'settings-account-organization-form-input-legal-address',
          },
          LegalRegNumber: {
            Label: 'settings-account-organization-form-label-legal-reg-number',
            Input: 'settings-account-organization-form-input-legal-reg-number',
          },
          DateOfRegistration: {
            Label: 'settings-account-organization-form-label-date-of-registration',
            Text: 'settings-account-organization-form-text-date-of-registration',
          },
          ContactSupport: {
            Button: 'settings-account-organization-form-button-contact-support',
          },
        },
      },
      Security: {
        Form: {
          PasswordExpiryPolicy: {
            Label: 'settings-account-security-form-label-password-expiry-policy',
            Switch: 'settings-account-security-form-switch-password-expiry-policy',
            Select: 'settings-account-security-form-select-password-expiry-policy',
          },
        },
      },
      Compliance: {
        Form: {
          VerificationNotes: {
            Label: 'settings-account-compliance-form-label-verification-notes',
            Description: 'settings-account-compliance-form-description-verification-notes',
            Switch: 'settings-account-compliance-form-switch-verification-notes',
          },
        },
      },
    },
    Team: {
      Members: {
        AddMember: 'settings-team-members-button-add-a-member',
        MemberTypeFilterDropdown: 'settings-team-members-member-type-filter-dropdown',
        SerachMembersInput: 'settings-team-members-search-members-input',
        ResendInvitationButton: 'settings-team-members-resend-invitation-button',
        TransferOwnership: {
          CancelButton: 'settings-team-members-transfer-ownership-cancel-button',
          YesButton: 'settings-team-members-transfer-ownership-yes-button',
        },
        AddMemberModal: {
          RoleSelect: 'settings-team-members-add-member-modal-role-select',
          EmailsInput: 'settings-team-members-add-member-modal-emails-input',
          CancelButton: 'settings-team-members-add-member-modal-cancel-button',
          SendInviteButton: 'settings-team-members-add-member-modal-send-invite-button',
          CloseButton: 'settings-team-members-add-member-modal-close-button',
        },
        Table: {
          HeadColumns: {
            Member: 'settings-team-members-table-head-column-member',
            LastActive: 'settings-team-members-table-head-column-last-active',
            Role: 'settings-team-members-table-head-column-role',
            MeatballMenu: 'settings-team-members-table-head-column-meatball-menu',
          },
          Body: {
            MeatballMenuIcon: 'settings-team-members-table-body-meatball-icon',
            RoleDropdown: 'settings-team-members-table-body-role-dropdown-icon',
            RowColumns: {
              Member: 'settings-team-members-table-body-row-column-member',
              LastActive: 'settings-team-members-table-body-row-column-last-active',
              Role: 'settings-team-members-table-body-row-column-role',
              MeatballMenu: 'settings-team-members-table-body-row-column-meatball-menu',
            },
          },
        },
      },
    },
    ManageRoles: {
      AddNewRole: 'settings-manage-roles-button-add-new-role',
      Table: {
        HeadColumns: {
          Role: 'settings-manage-roles-table-head-column-role',
          RoleType: 'settings-manage-roles-table-head-column-meatball-role-type',
          MetamapAccess: 'settings-manage-roles-table-head-column-metamap-access',
          MeatballMenu: 'settings-manage-roles-table-head-column-meatball-menu',
        },
        Body: {
          MeatballMenuIcon: 'settings-manage-roles-table-body-meatball-icon',
          RowColumns: {
            Role: 'settings-manage-roles-table-row-column-role',
            RoleType: 'settings-manage-roles-table-row-column-meatball-role-type',
            MetamapAccess: 'settings-manage-roles-table-row-column-metamap-access',
            MeatballMenu: 'settings-manage-roles-table-row-column-meatball-menu',
            EditRole: 'settings-manage-roles-table-row-column-meatball-menu-edit-role-button',
            DeleteRole: 'settings-manage-roles-table-row-column-meatball-menu-delete-role-button',
          },
        },
      },
      AddOrEditModal: {
        Form: {
          Input: {
            RoleName: 'settings-manage-roles-addoredit-modal-form-input-roleName',
            RoleDescription: 'settings-manage-roles-addoredit-modal-form-input-roleDescription',
          },
          Switch: {
            Permissions: {
              VerificationsAndCustomers: 'settings-manage-roles-addoredit-modal-form-switch-permissions-verifications-and-customers',
              Metamaps: 'settings-manage-roles-addoredit-modal-form-switch-permissions-metamaps',
              Analytics: 'settings-manage-roles-addoredit-modal-form-switch-permissions-analytics',
              IntegrationSettings: 'settings-manage-roles-addoredit-modal-form-switch-permissions-integration-settings',
              AccountSettings: 'settings-manage-roles-addoredit-modal-form-switch-permissions-account-settings',
            },
          },
          MetamapAccess: {
            Select: 'settings-manage-roles-addoredit-modal-form-metamap-access-select',
            SelectAllButton: 'settings-manage-roles-addoredit-modal-form-metamap-access-select-all-button',
            DeSelectAllButton: 'settings-manage-roles-addoredit-modal-form-metamap-access-deselect-all-button',
          },
          Button: {
            Cancel: 'settings-manage-roles-addoredit-modal-form-button-cancel',
            Create: 'settings-manage-roles-addoredit-modal-form-button-create',
            Update: 'settings-manage-roles-addoredit-modal-form-button-update',
          },
        },
      },
      DeleteModal: {
        Button: {
          Cancel: 'settings-manage-roles-delete-modal-cancel',
          Yes: 'settings-manage-roles-delete-modal-yes',
        },
      },
    },
  },

  Settings: {
    ChangePassword: {
      Change: 'settings-changePassword-button-change',
    },
    ContactUs: 'settings-changePassword-button-contact',
  },

  Review: {
    VerificationCount: 'review-verificationCount',
    Banner: {
      ReviewMode: 'review-banner-button-goToReviewMode',
      ShowVerifications: 'review-banner-button-showVerifications',
    },
    Page: {
      Buttons: {
        Verified: 'review-page-button-verified',
        Rejected: 'review-page-button-rejected',
        Skip: 'review-page-button-skip',
        Exit: 'review-page-button-exit',
        Cancel: 'review-page-button-cancel',
        Next: 'review-page-button-next',
      },
      ExitModal: {
        Exit: 'review-page-exitModal-button-exit',
        Cancel: 'review-page-exitModal-button-cancel',
      },
    },
  },

  AgentHistory: {
    FilterButton: 'agent-history-button-open-filter',
    GoBackButton: 'agent-history-button-go-back',
    HistoryTable: 'agent-history-table',
    AgentRoleSelect: 'agent-history-select-role',
  },

  FlowBuilder: {
    Integration: {
      API: 'flow-builder-integration-api',
      SDK: 'flow-builder-integration-sdk',
    },
    PreviewModal: {
      SDKCustomisationForm: {
        tabs: {
          colorAndStyling: 'flow-builder-preview-modal-sdk-customisation-form-tabs-color-and-styling',
          logo: 'flow-builder-preview-modal-sdk-customisation-form-tabs-logo',
          typography: 'flow-builder-preview-modal-sdk-customisation-form-tabs-typography',
        },
        label: {
          accentColor: 'flow-builder-preview-modal-sdk-customisation-form-label-accent-color',
          errorColor: 'flow-builder-preview-modal-sdk-customisation-form-label-error-color',
          warningColor: 'flow-builder-preview-modal-sdk-customisation-form-label-warning-color',
          successColor: 'flow-builder-preview-modal-sdk-customisation-form-label-success-color',
          spinnerColor: 'flow-builder-preview-modal-sdk-customisation-form-label-spinner-color',
          backgroundColor: 'flow-builder-preview-modal-sdk-customisation-form-label-background-color',
          backgroundAltColor: 'flow-builder-preview-modal-sdk-customisation-form-label-background-alt-color',
          bodyTextColor: 'flow-builder-preview-modal-sdk-customisation-form-label-body-text-color',
          titleTextColor: 'flow-builder-preview-modal-sdk-customisation-form-label-title-text-color',
          subtitleTextColor: 'flow-builder-preview-modal-sdk-customisation-form-label-subtitle-text-color',
          labelTextColor: 'flow-builder-preview-modal-sdk-customisation-form-label-label-text-color',
          lineColor: 'flow-builder-preview-modal-sdk-customisation-form-label-line-color',
          borderColor: 'flow-builder-preview-modal-sdk-customisation-form-label-border-color',
          customLogo: 'flow-builder-preview-modal-sdk-customisation-form-label-custom-logo',
          customLogoUploadInput: 'flow-builder-preview-modal-sdk-customisation-form-label-custom-logo-upload-input',
          customLogoUploadButton: 'flow-builder-preview-modal-sdk-customisation-form-label-custom-logo-upload-button',
          customLogoRemoveButton: 'flow-builder-preview-modal-sdk-customisation-form-label-custom-logo-remove-button',
          hidePoweredByMetamap: 'flow-builder-preview-modal-sdk-customisation-form-label-hide-powered-by-metamap',
          displayAllFonts: 'flow-builder-preview-modal-sdk-customisation-form-label-display-all-fonts',
        },
        cta: {
          setToDefault: 'flow-builder-preview-modal-sdk-customisation-form-cta-set-to-default',
          saveChanges: 'flow-builder-preview-modal-sdk-customisation-form-cta-save-changes',
          fontSelect: 'flow-builder-preview-modal-sdk-customisation-form-font-select',
          fontSelectSearch: 'flow-builder-preview-modal-sdk-customisation-form-font-select-search',
          formSelectOption: 'flow-builder-preview-modal-sdk-customisation-form-font-select-option',
        },
      },
    },
    Settings: {
      FlowNameInput: 'flow-builder-settings-input-flowName',
      EditFlowNameButton: 'flow-builder-settings-button-editFlowName',
      SaveButton: 'flow-builder-settings-save-button',
    },
    UpgradeFreemiumPreview: 'upgrade-freemium-preview',
  },

  SignUpForm: {
    BusinessName: 'signup-form-business-name',
    FullName: 'signup-form-full-name',
    Password: 'signup-form-password',
    Email: 'signup-form-email',
    CompanySize: 'signup-company-size',
    Countries: 'signup-countries',
    IndustryType: 'signup-industry-type',
    AccountType: 'signup-account-type',
    SalesforceId: 'signup-form-salesforce-id',
    SaveButton: 'signup-form-save-button',
    SetUpButton: 'signup-form-setup-button',
    OpenSecondPartOfFormButton: 'signup-form-to-second-form-button',
    OpenFirstPartOfFormButton: 'signup-form-to-first-form-button',
  },

  Templates: {
    Modal: {
      SaveButton: 'templates-modal-save-button',
      Description: 'templates-modal-description',
      CountriesSelect: 'templates-modal-countries-select',
      IndustriesSelect: 'templates-modal-industries-select',
      UseCasesSelect: 'templates-modal-useCases-select',
      MetamapName: 'templates-modal-metamap-name',
      TemplateTitle: 'templates-modal-template-title',
    },
    Details: {
      ScreenshotImage: 'templates-details-screenshot-image',
      PreviousScreenshotButton: 'templates-details-previousScreenshot-button',
      NextScreenshotButton: 'templates-details-nextScreenshot-button',
    },
    EditButton: 'templates-edit-button',
    SelectButton: 'templates-select-button',
    CreateNewTemplateButton: 'templates-create-new-template',
  },

  TemplatesModal: {
    ChosenFilters: {
      DeleteAll: 'templates-modal-chosenFilters-deleteAll',
    },
    Filters: {
      industry: 'templates-modal-filters-industry-button',
      country: 'templates-modal-filters-country-button',
    },
    UpgradeFreemiumTemplates: 'upgrade-freemium-templates',
  },

  SubscriptionStatus: {
    FreeTrial: {
      Activate: 'subscription-status-freeTrial-activate',
      DurationData: 'subscription-status-free-trial-duration-data',
    },
    PostTrial: {
      Activate: 'subscription-status-postTrial-activate',
      ExpiredChip: 'subscription-status-post-trial-expired-chip',
    },
  },
  OnboardingSteps: {
    BuildNewMetamap: {
      Button: 'onboarding-steps-build-the-first-metamap',
      Video: 'onboarding-steps-build-new-metamap-video',
      ListItem: 'onboarding-step-build-new-item',
    },
    ReviewVerifications: {
      Button: 'onboarding-steps-review-verifications',
      Video: 'onboarding-steps-review-verifications-video',
      ListItem: 'onboarding-step-review-verifications-item',
    },
    InviteTeamMembers: {
      ListItem: 'onboarding-step-invite-team-members-item',
      Button: 'onboarding-steps-invite-team-members-send',
      SkipNowButton: 'onboarding-steps-invite-team-members-skip-now',
      InputTypes: {
        admin: { Role: 'onboarding-steps-invite-team-members-radiobutton-role-admin', EmailsList: 'onboarding-steps-invite-team-members-admin-emails-list' },
        agent: { Role: 'onboarding-steps-invite-team-members-radiobutton-role-agent', EmailsList: 'onboarding-steps-invite-team-members-agent-emails-list' },
        auditor: { Role: 'onboarding-steps-invite-team-members-radiobutton-role-auditor', EmailsList: 'onboarding-steps-invite-team-members-auditor-emails-list' },
      },
    },
    ContactAndSupport: {
      ListItem: 'onboarding-step-contact-and-support-item',
      Button: 'onboarding-steps-contact-us',
      VerificationPerMonthInput: 'onboarding-steps-verificationsPerMonth-input',
    },
    IntegrateFlow: {
      ListItem: 'onboarding-step-integrate-flow-item',
      Button: 'onboarding-steps-check-documentation',
      Video: 'onboarding-steps-integrate-the-flow-video',
      IntegrationTypeInput: {
        Api: 'onboarding-steps-integration-type-api',
        Sdk: 'onboarding-steps-integration-type-sdk',
      },
    },
    FinishOnboardingButton: 'onboarding-steps-finish-onboarding-button',
  },
  ProfileInsights: {
    Button: 'profile-insights-open-sidebar',
    CloseButton: 'profile-insights-close-sidebar',
    VerificationsDropdown: 'profile-associated-verification-list-dropdown',
    Sidebar: 'profile-insights-sidebar',
    Cards: {
      ProfileRiskAssessment: {
        CardName: 'profile-insights-cards-profileRiskAssessment',
        Reason: 'profile-insights-cards-profileRiskAssessment-reason',
        Transaction: 'profile-insights-cards-profileRiskAssessment-transaction',
      },
      UserContactInfo: 'profile-insights-cards-userContactInfo',
      UserHistory: 'profile-insights-cards-userHistory',
      UserMerits: 'profile-insights-cards-userMerits',
      UserVerificationHistory: 'profile-insights-cards-userVerificationHistory',
    },
    FeedbackLink: 'profile-insights-feedbackLink',
  },
  FreemiumVsPremium: {
    Menu: {
      Upgrade: 'freemium-vs-premium-menu-upgrade',
    },
    Root: {
      FeaturesTable: {
        DocumentVerificationLink: 'freemium-vs-premium-root-features-table-document-verification-link',
        AllCountriesOfOperationLink: 'freemium-vs-premium-root-features-table-all-countries-of-operation-link',
        LearnMoreAboutPremiumButton: 'freemium-vs-premium-root-features-table-learn-more-about-premium-button',
      },
    },
    Details: {
      CountryByNameSearchInput: 'freemium-vs-premium-details-search-country-by-name-search-input',
      LetterButton: 'freemium-vs-premium-details-letter-button',
      CountryName: 'freemium-vs-premium-details-country-name',
      BackButton: 'freemium-vs-premium-details-back-button',
    },
  },
};
