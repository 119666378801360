import React from 'react';
import Box from '@material-ui/core/Box';
import { useFormatMessage } from 'apps/intl';
import { useStyles } from './NewBadge.styles';

export const NewBadge = () => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  return (
    <Box className={classes.newFeature}>
      {formatMessage('Biometrics.setting.badge.beta')}
    </Box>
  );
};
