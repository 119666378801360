import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { selectProductCards, useProductsIssues } from 'apps/Product';
import { notification } from 'apps/ui';
import React, { useEffect, useMemo, useState } from 'react';
import { useFormatMessage } from 'apps/intl';
import { navigation, NavigationEventTypes } from 'apps/layout';
import { useDispatch, useSelector } from 'react-redux';
import { Loadable } from 'models/Loadable.model';
import { IFlow } from 'models/Flow.model';
import { IProductCard, ProductTypes } from 'models/Product.model';
import { ChatWidget, IntercomCustomAttributeTypes, IntercomEventTypes } from 'apps/intercom';
import { TrackTypes, useTrackClickEvent } from 'apps/Tracking';
import { flowBuilderSaveAndPublish } from '../../store/FlowBuilder.action';
import { selectFlowBuilderChangeableFlow, selectFlowBuilderChangeableFlowModel, selectFlowBuilderHaveUnsavedChanges, selectFlowBuilderProductsInGraphModel } from '../../store/FlowBuilder.selectors';
import { useStyles } from './Save.styles';
import { ReactComponent as SaveDefaultIcon } from '../../assets/icon-save-default.svg';
import { ReactComponent as SaveInprogressIcon } from '../../assets/icon-save-inprogress.svg';
import { ReactComponent as SaveCompletedIcon } from '../../assets/icon-save-completed.svg';

export function Save({ newFlowName }: {
  newFlowName?: string;
}) {
  const classes = useStyles();
  const [isHover, setIsHover] = useState(false);
  const formatMessage = useFormatMessage();
  const dispatch = useDispatch();
  const cards: IProductCard[] = useSelector(selectProductCards);
  const productsInGraphModel = useSelector<any, Loadable<ProductTypes[]>>(selectFlowBuilderProductsInGraphModel);
  const allDisabledCards: IProductCard[] = cards.filter((type: IProductCard) => type.disabled);
  const isCardDisabled: boolean = productsInGraphModel.value.some((type: ProductTypes) => allDisabledCards.find((ele: IProductCard) => ele.id === type)?.disabled === true);
  const haveUnsavedChanges = useSelector<any, boolean>(selectFlowBuilderHaveUnsavedChanges);
  const changeableFlowModel = useSelector<any, Loadable<IFlow>>(selectFlowBuilderChangeableFlowModel);
  const haveIssues = useProductsIssues(productsInGraphModel.value);
  const isDisabled = useMemo(() => (productsInGraphModel?.value?.length || 0) === 0, [productsInGraphModel?.value]);
  const changeableFlow = useSelector(selectFlowBuilderChangeableFlow);
  const producstsInGraph = useSelector(selectFlowBuilderProductsInGraphModel);

  const trackEvent = useTrackClickEvent();
  useEffect(() => {
    function handleBeforeUnload(event) {
      event.preventDefault();
      // eslint-disable-next-line no-param-reassign
      event.returnValue = '';
    }

    if (haveUnsavedChanges) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [haveUnsavedChanges]);

  const handleSaveFlow = async (flowName: string) => {
    setIsHover(false);
    try {
      await dispatch(flowBuilderSaveAndPublish(flowName));
      notification.info(formatMessage('FlowBuilder.notification.saved'));
      trackEvent(TrackTypes.WorkflowUpdated, {
        workflowId: changeableFlow.id,
        workflowName: flowName || changeableFlow.name,
        workflowType: changeableFlow.integrationType,
        tools: producstsInGraph.value,
      });
      ChatWidget.update({ [IntercomCustomAttributeTypes.HasCreatedFirstMetamap]: true });
      ChatWidget.trackEvent(IntercomEventTypes.CreateMetamap);
    } catch (e) {
      notification.error(formatMessage('Error.common'));
    }
  };

  useEffect(() => {
    const confirm = (resume) => {
      if (haveUnsavedChanges) {
        setTimeout(() => {
          const confirmed = window.confirm(formatMessage('FlowBuilder.confirmUnsavedChanges'));
          if (confirmed) {
            resume();
          }
        });
      }
      return haveUnsavedChanges;
    };

    navigation.on(NavigationEventTypes.BeforeNavigation, confirm);

    return () => {
      navigation.off(NavigationEventTypes.BeforeNavigation, confirm);
    };
  }, [haveUnsavedChanges, handleSaveFlow, newFlowName]);

  return (
    <Grid id="save" container>
      {((haveUnsavedChanges && !haveIssues && !isCardDisabled)) && !changeableFlowModel.isLoading && (
      <Button
        disabled={isDisabled}
        className={classes.buttonSave}
        color="primary"
        variant="contained"
        onClick={() => handleSaveFlow(newFlowName)}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        {isHover ? <SaveInprogressIcon /> : <SaveDefaultIcon />}
        {formatMessage('FlowBuilder.button.save')}
      </Button>
      )}

      {((haveUnsavedChanges && haveIssues)) && !changeableFlowModel.isLoading && (
      <Button
        disabled
        className={classes.buttonSave}
        color="primary"
        variant="contained"
      >
        <SaveDefaultIcon />
        {formatMessage('FlowBuilder.button.save')}
      </Button>
      )}

      {(!productsInGraphModel.isLoaded || changeableFlowModel.isLoading) && (
      <Button
        disabled={isDisabled}
        className={classes.buttonSave}
        color="primary"
        variant="contained"
        onClick={() => handleSaveFlow(newFlowName)}
      >
        <SaveInprogressIcon />
        {formatMessage('FlowBuilder.button.saving')}
      </Button>
      )}

      {productsInGraphModel.isLoaded && !haveUnsavedChanges && (
      <Button
        disabled
        className={classes.buttonSave}
        color="primary"
        variant="contained"
        onClick={() => handleSaveFlow(newFlowName)}
      >
        <SaveCompletedIcon />
        {formatMessage('FlowBuilder.button.saved')}
      </Button>
      )}

      {productsInGraphModel.isLoaded && haveUnsavedChanges && isCardDisabled && !haveIssues && (
      <Button
        disabled
        className={classes.buttonSave}
        color="primary"
        variant="contained"
      >
        <SaveDefaultIcon />
        {formatMessage('FlowBuilder.button.save')}
      </Button>
      )}
    </Grid>
  );
}
