import React, { useEffect } from 'react';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Icon from '@material-ui/core/Icon';
import classnames from 'classnames';
import { useFormatMessage } from 'apps/intl';
import { useSelector, useDispatch } from 'react-redux';
import { toggleProfileInsight } from 'apps/ProfileInsights/store/ProfileInsights.action';
import { Spinner } from 'apps/ui';
import { Loadable } from 'models/Loadable.model';
import { QATags } from 'models/QA.model';
import { TrackTypes, useTrackClickEvent } from 'apps/Tracking';
import { UserHistory } from '../UserHistory/UserHistory';
import { UserContactInfo } from '../UserContactInfo/UserContactInfo';
import { UserMerits } from '../UserMerits/UserMerits';
import { UserVerificationHistory } from '../UserVerificationHistory/UserVerificationHistory';
import { ProfileRiskAssessment } from '../ProfileRiskAssessment/ProfileRiskAssessment';
import { selectProfileInsightState, selectUserContact, selectUserHistory, selectUserMerits, selectUserVerificationHistory, selectCanSeePremiumInsights } from '../../store/ProfileInsights.selectors';
import { IProfileContactReponse, IProfileVerificationHistoryResponse, IProfileMeritResponse, IProfileHistoryResponse } from '../../model/ProfileInsights.model';
import { FeedbackLink } from '../FeedbackLink/FeedbackLink';
import { ReactComponent as ProfileInsightsIcon } from '../../assets/profileInsights.svg';
import { ReactComponent as Close } from '../../assets/close.svg';
import { useStyles } from './ProfileInsightsSideBar.styles';

export function ProfileInsightsSideBar() {
  const formatMessage = useFormatMessage();
  const isProfileInsightVisible = useSelector<any, boolean>(selectProfileInsightState);
  const classes = useStyles();
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery('(min-width:768px)', { noSsr: true });

  const userHistoryData = useSelector<any, Loadable<IProfileHistoryResponse>>(selectUserHistory);
  const userMeritData = useSelector<any, Loadable<IProfileMeritResponse>>(selectUserMerits);
  const userContactData = useSelector<any, Loadable<IProfileContactReponse>>(selectUserContact);
  const userVerificationData = useSelector<any, Loadable<IProfileVerificationHistoryResponse>>(selectUserVerificationHistory);
  const canUsePremiumInsights = useSelector<any, boolean>(selectCanSeePremiumInsights);

  const trackEvent = useTrackClickEvent();

  useEffect(() => {
    if (isProfileInsightVisible) {
      trackEvent(TrackTypes.ProfileInsightsViewed);
    }
  }, [isProfileInsightVisible, trackEvent]);

  const handleInsightClose = () => {
    dispatch(toggleProfileInsight());
  };

  return (
    <Drawer
      id="profile-insights-drawer"
      open={isProfileInsightVisible}
      anchor="right"
      onClose={handleInsightClose}
      className={classnames(classes.drawer, {
        [classes.drawerOpenMobile]: !isDesktop,
      })}
      elevation={0}
      classes={{
        paper: classnames(classes.drawer, {
          [classes.drawerOpenMobile]: !isDesktop,
        }),
      }}
    >
      <Box data-qa={QATags.ProfileInsights.Sidebar} className={classes.drawerWrapper}>
        <Box className={classes.heading}>
          <Icon className={classes.icon}>
            <ProfileInsightsIcon />
          </Icon>
          <Typography className={classes.title}>
            {formatMessage('ProfileInsights.title')}
          </Typography>
          <IconButton data-qa={QATags.ProfileInsights.CloseButton} className={classes.button} onClick={handleInsightClose}>
            <Close />
          </IconButton>
        </Box>
        <FeedbackLink />
        <Box mb={2} display="flex" flexDirection="column">
          {canUsePremiumInsights && <ProfileRiskAssessment />}
          {!userMeritData.isFailed && userMeritData.value?.uniqueMerits?.length > 0 && (
            <Card data-qa={QATags.ProfileInsights.Cards.UserMerits} raised={false} className={classes.insightsBlocks}>
              <CardContent>
                <Typography variant="body1" className={classes.insightTitle}>
                  {formatMessage('ProfileInsights.userMerit.title')}
                </Typography>
                <Typography
                  variant="subtitle2"
                  className={classes.insightSubTitle}
                >
                  {formatMessage('ProfileInsights.UserMerit.subTitle')}
                </Typography>
                {userMeritData.isLoading && (
                  <Box mt={2} mb={2}>
                    <Spinner size="medium" />
                  </Box>
                )}
                {userMeritData.isLoaded && <UserMerits />}
              </CardContent>
            </Card>
          )}
          {!userVerificationData.isFailed && userVerificationData.value?.verificationStatus?.length > 0 && (
            <Card data-qa={QATags.ProfileInsights.Cards.UserVerificationHistory} raised={false} className={classes.insightsBlocks}>
              <CardContent>
                <Typography variant="body1" className={classes.insightTitle}>
                  {formatMessage('ProfileInsights.userVerification.title')}
                </Typography>
                <Typography
                  variant="subtitle2"
                  className={classes.insightSubTitle}
                >
                  {formatMessage('ProfileInsights.userVerification.subTitle')}
                </Typography>
                {userVerificationData.isLoading && (
                  <Box mt={2} mb={2}>
                    <Spinner size="medium" />
                  </Box>
                )}
                {userVerificationData.isLoaded && <UserVerificationHistory />}
              </CardContent>
            </Card>
          )}
          {!userContactData.isFailed && !!(userContactData.value?.contactInformation?.emails?.length || userContactData.value?.contactInformation?.contactNumbers?.length || userContactData.value?.contactInformation?.addresses?.length) && (
            <Card data-qa={QATags.ProfileInsights.Cards.UserContactInfo} raised={false} className={classes.insightsBlocks}>
              <CardContent>
                <Typography variant="body1" className={classes.insightTitle}>
                  {formatMessage('ProfileInsights.userContact.title')}
                </Typography>
                {userContactData.isLoading && (
                  <Box mt={2} mb={2}>
                    <Spinner size="medium" />
                  </Box>
                )}
                {userContactData.isLoaded && <UserContactInfo />}

              </CardContent>
            </Card>
          )}
          {!userHistoryData.isFailed && (
            <Card data-qa={QATags.ProfileInsights.Cards.UserHistory} raised={false} className={classes.insightsBlocks}>
              <CardContent>
                <Typography variant="body1" className={classes.insightTitle}>
                  {formatMessage('ProfileInsights.userHistory.title')}
                </Typography>
                {userHistoryData.isLoading && (
                  <Box mt={2} mb={2}>
                    <Spinner size="medium" />
                  </Box>
                )}
                {userHistoryData.isLoaded && <UserHistory />}
              </CardContent>
            </Card>
          )}
        </Box>
      </Box>
    </Drawer>
  );
}
