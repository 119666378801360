import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import { CheckResultLogo, MediaSelfie, MediaVideo } from 'apps/ui';
import { useFormatMessage } from 'apps/intl';
import { CheckBarExpandable } from 'apps/checks';
import { getVerificationStatus, IVideoAgreementStep, getLanguageObject, VideoAgreementErrors } from '../../models/VideoAgreement.model';
import { useStyles } from './VideoAgreementVerification.style';

export function VideoAgreementVerification({ data: stepData }: { data: IVideoAgreementStep }) {
  const errors = stepData.data.errors || [];
  const isRunning = stepData.data.isRunning;
  const videoUrl = stepData.data.publicUrls.videoUrl;
  const selfieUrl = stepData.data.publicUrls.selfieUrl;
  const { transcript, similarity } = stepData.data.recognizedSpeech;
  const { language: languageId, script } = stepData.data.agreement;

  const formatMessage = useFormatMessage();
  const classes = useStyles();
  const language = getLanguageObject(languageId, formatMessage)?.label;
  const verificationStatus = getVerificationStatus(isRunning, Boolean(errors.length));

  const checkError = (errorCode) => Boolean(errors.find((error) => (error.code === errorCode)));
  const insufficientSimilarityError = checkError(VideoAgreementErrors.InsufficientSimilarity);
  const moreThanOneFaceError = checkError(VideoAgreementErrors.MoreThanOneFace);
  const faceIsNotRecognizedError = checkError(VideoAgreementErrors.FaceIsNotRecognized);

  return (
    <Grid container spacing={2}>
      <Grid lg={3} md={12} item>
        <Grid container xs={12} justifyContent="center">
          <Grid item lg={12} md={6} className={classNames(classes.mediaContainer, 'fs-exclude')}>
            <MediaVideo title={formatMessage('VideoAgreement.Checks.video.title')} withSoundButton video={videoUrl} />
          </Grid>
          {!isRunning && selfieUrl && (
          <Grid item lg={12} md={6} className={classNames(classes.mediaContainer, 'fs-exclude')}>
            <MediaSelfie title={formatMessage('VideoAgreement.Checks.selfie.title')} image={selfieUrl} />
          </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item lg={1} />
      <Grid item lg={8} md={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <CheckResultLogo status={verificationStatus} type="videoAgreement" />
          </Grid>
          {!isRunning && (
          <Grid item xs={12}>
            <CheckBarExpandable isError={insufficientSimilarityError} step={stepData} title="Checks.result.videoAgreement.transcript.title" isOpenByDefault>
              <Grid container className={classes.checkDetailsContainer}>
                <Grid item xs={12}>
                  <Typography className={classNames(classes.checkData, 'fs-exclude')}>
                    {transcript}
                  </Typography>
                  <Typography className={classes.checkTitle}>
                    {formatMessage('Checks.result.videoAgreement.transcript.result.title')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classNames(classes.checkData, classes.script, 'fs-exclude')}>
                    {script}
                  </Typography>
                  <Typography className={classes.checkTitle}>
                    {formatMessage('Checks.result.videoAgreement.script.result.title')}
                  </Typography>
                </Grid>
                <Grid item md={2} xs={12}>
                  <Typography className={classNames(classes.checkData, 'fs-exclude')}>
                    {similarity}
                  </Typography>
                  <Typography className={classes.checkTitle}>
                    {formatMessage('Checks.result.videoAgreement.similarity.result.title')}
                  </Typography>
                </Grid>
                <Grid item md={1} />
                <Grid item md={6} xs={12}>
                  <Typography className={classNames(classes.checkData, 'fs-exclude')}>
                    {language}
                  </Typography>
                  <Typography className={classes.checkTitle}>
                    {formatMessage('Checks.result.videoAgreement.language.result.title')}
                  </Typography>
                </Grid>
              </Grid>
            </CheckBarExpandable>
            {(faceIsNotRecognizedError || moreThanOneFaceError) && (
              <CheckBarExpandable isError={faceIsNotRecognizedError || moreThanOneFaceError} step={stepData} title="Checks.result.videoAgreement.faceRecognition.title" isOpenByDefault>
                <Grid container className={classes.checkDetailsContainer}>
                  <Grid item xs={12}>
                    <Typography className={classNames(classes.checkData, 'fs-exclude')}>
                      {formatMessage(`Checks.result.${(faceIsNotRecognizedError && VideoAgreementErrors.FaceIsNotRecognized) || VideoAgreementErrors.MoreThanOneFace}`)}
                    </Typography>
                  </Grid>
                </Grid>
              </CheckBarExpandable>
            )}
          </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
