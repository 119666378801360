import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  subTitle: {
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.palette.common.black90,
  },
  title: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '14px',
    color: theme.palette.common.darkgrey,
  },
  subTitle2: {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '14px',
    color: theme.palette.common.darkgrey,
  },
  firstVerified: {
    color: theme.palette.common.darkBlue,
  },
  success: {
    color: theme.palette.common.green,
  },
  rejected: {
    color: theme.palette.common.red,
  },
  chip: {
    borderRadius: '99px',
    width: '100%',
  },
  isActive: {
    backgroundColor: theme.palette.common.whiteblue,
  },
  manualReview: {
    color: theme.palette.common.yellow,
  },
  paginationIcon: {
    width: 20,
    height: 20,
  },
  paginationIconDisabled: {
    color: theme.palette.common.whiteblue,
  },
  titleBoldMain: {
    fontSize: 15,
    fontWeight: 'bold',
    color: theme.palette.common.black75,
  },
  dropdownSelect: {
    borderRadius: 99,
    border: 'none',
    backgroundColor: `${theme.palette.common.whiteblue} !important`,
    overflow: 'hidden',
    height: 'fit-content',
    '&:hover, &:focus': {
      background: theme.palette.common.whiteblue,
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'initial',
    },
    '&::before, &::after': {
      content: 'none',
    },
    '& .MuiFilledInput-input': {
      padding: [[4, 8]],
      paddingRight: 35,
      color: theme.palette.common.lightblue,
    },
    '& svg': {
      color: theme.palette.common.lightblue,
    },
  },
}));
