import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    '& $title, & $subtitle': {
      fontFamily: 'Inter',
      color: theme.palette.common.black90,
      textAlign: 'center',
      opacity: 0.5,
    },
  },
  title: {
    fontSize: 16,
    lineHeight: '19px',
  },
  subtitle: {
    marginTop: 3,
    fontSize: 12,
    letterSpacing: '0.2px',
    lineHeight: '15px',
    fontWeight: 500,
  },
  heading: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '22px',
    color: theme.palette.common.black75,
  },
  reason: {
    color: theme.palette.common.brinkPink,
    fontSize: 16,
    lineHeight: '19px',
    fontWeight: 600,
  },
  warningColor: {
    color: theme.palette.common.black90,
  },
  noRiskColor: {
    color: theme.palette.common.green,
  },
  accordion: {
    boxShadow: 'none',
    borderTop: `1px solid ${theme.palette.common.black7}`,
    '&.Mui-expanded': {
      margin: 0,
    },
    '& *': {
      padding: 0,
    },
    '& .MuiIconButton-edgeEnd': {
      marginRight: 0,
    },
    '&:before': {
      display: 'none',
    },
    '& .MuiAccordionSummary-root, & .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 30,
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '12px 0',
    },
  },
  accordionDetails: {
    flexDirection: 'column',
  },
  accordionItem: {
    marginTop: theme.spacing(1),
    '& p, & div': {
      fontSize: 11,
      lineHeight: '17px',
      fontWeight: 700,
      color: theme.palette.common.black75,
    },
  },
  riskScore: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 32,
    height: 32,
    padding: 10,
    fontSize: '12px !important',
    gap: 10,
    borderRadius: '50%',
  },
  noRisk: {
    background: theme.palette.common.greentransparent,
    color: `${theme.palette.common.silverTree} !important`,
  },
  warningRisk: {
    background: theme.palette.common.lightyellow,
    color: `${theme.palette.common.galliano} !important`,
  },
  highRisk: {
    background: theme.palette.common.lightRed,
    color: `${theme.palette.common.brinkPink} !important`,
  },
  info: {
    fontSize: 16,
    marginLeft: 'auto',
    color: theme.palette.common.black75,
  },
}));
