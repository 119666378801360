import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

interface IProps{
  isBottomElement: boolean;
  colorHex: string;
}
export const useCustomColorPickerStyles = makeStyles<Theme, IProps>((theme) => ({
  customColorPickerWrapper: {
    marginTop: '8px',
  },
  root: {
    width: '245px',
    height: '32px',
    cursor: 'pointer',
    border: `1px solid ${theme.palette.common.black50}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '4px 8px',
    borderRadius: '4px',
  },
  text: {
    fontSize: '16px',
    fontFamily: 'Inter, Lato, "Helvetica Neue", "sans-serif"',
    fontWeight: 500,
    color: theme.palette.common.wfBase900,
  },
  colorRect: {
    width: '16px',
    height: '16px',
    borderRadius: '4px',
    display: 'inline-block',
    margin: '4px 12px 4px 8px',
    border: `1px solid ${theme.palette.common.black}`,
    backgroundColor: (props: any) => props.colorHex,
  },
  popover: {
    position: 'absolute',
    zIndex: 2,
    bottom: (props: IProps) => (props.isBottomElement ? '10px' : 'none'),
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
}));
