import React from 'react';
import Box from '@material-ui/core/Box';
import { useFormatMessage } from 'apps/intl';
import { Badge } from 'apps/ui';

export const CertifiedBadges = () => {
  const formatMessage = useFormatMessage();
  return (
    <Box mt={0.5} display="flex" flexDirection="row" alignItems="center">
      <Badge text={formatMessage('Biometrics.setting.badge.certified')} />
      <Badge text={formatMessage('FlowBuilder.integration.sdkOnly')} />
    </Box>
  );
};
