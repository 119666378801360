import { VerificationId } from 'models/VerificationOld.model';

export const DRAWER_WIDTH = 419;
export const NO_OF_VERIFICATION_HISTORY_PER_PAGE = 3;
export const VERIFIED_MERITS_PER_PAGE = 9;
export enum ProfileMeritTypes {
  AntiMoneyLaundering = 'AntiMoneyLaundering',
  BankAccountData = 'BankAccountData',
  BiometricVerification = 'BiometricVerification',
  BusinessVerification = 'BusinessVerification',
  CourtRecords = 'CourtRecords',
  CreditCheck = 'CreditCheck',
  CriminalRecords = 'CriminalRecords',
  CustomDocument = 'CustomDocument',
  CustomInput = 'CustomInput',
  CustomWatchlists = 'CustomWatchlists',
  DocumentVerification = 'DocumentVerification',
  DrivingLicenseVerification = 'DrivingLicenseVerification',
  ESignature = 'ESignature',
  EmailCheck = 'EmailCheck',
  FaceIDAccess = 'FaceIDAccess',
  FaceMatch = 'FaceMatch',
  GovernmentCheck = 'GovernmentCheck',
  GovernmentIDVerification = 'GovernmentIDVerification',
  LocationIntelligence = 'LocationIntelligence',
  PhoneCheck = 'PhoneCheck',
  TaxNumberVerification = 'TaxNumberVerification',
  VideoAgreement = 'VideoAgreement',
  Other = 'Other',
}
export interface IProfileMeritResponse extends IPorfileInsightResponse {
  uniqueMerits: Array<ProfileMeritTypes>;
}

export enum IndustryTypes {
  'AllIndustries' = 'AllIndustries',
}

export enum StatusTypes {
  verified = 'verified',
  reviewNeeded = 'reviewNeeded',
  rejected = 'rejected',
}
export interface IStatusChangeHistory {
  status: StatusTypes;
  date: string;
}

export interface IProfileHistory {
  dateOfFirstVerification: string;
  totalVerifications: number;
  totalManualReview: number;
  totalRejected: number;
  totalNumberOfMerchants: number;
  totalVerified: number;
}
export interface IProfileHistoryResponse extends IPorfileInsightResponse {
  userHistory: IProfileHistory;
}

export interface IPorfileInsightResponse {
  responseStatus: string;
  profileId: string;
}
export interface IProfileContactData {
  contactNumbers?: Array<string>;
  emails?: Array<string>;
  addresses?: Array<string>;
}
export interface IProfileContactReponse extends IPorfileInsightResponse {
  contactInformation: IProfileContactData;
}

export interface IVerificationStatus {
  verificationId: string;
  merchantVertical: string;
  country: string;
  statusChangeHistory: Array<IStatusChangeHistory>;
}
export interface IProfileVerificationHistoryResponse
  extends IPorfileInsightResponse {
  verificationStatus: Array<IVerificationStatus>;
}

export interface IProfileRiskAssessmentTransaction {
  verificationId: VerificationId;
  risk: boolean;
  riskScore: number;
  completedAt: string;
}

export enum FraudReasonCodeTypes {
  VPN = 'VPN',
  FraudNetwork = 'Fraud_network',
  FraudIP = 'Fraud_IP',
  FraudName = 'Fraud_name',
  FraudDoc = 'Fraud_doc',
  FraudEmail = 'Fraud_email',
  EmailMCountry = 'Email_m_country',
  DocMMerchant = 'Doc_m_merchant',
  DocMIP = 'Doc_m_IP',
  DocMEmail = 'Doc_m_email',
  EmailMUser = 'Email_m_user',
}

export interface IProfileRiskAssessmentResponse extends IPorfileInsightResponse {
  profileRisk: boolean;
  reason: FraudReasonCodeTypes[];
  riskScore: number;
  transactions: IProfileRiskAssessmentTransaction[];
}

export enum RiskScoreTypes {
  NO_RISK = 'noRisk',
  WARNING = 'warning',
  HIGH_RISK = 'highRisk'
}

export const riskScoreLimits = {
  [RiskScoreTypes.NO_RISK]: 33.3,
  [RiskScoreTypes.WARNING]: 66.6,
  [RiskScoreTypes.HIGH_RISK]: 100,
};

export const ProfileInsightFeedbackURL = process.env.REACT_APP_PROFILE_INSIGHT_FEEDBACK;
export const ProfileRiskInsightTransationsLimit = 10;
export interface IReusageRequest {
  profileId: string;
}
