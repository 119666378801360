import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  feedbackLink: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    paddingBottom: 0,
  },
}));
