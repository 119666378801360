import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { IndustryTypes, IStatusChangeHistory, IVerificationStatus, NO_OF_VERIFICATION_HISTORY_PER_PAGE, StatusTypes, IProfileVerificationHistoryResponse } from 'apps/ProfileInsights/model/ProfileInsights.model';
import { formatDate, DateFormatTypes } from 'lib/date';
import { useSelector } from 'react-redux';
import { useFormatMessage } from 'apps/intl';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { Loadable } from 'models/Loadable.model';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import { Select } from 'apps/ui';
import { useStyles } from './UserVerificationHistory.styles';
import { selectUserVerificationHistory } from '../../store/ProfileInsights.selectors';

export const UserVerificationHistory = () => {
  const userVerificationData = useSelector<any, Loadable<IProfileVerificationHistoryResponse>>(selectUserVerificationHistory);

  const [industryList, setIndustryList] = useState<Array<string>>([IndustryTypes.AllIndustries]);
  const [selectedIndustry, setSelectedIndustry] = useState<string>(IndustryTypes.AllIndustries);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filteredData, setFilteredData] = useState<Array<Array<IVerificationStatus>>>([]);
  const [displayData, setDisplayData] = useState<Array<IVerificationStatus>>([]);
  const classes = useStyles();
  const formatMessage = useFormatMessage();

  const paginateData = (data) => data.reduce((acc, val, i) => {
    const idx = Math.floor(i / NO_OF_VERIFICATION_HISTORY_PER_PAGE);
    // eslint-disable-next-line no-param-reassign
    const page = acc[idx] || (acc[idx] = []);
    page.push(val);
    return acc;
  }, []);

  const getTopMerchantVertical = (verificationData: Array<IVerificationStatus>) => {
    const merchantVerticalWithCount = verificationData.reduce((obj, v) => {
      // eslint-disable-next-line no-param-reassign
      obj[v.merchantVertical] = (obj[v.merchantVertical] || 0) + 1;
      return obj;
    }, {});

    const topVerticals = Object.keys(merchantVerticalWithCount).sort((a, b) => merchantVerticalWithCount[b] - merchantVerticalWithCount[a]);
    setIndustryList([IndustryTypes.AllIndustries, ...topVerticals]);
  };

  useEffect(() => {
    getTopMerchantVertical(userVerificationData.value.verificationStatus);
    const paginatedData = paginateData(userVerificationData.value.verificationStatus);
    setFilteredData(paginatedData);
  }, [userVerificationData.value.verificationStatus]);

  useEffect(() => {
    if (filteredData.length > 0) {
      setDisplayData(filteredData[currentPage - 1]);
    }
  }, [filteredData, currentPage]);

  const handleIndustryFilter = (filter: string) => () => {
    setSelectedIndustry(filter);
    let newFilteredData = [];
    if (filter !== IndustryTypes.AllIndustries) {
      newFilteredData = userVerificationData.value.verificationStatus.filter((el) => el.merchantVertical === filter);
    } else {
      newFilteredData = userVerificationData.value.verificationStatus;
    }
    const paginatedFilteredData = paginateData(newFilteredData);
    setFilteredData(paginatedFilteredData);
    setCurrentPage(1);
  };

  const handleIndustrySelect = (event: React.ChangeEvent<{ value: unknown }>) => handleIndustryFilter(event.target.value as string)();

  const totalPages = filteredData.length;
  const isPreviousPageDisabled = currentPage === 1 || totalPages === 1;
  const isNextPageDisabled = totalPages === 1 || currentPage === totalPages;

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };
  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const displayVerificationStatus = (status: StatusTypes) => {
    switch (status) {
      case StatusTypes.verified:
        return formatMessage('statuses.verified');
      case StatusTypes.rejected:
        return formatMessage('statuses.rejected');
      default:
        return formatMessage('statuses.manual-review');
    }
  };

  const verificationStatusHistoryDetails = (statusHistroyData: Array<IStatusChangeHistory>) => statusHistroyData.map((statusHistroy: IStatusChangeHistory) => (
    <Box mb={1}>
      <Grid container key={statusHistroy.date} alignItems="center">
        <Grid item xs={6}>
          <Typography className={classes.subTitle2}>{formatDate(statusHistroy.date, DateFormatTypes.DateFull)}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography
            className={classNames(classes.subTitle, {
              [classes.success]: statusHistroy.status === StatusTypes.verified,
              [classes.rejected]: statusHistroy.status === StatusTypes.rejected,
              [classes.manualReview]: statusHistroy.status === StatusTypes.reviewNeeded,
            })}
          >
            {displayVerificationStatus(statusHistroy.status)}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  ));

  const verificationDetials = () => displayData.map((verificationData: IVerificationStatus) => (
    <Box mb={1.5} key={verificationData.verificationId}>
      <Grid container>
        <Grid item xs={5}>
          <Typography className={classes.subTitle}>{verificationData.merchantVertical || formatMessage('ProfileInsights.UserVerificationHistory.IndustryTypes.Unknown')}</Typography>
          <Typography className={classes.title}>{verificationData.country}</Typography>
        </Grid>
        <Grid item xs={7}>
          {verificationStatusHistoryDetails(verificationData.statusChangeHistory)}
        </Grid>
      </Grid>
    </Box>
  ));

  return (
    <Box mt={2}>
      <Grid container alignItems="center">
        {industryList.length > 4 ? (
          <Select variant="filled" className={classes.dropdownSelect} value={selectedIndustry} onChange={handleIndustrySelect}>
            {industryList.map((industry) => (
              <MenuItem key={industry} value={industry}>
                {[IndustryTypes.AllIndustries, ''].includes(industry) ? formatMessage(`ProfileInsights.UserVerificationHistory.IndustryTypes.${industry || 'Unknown'}`) : industry}
              </MenuItem>
            ))}
          </Select>
        ) : industryList.map((industry) => (
          <Grid item xs={3} key={industry}>
            <Button
              color="primary"
              variant="text"
              size="small"
              className={classNames(classes.chip, { [classes.isActive]: industry === selectedIndustry })}
              onClick={handleIndustryFilter(industry)}
            >
              {[IndustryTypes.AllIndustries, ''].includes(industry) ? formatMessage(`ProfileInsights.UserVerificationHistory.IndustryTypes.${industry || 'Unknown'}`) : industry}
            </Button>
          </Grid>
        ))}
      </Grid>
      <Box mt={2}>
        <Grid container>
          <Grid item xs={5}>
            <Typography className={classes.title}>{formatMessage('ProfileInsights.userVerification.merchantIndustry')}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.title}>{formatMessage('ProfileInsights.userVerification.date')}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Box pl={1.5}>
              <Typography className={classes.title}>{formatMessage('ProfileInsights.userVerification.status')}</Typography>
            </Box>
          </Grid>
        </Grid>
        <Box marginTop={1.5}>
          {verificationDetials()}
        </Box>
      </Box>
      <Box mt={2} display="flex" justifyContent="center" alignItems="center" className={classes.titleBoldMain}>
        <Box mr={1}>
          <IconButton disabled={isPreviousPageDisabled} onClick={handlePreviousPage}>
            <FiChevronLeft className={classNames(classes.paginationIcon, { [classes.paginationIconDisabled]: isPreviousPageDisabled })} />
          </IconButton>
        </Box>
        <Typography variant="h6">{currentPage}</Typography>
        <Box ml={0.5} mr={0.5}>
          <Typography variant="h6">{formatMessage('ProfileInsights.userVerification.of')}</Typography>
        </Box>
        <Typography variant="h6">
          {totalPages}
        </Typography>
        <Box ml={1}>
          <IconButton disabled={isNextPageDisabled} onClick={handleNextPage}>
            <FiChevronRight className={classNames(classes.paginationIcon, { [classes.paginationIconDisabled]: isNextPageDisabled })} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};
