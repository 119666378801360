import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  testit: {
    marginRight: 24,
    backgroundColor: theme.palette.common.lightblue,
    color: theme.palette.common.white,
    borderColor: theme.palette.common.whiteblue,
    minWidth: 120,
    height: 43,
    minHeight: 43,
    fontSize: 14,
    fontWeight: 'bold',
    padding: '5px 15px 5px 5px',
    '&:hover': {
      backgroundColor: theme.palette.common.lightblue,
    },
    '& svg': {
      marginRight: 5,
    },
  },
}));
