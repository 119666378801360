import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: 20,
    minHeight: 100,
  },
  title: {
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '22px',
    color: theme.palette.common.darkgrey,
  },
  subtitle: {
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '15px',
    color: theme.palette.common.darkgrey,
    marginTop: 4,
  },
}));
