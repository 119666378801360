import React, { useState } from 'react';
import { CopyToClipboard } from 'apps/ui';
import { useFormatMessage } from 'apps/intl';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { useStyles } from './UserDetailsList.styles';

export const UserDetailsList = ({
  icon,
  title,
  items,
  isEmail,
}: {
  title: string;
  icon: React.ReactNode;
  items: string[];
  isEmail?: boolean;
}) => {
  const [displayItems, setDisplayItems] = useState<string[]>(
    items[0] ? [items[0]] : [],
  );
  const classes = useStyles();
  const formatMessage = useFormatMessage();

  const handleShowAllToggle = () => {
    if (displayItems.length === 1) {
      setDisplayItems(items);
    } else {
      setDisplayItems([items[0]]);
    }
  };
  return (
    <>
      {displayItems.length !== 0 && (
        <Box mt={2}>
          <Box
            mt={1}
            display="flex"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Box display="flex" className={classes.displayItem}>
              {icon}
              <Box ml={1} mb={1}>
                <Typography className={classes.title}>{title}</Typography>
              </Box>
            </Box>
            {items.length > 1 && (
              <Box display="flex">
                <Button
                  variant="text"
                  color="primary"
                  size="small"
                  onClick={handleShowAllToggle}
                >
                  {displayItems.length === 1
                    ? formatMessage('ProfileInsights.userContact.showAll')
                    : formatMessage('ProfileInsights.userContact.hide')}
                </Button>
              </Box>
            )}
          </Box>
          <Box>
            {isEmail && displayItems.map((item) => (
              <Box mb={1} key={item}>
                <CopyToClipboard text={item} key={item}>
                  <Link href={`mailto:${item}`}>{item}</Link>
                </CopyToClipboard>
              </Box>
            ))}
            {!isEmail && displayItems.map((item) => (
              <Box mb={1} key={item}>
                <CopyToClipboard text={item}>
                  <Typography className={classes.subTitle}>{item}</Typography>
                </CopyToClipboard>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </>
  );
};
