import { Box, Button, Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { overlayCloseAll } from 'apps/overlay';
import { useDeleteButtonHook, Warning } from 'apps/ui';
import classNames from 'classnames';
import { toIsoPeriod, fromIsoPeriod } from 'lib/date';
import { GDPRRanges } from 'models/GDPR.model';
import { DigitalSignatureProvider } from 'models/DigitalSignature.model';
import { VerificationPatternTypes } from 'models/VerificationPatterns.model';
import { Routes } from 'models/Router.model';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FiTrash2 } from 'react-icons/fi';
import { useFormatMessage } from 'apps/intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectPostponedTimeout } from 'state/merchant/merchant.selectors';
import { round } from 'lib/round';
import { useFlowSettings } from 'hooks/useFlowSettings';
import { QATags } from 'models/QA.model';
import { SDKRedirectionOptionsTypes } from 'models/Flow.model';
import { VerificationRetryBlockerModeTypes } from 'apps/flowBuilder';
import { flowBuilderDelete, flowBuilderSaveAndPublishSettings } from '../../store/FlowBuilder.action';
import { useStyles } from './FlowSettings.styles';
import { useValidatePolicyInterval } from '../../hooks/validatePolicyInterval.hook';
import { FlowSettingsSwitches } from '../FlowSettingsSwitches/FlowSettingsSwitches';

const disabledGDPRList = [VerificationPatternTypes.CreditMexicoCdc];

export function FlowSettings({ onClose }: {
  onClose: () => void;
}) {
  const dispatch = useDispatch();
  const formatMessage = useFormatMessage();
  const classes = useStyles();
  // TODO: @anatoliy.turkin move all the logic into the hook
  const {
    postponedTimeout,
    setPostponedTimeout,
    flowName,
    isSaveButtonLoading,
    setIsSaveButtonLoading,
    policyIntervalError,
    setPolicyIntervalError,
    newFlowName,
    setNewFlowName,
    isInit,
    setIsInit,
    oldSettings,
    setOldSettings,
    verificationPatterns,
    redirectOptions,
    allowedRedirectionUrls,
    policyInterval: policyIntervalFromSelector,
    digitalSignature: digitalSignatureFromSelector,
    retryBlockerConfig,
    retryVerificationBlockerConfig,
    setRetryVerificationBlockerConfig,
  } = useFlowSettings(useSelector(selectPostponedTimeout));
  const [policyInterval, setPolicyInterval] = useState<string>(fromIsoPeriod(policyIntervalFromSelector));
  const [isGDPRChecked, setIsGDPRChecked] = useState<boolean>(!!policyIntervalFromSelector);
  const [digitalSignature, setDigitalSignature] = useState<DigitalSignatureProvider>(digitalSignatureFromSelector);
  const validatePolicyInterval = useValidatePolicyInterval();

  const isDisabled = useMemo(() => disabledGDPRList.every((item) => verificationPatterns[item]), [verificationPatterns]);
  const GDPRRange = verificationPatterns[VerificationPatternTypes.CreditMexicoCdc] ? GDPRRanges[VerificationPatternTypes.CreditMexicoCdc] : GDPRRanges.default;

  const [sdkRedirectionOptionsState, setSDKRedirectionOptionsState] = useState({
    [SDKRedirectionOptionsTypes.QR]: redirectOptions?.includes(SDKRedirectionOptionsTypes.QR) ?? false,
    [SDKRedirectionOptionsTypes.Email]: redirectOptions?.includes(SDKRedirectionOptionsTypes.Email) ?? false,
    isError: !redirectOptions?.length,
  });

  const [sdkAllowedRedirectionUrls, setSdkAllowedRedirectionUrls] = useState<string[]>(allowedRedirectionUrls ?? []);

  const handleSDKRedirectionOptionsSwitchChange = (option: SDKRedirectionOptionsTypes, value: boolean) => {
    setSDKRedirectionOptionsState((prev) => {
      const updated = {
        ...prev,
        [option]: value,
        isError: false,
      };

      if (!updated[SDKRedirectionOptionsTypes.QR] && !updated[SDKRedirectionOptionsTypes.Email]) {
        updated.isError = true;
      }

      return updated;
    });
  };

  const handleDeleteFlow = useCallback(async () => {
    await dispatch(overlayCloseAll());
    await dispatch(flowBuilderDelete());
  }, [dispatch]);

  const { handleDelete } = useDeleteButtonHook(handleDeleteFlow, {
    redirectUrl: Routes.flow.root,
    header: 'VerificationFlow.modal.delete.title',
    confirm: 'VerificationFlow.modal.delete.subtitle',
  });

  const handleValidatePolicyInterval = useCallback(({ target: { value } }) => {
    setPolicyIntervalError(validatePolicyInterval(parseInt(value, 10), GDPRRange));
  }, [GDPRRange, setPolicyIntervalError, validatePolicyInterval]);

  const handleChangePolicyInterval = useCallback(({ target: { value } }) => {
    setPolicyInterval(value);
  }, []);

  const handleGDPRSwitcher = useCallback((e) => {
    const isChecked = e.target.checked;
    setIsGDPRChecked(isChecked);
    if (!isChecked) {
      setPolicyInterval(null);
      setPolicyIntervalError(null);
    }
  }, [setPolicyIntervalError]);

  const handleBlockVerficationRetries = useCallback((mode: VerificationRetryBlockerModeTypes, timeout?: string) => {
    const updatedConfig = {
      ...retryVerificationBlockerConfig,
      mode,
      timeout,
    };
    setRetryVerificationBlockerConfig(updatedConfig);
  }, [retryVerificationBlockerConfig, setRetryVerificationBlockerConfig]);

  const handleDigitalSignatureSwitcher = useCallback((e) => {
    const isChecked = e.target.checked;
    setDigitalSignature(isChecked ? DigitalSignatureProvider.NOM151 : DigitalSignatureProvider.NONE);
  }, []);

  const handleBlockRetriesPrimaryKey = useCallback((value: string | undefined) => {
    setRetryVerificationBlockerConfig({ ...retryVerificationBlockerConfig, primaryKey: value });
  }, [retryVerificationBlockerConfig, setRetryVerificationBlockerConfig]);

  const handleSaveAndPublish = useCallback(async () => {
    if (policyIntervalError) {
      return;
    }
    const redirectOptionsList = [];
    if (sdkRedirectionOptionsState[SDKRedirectionOptionsTypes.Email]) {
      redirectOptionsList.push(SDKRedirectionOptionsTypes.Email);
    }
    if (sdkRedirectionOptionsState[SDKRedirectionOptionsTypes.QR]) {
      redirectOptionsList.push(SDKRedirectionOptionsTypes.QR);
    }
    const roundedValue = round(parseInt(policyInterval, 10), 0);
    const payload = {
      policyInterval: roundedValue ? toIsoPeriod(roundedValue) : null,
      digitalSignature,
      postponedTimeout,
      name: newFlowName,
      redirectOptions: redirectOptionsList,
      allowedRedirectionUrls: sdkAllowedRedirectionUrls?.length ? sdkAllowedRedirectionUrls : undefined,
      retryBlockerConfig: retryVerificationBlockerConfig,
    };
    setIsSaveButtonLoading(true);
    await dispatch(flowBuilderSaveAndPublishSettings(payload));
    setIsSaveButtonLoading(false);
    onClose();
  }, [policyIntervalError, sdkRedirectionOptionsState, sdkAllowedRedirectionUrls, policyInterval, digitalSignature, postponedTimeout, newFlowName, retryVerificationBlockerConfig, setIsSaveButtonLoading, dispatch, onClose]);

  const showUnsavedChange = useMemo(() => {
    if (!oldSettings) {
      return false;
    }

    const {
      postponedTimeout: oldPostponedTimeout,
      policyInterval: oldPolicyInterval,
      digitalSignature: oldDigitalSignature,
      flowName: oldFlowName,
      retryBlockerConfig: { mode: oldRetryMode, timeout: oldRetryTimeout, primaryKey: oldPrimaryKey },
    } = oldSettings;

    const oldTimeout = oldRetryTimeout !== undefined ? oldRetryTimeout : null;
    const oldPrimaryKeyValue = oldPrimaryKey || '';
    const newRetryMode = retryVerificationBlockerConfig?.mode || 'off';
    const newTimeout = retryVerificationBlockerConfig?.timeout !== undefined ? retryVerificationBlockerConfig?.timeout : null;
    const newPrimaryKey = retryVerificationBlockerConfig?.primaryKey || '';

    if (
      postponedTimeout !== oldPostponedTimeout
      || policyInterval !== oldPolicyInterval
      || digitalSignature !== oldDigitalSignature
      || newFlowName !== oldFlowName
      || newRetryMode !== oldRetryMode
      || newTimeout !== oldTimeout
      || newPrimaryKey !== oldPrimaryKeyValue
    ) {
      return true;
    }

    return false;
  }, [oldSettings, retryVerificationBlockerConfig?.mode, retryVerificationBlockerConfig?.timeout, retryVerificationBlockerConfig?.primaryKey, postponedTimeout, policyInterval, digitalSignature, newFlowName]);

  useEffect(() => {
    setNewFlowName(flowName);
  }, [flowName, setNewFlowName]);

  useEffect(() => {
    if (isInit) {
      setOldSettings({
        policyInterval,
        postponedTimeout,
        digitalSignature,
        flowName,
        retryBlockerConfig: retryBlockerConfig || {
          mode: VerificationRetryBlockerModeTypes.Off,
        },
      });
      setIsInit(false);
    }
  }, [isInit, policyInterval, digitalSignature, flowName, setOldSettings, postponedTimeout, setIsInit, retryBlockerConfig]);

  return (
    <Grid container spacing={2} alignItems="flex-start">
      <Grid item xs={12}>
        <Box color="common.black90" fontWeight="bold">
          {formatMessage('FlowBuilder.settings.title.settings')}
        </Box>
        <Box color="common.black75" mt={0.5}>
          {formatMessage('FlowBuilder.settings.title.description')}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <FlowSettingsSwitches
          policyInterval={policyInterval}
          policyIntervalError={policyIntervalError}
          onValidatePolicyInterval={handleValidatePolicyInterval}
          onChangePolicyInterval={handleChangePolicyInterval}
          gdprChecked={isGDPRChecked}
          digitalSignatureChecked={digitalSignature === DigitalSignatureProvider.NOM151}
          onGDPRSwitcher={handleGDPRSwitcher}
          onDigitalSignatureSwitcher={handleDigitalSignatureSwitcher}
          disableDigitalSignature={isDisabled}
          placeholder={`${GDPRRange.from}-${GDPRRange.to}`}
          postponedTimeout={postponedTimeout}
          onPostponedTimeoutChange={setPostponedTimeout}
          sdkRedirectionOptionsState={sdkRedirectionOptionsState}
          onSDKRedirectOptionsSwitcher={handleSDKRedirectionOptionsSwitchChange}
          allowedUrls={sdkAllowedRedirectionUrls}
          onSdkAllowedRedirectionUrlsUpdate={setSdkAllowedRedirectionUrls}
          onBlockVerificationRetryChange={handleBlockVerficationRetries}
          retryVerificationBlockerConfig={retryVerificationBlockerConfig}
          updateBlockRetriesPrimaryKey={handleBlockRetriesPrimaryKey}
        />
      </Grid>
      <Grid container item xs={12} className={classes.buttonsWrapper} alignItems="center">
        <Button variant="outlined" className={classNames(classes.buttonCancel)} onClick={handleDelete}>
          <FiTrash2 fontSize={17} />
          <Box ml={1}>
            {formatMessage('FlowBuilder.settings.button.delete')}
          </Box>
        </Button>
        {showUnsavedChange && (
          <Warning label={formatMessage('FlowBuilder.settings.button.warning')} />
        )}
        <Button className={classNames(classes.buttonSave)} disabled={sdkRedirectionOptionsState?.isError} onClick={handleSaveAndPublish} data-qa={QATags.FlowBuilder.Settings.SaveButton}>
          {isSaveButtonLoading ? <CircularProgress color="inherit" size={17} /> : formatMessage('FlowBuilder.settings.button.save')}
        </Button>
      </Grid>
    </Grid>
  );
}
