import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  viewTemplatesButton: {
    borderColor: 'transparent',
    color: theme.palette.common.lightblue,
    background: theme.palette.common.whiteblue,
    minWidth: 120,
    height: 30,
    minHeight: 30,
    marginLeft: 10,
    fontSize: 14,
    fontWeight: 'bold',
    '& svg': {
      marginRight: 5,
    },
  },
}));
