import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

export function TabPanel({ index, value, children, ...props }: {
  children?: React.ReactNode;
  index: number;
  value: number;
}) {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...props}
    >
      {value === index && (
        <Box p={1}>
          {children}
        </Box>
      )}
    </Box>
  );
}
