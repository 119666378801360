import React from 'react';
import Box from '@material-ui/core/Box';
import { useFormatMessage } from 'apps/intl';
import { FiMail, FiHome, FiPhone } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { Loadable } from 'models/Loadable.model';
import { selectUserContact } from '../../store/ProfileInsights.selectors';
import { UserDetailsList } from '../UserDetailsList/UserDetailsList';
import { IProfileContactReponse } from '../../model/ProfileInsights.model';

export const UserContactInfo = () => {
  const formatMessage = useFormatMessage();
  const userContactData = useSelector<any, Loadable<IProfileContactReponse>>(selectUserContact);
  return (
    <Box>
      {userContactData.value.contactInformation.emails && (
      <Box mb={1}>
        <UserDetailsList
          title={formatMessage('ProfileInsights.userContact.email')}
          icon={<FiMail />}
          items={userContactData.value.contactInformation.emails}
          isEmail
        />
      </Box>
      )}
      {userContactData.value.contactInformation.contactNumbers && (
        <Box mb={1}>
          <UserDetailsList
            title={formatMessage('ProfileInsights.userContact.phone')}
            icon={<FiPhone />}
            items={userContactData.value.contactInformation.contactNumbers}
          />
        </Box>
      )}
      {userContactData.value.contactInformation.addresses && (
      <Box mb={1}>
        <UserDetailsList
          title={formatMessage('ProfileInsights.userContact.address')}
          icon={<FiHome />}
          items={userContactData.value.contactInformation.addresses}
        />
      </Box>
      )}
    </Box>
  );
};
