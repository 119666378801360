import { DocumentTypes } from 'models/Document.model';
import { IFlow } from 'models/Flow.model';
import { ProductSettings, ProductTypes } from 'models/Product.model';
import intersection from 'lodash/intersection';
import { VerificationInputTypes } from 'models/VerificationPatternsConfigs.model';

export enum DocumentVerificationSettingTypes {
  DocumentSteps = 'documentSteps',
  OtherSteps = 'otherSteps',
  DenyUploadRequirement = 'denyUploadRequirement',
  AgeThreshold = 'ageThreshold',
  GrayscaleImage = 'grayscaleImage',
  DuplicateUserDetection = 'duplicateUserDetection',
  IsRestrictedToFreemiumCountries = 'isRestrictedToFreemiumCountries',
  CountryRestriction = 'countryRestriction',
  FacematchThreshold = 'facematchThreshold',
  ProofOfOwnership = 'proofOfOwnership',
  HumanDocumentReadingService= 'humanDocumentReadingService',
  HumanFraudDetectionService = 'humanFraudDetectionService',
  CountriesSettings = 'countriesSettings',
  IsDuplicateUserRejection = 'isDuplicateUserRejection',
  PooNistWarning = 'proofOfOwnershipNistWarning',
  ScreenPhotoValidation = 'screenPhotoValidation',
  AllowDocumentsViaWebCamera = 'allowDocumentsViaWebCamera',
  AllowDocumentsViaNativeCamera = 'allowDocumentsViaNativeCamera',
  DocumentCroppedCheck = 'documentCroppedCheck',
  DocumentPhotoFaceCheck = 'documentPhotoFaceCheck',
  DocumentPhotoPresenceCheck = 'documentPhotoPresenceCheck',
  BlurryImageCheck = 'blurryImageCheck',
}

export type ProductSettingsDocumentVerification = ProductSettings<DocumentVerificationSettingTypes>;

export enum DocumentVerificationCheckTypes {
  DocumentReading = 'documentReading',
  ExpirationDetection = 'expirationDetection',
  TemplateMatching = 'templateMatching',
  AgeThreshold = 'ageThreshold',
  DuplicateUserDetection = 'duplicateUserDetection',
  AlterationDetection = 'alterationDetection',
  Facematch = 'facematch',
}

export function getDocumentStepsSettingError(flow: IFlow, productsInGraph?: ProductTypes[]): Nullable<string> {
  const neededSteps = flow?.verificationSteps?.filter((group) => intersection(group, [DocumentTypes.Passport, DocumentTypes.NationalId, DocumentTypes.DrivingLicense, DocumentTypes.ProofOfResidency]).length > 0);
  const dataSourceOfGovCheck = flow?.productSettings?.IdentityValidation?.dataSource;
  const productsToCheck = [ProductTypes.AmlCheck];
  if (dataSourceOfGovCheck !== VerificationInputTypes.CustomField) {
    productsToCheck.push(ProductTypes.IdentityValidation);
  }
  const isCantUseProofOfResidency = productsInGraph.some((product) => productsToCheck.includes(product)) && neededSteps?.length > 0 && neededSteps[0]?.every((step) => step === DocumentTypes?.ProofOfResidency);

  if (isCantUseProofOfResidency) {
    return 'DocumentVerification.settings.warning.cantUsePoo';
  }
  return null;
}

export enum BusinessRegionsTypes {
  AFRICA = 'Africa',
  LATIN_AMERICA = 'Latin America',
}
export interface ICountriesSettings {
  country: String;
  documentSubtypes: String[];
}

export enum DocumentDuplicateUserRejectionFlowTypes {
  ManualReview = 'manualReview',
  Rejected = 'rejected',
}
