import Grid from '@material-ui/core/Grid';
import { ChecksByDocument } from 'apps/ui';
import React, { ComponentType } from 'react';
import { hasMinorError, StepStatus } from 'models/Step.model';
import type { GovernmentChecksStep } from 'models/GovernmentChecksMerit.model';
import { IGovernmentChecksVerificationData } from '../../models/GovCheckMerit.model';
import { GovCheckMinorError } from './components/GovCheckMinorError/GovCheckMinorError';
import { GovCheckSuccess } from './components/GovCheckSuccess/GovCheckSuccess';
import { GovCheckError } from './components/GovCheckError/GovCheckError';
import { GovCheckInfo } from './components/GovCheckInfo/GovCheckInfo';

const getComponentByStep = (step: GovernmentChecksStep): ComponentType<{ step: GovernmentChecksStep }> => {
  if (hasMinorError(step.id, step.error?.code)) {
    return GovCheckMinorError;
  }

  if (step.checkStatus === StepStatus.Failure) {
    return GovCheckError;
  }

  if (step.checkStatus === StepStatus.Success) {
    return GovCheckSuccess;
  }

  return GovCheckInfo;
};

export function GovCheckVerification({ data }: {
  data: IGovernmentChecksVerificationData;
}) {
  return (
    <Grid container spacing={2}>
      {data.document?.map(({
        steps,
        type,
        country,
        photos,
      }, index) => (
        <Grid key={type || index} item xs={12} xl={8}>
          <ChecksByDocument photos={photos} country={country} docType={type}>
            {steps.map((step) => {
              const StepComponent = getComponentByStep(step);
              return <StepComponent step={step} key={step.id} />;
            })}
          </ChecksByDocument>
        </Grid>
      ))}
      {data.rootSteps?.map((step) => {
        const StepComponent = getComponentByStep(step);
        return <StepComponent step={step} key={step.id} />;
      })}
    </Grid>
  );
}
