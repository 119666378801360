import React from 'react';
import { useFormatMessage } from 'apps/intl';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import { QATags } from 'models/QA.model';
import { useStyles } from './ProfileInsightsButton.styles';
import { toggleProfileInsight } from '../../store/ProfileInsights.action';
import { ReactComponent as ProfileInsightsIcon } from '../../assets/profileInsights.svg';

export function ProfileInsightsButton({ profileId }: {profileId: string}): JSX.Element {
  const formatMessage = useFormatMessage();

  const dispatch = useDispatch();
  const classes = useStyles();

  const handleProfileInsight = () => {
    dispatch(toggleProfileInsight(profileId));
  };

  return (
    <Button
      data-qa={QATags.ProfileInsights.Button}
      variant="contained"
      onClick={handleProfileInsight}
      className={classes.profileInsightButton}
      size="large"
    >
      <ProfileInsightsIcon className={classes.labelIcon} />
      {formatMessage('ProfileInsights.title')}
    </Button>
  );
}
