import React, { useCallback } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Close from '@material-ui/icons/Close';
import { FiSettings } from 'react-icons/fi';
import { useOverlay } from 'apps/overlay';
import { IntegrationType } from 'apps/Integration';
import { ForDevsWebhookModal } from 'apps/forDevelopers/components/ForDevsWebhookModal/ForDevsWebhookModal';
import { useFormatMessage } from 'apps/intl';
import { PermissionSubscriptionStatusTypes, LimiterSubscriptionStatus } from 'apps/Permissions';
import { useStyles } from './FlowBuilderIntegrationDetails.styles';

export function FlowBuilderIntegrationDetails({ closePanel }: { closePanel: () => void }) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const [createOverlay] = useOverlay();

  const handleOpenWebhookModal = useCallback(() => {
    createOverlay(<ForDevsWebhookModal />);
  }, [createOverlay]);

  return (
    <Box p={0.4} className={classes.root}>
      <Box p={1.6} pt={0.6} className={classes.container}>
        <Box mb={1}>
          <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap">
            <Box fontWeight="bold" color="common.black90">
              {formatMessage('FlowBuilder.integration')}
            </Box>
            <Box display="flex" alignItems="center">
              <LimiterSubscriptionStatus permission={PermissionSubscriptionStatusTypes.canUseWebhooksModal}>
                <Button color="primary" variant="text" onClick={handleOpenWebhookModal}>
                  <FiSettings size={17} />
                  <Box fontWeight="bold" fontSize={14} ml={0.5} component="span">
                    {formatMessage('forDevs.webhook.button')}
                  </Box>
                </Button>
              </LimiterSubscriptionStatus>
              <Box ml={2} onClick={closePanel} className={classes.closeButton}>
                <Close />
              </Box>
            </Box>
          </Grid>
        </Box>
        <IntegrationType />
      </Box>
    </Box>
  );
}
