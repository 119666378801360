import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  buttonPreview: {
    borderColor: theme.palette.common.whiteblue,
    color: theme.palette.common.lightblue,
    minWidth: 120,
    height: 43,
    minHeight: 43,
    marginLeft: 10,
    fontSize: 14,
    fontWeight: 'bold',
    '& svg': {
      marginRight: 5,
    },
    '&:disabled': {
      color: theme.palette.common.lightblue,
      opacity: 0.5,
    },
  },
  issues: {
    color: theme.palette.common.red,
    position: 'absolute !important' as any,
    right: 355,
    top: -3,
  },
  unsavedChanges: {
    position: 'absolute !important' as any,
    right: 355,
    top: -3,
  },
}));
