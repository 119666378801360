import React, { useEffect } from 'react';
import { Box, Typography, Grid } from '@material-ui/core';
import { useFormatMessage } from 'apps/intl';
import { PageLoader } from 'apps/layout';
import { Modal } from 'apps/overlay';
import { useDispatch, useSelector } from 'react-redux';
import { selectPreviewFlowModel } from 'apps/flowBuilder';
import { useMerchantPlan } from 'apps/merchant';
import { TestIt } from 'apps/flowBuilder/TestIt/TestIt';
import { UpgradeFreemiumButton } from 'apps/SubscriptionStatus';
import { QATags } from 'models/QA.model';
import { merchantLoad, selectMerchantTrialQuota } from 'state/merchant';
import { useStyles } from './PreviewModal.styles';
import { PreviewGallery } from '../PreviewGalery/PreviewGalery';
import { SDKCustomizationTabs } from '../SDKCustomizationTabs/SDKCustomizationTabs';

export function PreviewModal({ onClose }) {
  const dispatch = useDispatch();
  const formatMessage = useFormatMessage();
  const classes = useStyles();
  const previewList = useSelector(selectPreviewFlowModel);
  const { isFreemium, isFreeTrial } = useMerchantPlan();
  const quota = useSelector(selectMerchantTrialQuota);
  const isShowFreemiumBanner = isFreemium && quota.verifications.used >= quota.verifications.total;

  // trigger merchant load to update remaining verifications quota
  useEffect(() => {
    if (isFreemium || isFreeTrial) {
      dispatch(merchantLoad());
    }
  }, [dispatch, isFreemium, isFreeTrial]);

  return (
    <Modal
      onClose={onClose}
      className={classes.modal}
    >
      <Grid item container className={classes.container}>
        <Grid item container xs={8}>
          <Box className={classes.titleWrapper}>
            <Typography variant="h3" gutterBottom>
              {formatMessage('FlowBuilder.previewModal.title')}
            </Typography>
            <Box className={classes.subtitle}>
              {formatMessage('FlowBuilder.previewModal.subtitle')}
            </Box>
          </Box>
        </Grid>
        <Grid item container xs={4} justifyContent="flex-end">
          {isShowFreemiumBanner ? (
            <UpgradeFreemiumButton
              text={formatMessage('FlowBuilder.previewModal.freemiumCTA.button')}
              intercomMessage={formatMessage('FlowBuilder.previewModal.freemiumCTA.message')}
              dataQa={QATags.FlowBuilder.UpgradeFreemiumPreview}
            />
          ) : (
            <TestIt onClose={onClose} />
          )}
        </Grid>
      </Grid>
      <Grid item container className={classes.bottomContainer}>
        <Grid item className={classes.sdkLeftPanel}>
          <Box m="auto 0">
            <Grid item container alignItems="center" className={classes.previewContainer}>
              { previewList.isLoading ? (
                <PageLoader />
              ) : (
                <PreviewGallery screenshots={previewList.value.screenshots} />
              )}
            </Grid>
          </Box>
        </Grid>
        <Grid item className={classes.sdkRightPanel}>
          <SDKCustomizationTabs />
        </Grid>
      </Grid>
    </Modal>
  );
}
