import React, { } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useFormatMessage } from 'apps/intl';
import { useOverlay } from 'apps/overlay';
import { useStyles } from './TestIt.styles';
import { TestItModal } from '../TestItModal/TestItModal';
import { ReactComponent as TestItIcon } from '../assets/icon-testit.svg';

export function TestIt({ onClose }: { onClose?: () => void }) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const [createOverlay, closeOverlay] = useOverlay();

  const openTestItModal = () => {
    onClose();
    createOverlay(<TestItModal onClose={closeOverlay} />);
  };

  return (
    <Grid item container justifyContent="flex-end">
      <Button
        className={classes.testit}
        color="default"
        variant="outlined"
        onClick={openTestItModal}
      >
        <TestItIcon />
        {formatMessage('FlowBuilder.button.testit')}
      </Button>
    </Grid>
  );
}
