import { useScrollMeIntoView } from 'lib/ScrollIntoView';
import React, { useCallback, useState, useEffect } from 'react';
import { ProductSettingsProps } from 'models/Product.model';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { ExtendedDescription, BoxBordered, FlowError } from 'apps/ui';
import cloneDeep from 'lodash/cloneDeep';
import { useDebounce } from 'lib/debounce.hook';
import { useFormatMessage } from 'apps/intl';
import { VerificationPatternsConfigType } from 'models/VerificationPatternsConfigs.model';
import { FacematchSourceTypes } from 'models/Facematch.model';
import { FacematchCheckSettingsTypes, IFacematchSource, FACEMATCH_SOURCES_COUNT, getThresholdValue } from '../../models/Facematch.model';
import { TextFieldInputScore, useStyles } from './FacematchSetting.styles';
import { FacematchSource } from '../FacematchSourceSelect/FacematchSource';

const RECOMMENDED_THRESHOLD_PERCENT = 70;

export function FacematchSettings({ settings, onUpdate }: ProductSettingsProps<FacematchCheckSettingsTypes>) {
  const formatMessage = useFormatMessage();
  const debounced = useDebounce();
  const classes = useStyles();
  const [rejectThreshold, setRejectThreshold] = useState<number>(settings[FacematchCheckSettingsTypes.RejectThreshold].value);
  const [approveThreshold, setApproveThreshold] = useState<number>(settings[FacematchCheckSettingsTypes.ApproveThreshold].value);
  const [sources, setSources] = useState<IFacematchSource[]>([]);
  const [error, setError] = useState<Nullable<string>>(null);
  const ScrollMeIntoView = useScrollMeIntoView();

  const availableSources = Object.values(FacematchSourceTypes);

  useEffect(() => {
    setSources(settings[FacematchCheckSettingsTypes.Sources].value);
  }, [settings]);

  const handleMultipleUpdate = useCallback((setts: { settingId: FacematchCheckSettingsTypes; value: unknown }[]) => {
    const newSettings = cloneDeep(settings);
    setts.forEach(({ settingId, value }) => {
      newSettings[settingId].value = value;
    });
    onUpdate(newSettings);
  }, [onUpdate, settings]);

  const handleRejectThresholdChange = useCallback(({ target: { value } }) => {
    const result = getThresholdValue(value);
    setRejectThreshold(result);
    debounced(() => handleMultipleUpdate([{ settingId: FacematchCheckSettingsTypes.RejectThreshold, value: result }]));
  }, [handleMultipleUpdate, debounced]);

  const handleApproveThresholdChange = useCallback(({ target: { value } }) => {
    const result = getThresholdValue(value);
    setApproveThreshold(result);
    debounced(() => handleMultipleUpdate([{ settingId: FacematchCheckSettingsTypes.ApproveThreshold, value: result }]));
  }, [handleMultipleUpdate, debounced]);

  const handleValidation = useCallback(() => {
    const isValid = rejectThreshold < approveThreshold;
    setError(!isValid ? formatMessage('Facematch.issues.rejectLessThanApprove.description') : null);
  }, [rejectThreshold, approveThreshold, formatMessage]);

  const handleSourceChange = useCallback((index: number, source: Partial<IFacematchSource>, setts: { settingId: FacematchCheckSettingsTypes; value: unknown }[] = []) => {
    const newSources = cloneDeep(sources);

    newSources[index] = {
      ...newSources[index],
      ...source,
    };

    if (source.type) {
      switch (source.type) {
        case FacematchSourceTypes.Document: {
          newSources[index].options = {
            verificationStepIndex: 0,
          };
          break;
        }
        case FacematchSourceTypes.GovermentCheck: {
          const patternsConfigs: VerificationPatternsConfigType = settings[FacematchCheckSettingsTypes.PatternsConfig].value;
          const verificationPatterns = settings[FacematchCheckSettingsTypes.CountriesGovChecks].value;
          newSources[index].options = {
            govCheckIds: Object.entries(patternsConfigs)
              .filter(([pattern, configs]) => verificationPatterns[pattern] === true || verificationPatterns[pattern] === configs.find((config) => config.isFacematchSource).patternValue)
              .map(([pattern]) => pattern),
          };
          break;
        }
        default:
          delete newSources[index].options;
          break;
      }
    }

    const newSettings = setts.concat({ settingId: FacematchCheckSettingsTypes.Sources, value: newSources });

    handleMultipleUpdate(newSettings);
  }, [handleMultipleUpdate, settings, sources]);

  return (
    <Box>
      {settings[FacematchCheckSettingsTypes.Sources].error && (
        <Box width="100%">
          <ScrollMeIntoView />
          <FlowError text={formatMessage(settings[FacematchCheckSettingsTypes.Sources].error)} />
        </Box>
      )}
      <Box>
        {[...Array(FACEMATCH_SOURCES_COUNT)].map((_key, index) => (
          <Box key={index}>
            <Box mb={0.5} mt={1} fontWeight="bold" color="common.black90">{formatMessage('Facematch.settings.source.title', { messageValues: { index: index + 1 } })}</Box>
            <FacematchSource
              index={index}
              availableSources={availableSources}
              sources={sources}
              handleSourceChange={handleSourceChange}
              patternsConfig={settings[FacematchCheckSettingsTypes.PatternsConfig].value}
              documentTypes={settings[FacematchCheckSettingsTypes.DocumentTypes].value}
            />
          </Box>
        ))}
      </Box>
      <Box mt={3}><Divider className={classes.divider} /></Box>
      <Box mt={2}>
        <ExtendedDescription
          title={formatMessage(`Facematch.settings.${FacematchCheckSettingsTypes.FacematchSettings}.title`)}
          text={formatMessage(`Facematch.settings.${FacematchCheckSettingsTypes.FacematchSettings}.description`)}
        />
        <Box mt={2}>
          <Grid container spacing={1}>
            <Grid item xs={9}><Box mb={0.5} fontWeight="bold" color="common.black90">{formatMessage('Facematch.settings.rejectThreshold.title')}</Box></Grid>
            <Grid item xs={3}>
              <TextFieldInputScore
                value={rejectThreshold}
                onChange={handleRejectThresholdChange}
                onBlur={handleValidation}
                error={!!error}
              />
            </Grid>
            <Grid item xs={9}><Box mb={0.5} fontWeight="bold" color="common.black90">{formatMessage('Facematch.settings.approveThreshold.title')}</Box></Grid>
            <Grid item xs={3}>
              <TextFieldInputScore
                value={approveThreshold}
                onChange={handleApproveThresholdChange}
                onBlur={handleValidation}
                error={!!error}
              />
            </Grid>
            <Grid item xs={12}>
              {error && <Box mb={0.5} fontWeight="bold" color="common.red">{error}</Box>}
            </Grid>
          </Grid>
        </Box>
        <Box mt={2}>
          <BoxBordered px={1.7} py={1.7}>
            <Box>
              <ExtendedDescription
                title={formatMessage('Facematch.settings.scoreDescription.title')}
              />
            </Box>
            <Box mt={2}>
              <ExtendedDescription
                title={formatMessage('Facematch.settings.scoreDescription.recommended.title')}
                titleColor="common.black75"
                text={formatMessage('Facematch.settings.scoreDescription.recommended.text', { messageValues: { percent: RECOMMENDED_THRESHOLD_PERCENT } })}
              />
            </Box>
            <Box mt={2}>
              <ExtendedDescription
                title={formatMessage('Facematch.settings.scoreDescription.low.title')}
                titleColor="common.black75"
                text={formatMessage('Facematch.settings.scoreDescription.low.text')}
              />
            </Box>
            <Box mt={2}>
              <ExtendedDescription
                title={formatMessage('Facematch.settings.scoreDescription.high.title')}
                titleColor="common.black75"
                text={formatMessage('Facematch.settings.scoreDescription.high.text')}
              />
            </Box>
          </BoxBordered>
        </Box>
      </Box>
    </Box>
  );
}
