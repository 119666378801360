import { useChiliPiper } from 'apps/ChiliPiperIntegration';
import { useHistory } from 'react-router-dom';
import { Routes } from 'models/Router.model';

export function useFreemium() {
  const chiliPiperSubmit = useChiliPiper();
  const history = useHistory();

  return {
    upgrade: () => chiliPiperSubmit(),
    comparePlans: () => history.push(Routes.freemiumVsPreemium.root),
  };
}

