import { LoadableAdapter } from 'lib/Loadable.adapter';
import { createReducer } from 'state/store.utils';
import { IProfileInsightsStore, ProfileInsightsSliceTypes, ProfileInsightsActionTypes } from './ProfileInsights.store';

const initialState: IProfileInsightsStore = {
  [ProfileInsightsSliceTypes.ProfileInsightSideBar]: false,
  [ProfileInsightsSliceTypes.ProfileInsightId]: undefined,
  [ProfileInsightsSliceTypes.ProfileInsightUserMerits]: LoadableAdapter.createState(null),
  [ProfileInsightsSliceTypes.ProfileInsightUserHistory]: LoadableAdapter.createState(null),
  [ProfileInsightsSliceTypes.ProfileInsightUserContact]: LoadableAdapter.createState(null),
  [ProfileInsightsSliceTypes.ProfileInsightVerificationHistory]: LoadableAdapter.createState(null),
  [ProfileInsightsSliceTypes.ProfileInsightRiskAssessment]: LoadableAdapter.createState(null),
};

export const profileInsightsReducer = createReducer(initialState, {
  ...LoadableAdapter.createActionHandlers(ProfileInsightsSliceTypes.ProfileInsightUserMerits),
  ...LoadableAdapter.createActionHandlers(ProfileInsightsSliceTypes.ProfileInsightUserContact),
  ...LoadableAdapter.createActionHandlers(ProfileInsightsSliceTypes.ProfileInsightUserHistory),
  ...LoadableAdapter.createActionHandlers(ProfileInsightsSliceTypes.ProfileInsightVerificationHistory),
  ...LoadableAdapter.createActionHandlers(ProfileInsightsSliceTypes.ProfileInsightRiskAssessment),
  [ProfileInsightsActionTypes.OPEN_PROFILE_INSIGHT](state) {
    return {
      ...state,
      profileInsightSideBar: true,
    };
  },
  [ProfileInsightsActionTypes.CLOSE_PROFILE_INSIGHT](state) {
    return {
      ...state,
      profileInsightSideBar: false,
    };
  },
  [ProfileInsightsActionTypes.ADD_PROFILE_INSIGHT_ID](state, { payload }) {
    return {
      ...initialState,
      [ProfileInsightsSliceTypes.ProfileInsightId]: payload,
      profileInsightSideBar: true,
    };
  },
  [ProfileInsightsActionTypes.CLEAR_PROFILE_INSIGHT_ID]() {
    return {
      ...initialState,
    };
  },
});
