import { Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ScheduleIcon from '@material-ui/icons/Schedule';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { EditableText } from 'apps/ui';
import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { Modal, useOverlay } from 'apps/overlay';
import { useMerchantPlan } from 'apps/merchant';
import { useFreemium } from 'apps/Freemiun';
import { useFormatMessage } from 'apps/intl';
import { appcues, CustomAppcuesActions } from 'apps/Appcues';
import { navigation, NavigationEventTypes } from 'apps/layout';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { IFlow } from 'models/Flow.model';
import { selectCurrentFlow } from 'state/merchant/merchant.selectors';
import { QATags } from 'models/QA.model';
import { Routes } from 'models/Router.model';
import { Save } from '../Save/Save';
import { Preview } from '../Preview/Preview';
import { selectFlowBuilderChangeableFlow } from '../../store/FlowBuilder.selectors';
import { FlowSettings } from '../FlowSettings/FlowSettings';
import { useStyles } from './FlowHeader.styles';
import { ReactComponent as EditIcon } from '../../assets/icon-edit-button.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icon-settings.svg';
import { ReactComponent as IntegrateIcon } from '../../assets/icon-integrate.svg';


export function FlowHeader({ canEdit, isEditable, newFlowName, setIsEditable, onSubmit, onCancel, validator, isFlowPage, handleIntegrate }: {
  canEdit?: boolean;
  isFlowPage?: boolean;
  isEditable?: boolean;
  newFlowName?: string;
  setIsEditable?: Dispatch<SetStateAction<boolean>>;
  onSubmit?: (text: string) => void;
  onCancel?: () => void;
  validator?: (text: string) => void;
  handleIntegrate?: () => void;
}) {
  const history = useHistory();
  const formatMessage = useFormatMessage();
  const { id } = useParams();
  const { comparePlans } = useFreemium();
  const currentFlowId = useSelector<any, IFlow>(selectCurrentFlow);
  const flowBuilderChangeableFlow = useSelector<any, IFlow>(selectFlowBuilderChangeableFlow);
  const { name } = (!id || !currentFlowId) ? flowBuilderChangeableFlow : currentFlowId;
  const classes = useStyles();
  const [createOverlay, closeOverlay] = useOverlay();
  const handleOpenFlowSettings = useCallback(() => {
    createOverlay(<Modal className={classes.modal} onClose={closeOverlay}><FlowSettings onClose={closeOverlay} /></Modal>);
  }, [classes.modal, closeOverlay, createOverlay]);
  const { isFreemium } = useMerchantPlan();
  const handleOpenHistory = () => history.push(Routes.flow.history.replace(':id', id));

  return (
    <Box>
      <Box color="common.black90" fontSize={24}>
        <Grid id="flow-name" item container className={classes.field} justifyContent="space-between">
          {canEdit ? (
            <Grid item container className={classes.editField}>
              <EditableText
                enabled={isEditable}
                setEditable={setIsEditable}
                submitEditable={onSubmit}
                cancelEditable={onCancel}
                validator={validator}
                value={newFlowName || name}
                qaTag={QATags.FlowBuilder.Settings.FlowNameInput}
              />
              {!isEditable && (
              <IconButton
                className={classes[isFlowPage ? 'button' : 'settingsButton']}
                onClick={() => {
                  setIsEditable((prevState: boolean) => !prevState);
                  appcues.action(CustomAppcuesActions.EditedFlowName);
                }}
                data-qa={QATags.FlowBuilder.Settings.EditFlowNameButton}
              >
                <EditIcon />
              </IconButton>
              )}
            </Grid>
          ) : name}
          <Grid item container alignItems="center" direction="row-reverse">
            <Box>
              <Button
                id="integrate-flow-button"
                className={classes.integrateButton}
                variant="outlined"
                disableElevation
                onClick={handleIntegrate}
              >
                <IntegrateIcon />
                {formatMessage('FlowBuilder.button.integrate')}
              </Button>
            </Box>
            <Box>
              <Preview />
            </Box>
            <Box>
              <Button
                id="flow-history-button"
                className={classes.historyButton}
                variant="outlined"
                disableElevation
                onClick={() => {
                  const interrupted = navigation.trigger(NavigationEventTypes.BeforeNavigation, handleOpenHistory);
                  if (!interrupted) {
                    handleOpenHistory();
                  }
                }}
              >
                <ScheduleIcon />
                {formatMessage('FlowBuilder.button.history')}
              </Button>
            </Box>
            <Box>
              <Save newFlowName={newFlowName} />
            </Box>
            <Box>
              <Button
                id="flow-settings-button"
                className={classes.settingsButton}
                variant="outlined"
                disableElevation
                onClick={handleOpenFlowSettings}
              >
                <SettingsIcon />
                {formatMessage('FlowBuilder.button.settings')}
              </Button>
            </Box>
            {isFreemium && (
              <Box>
                <Button
                  id="upgrade-button"
                  data-qa={QATags.Menu.Upgrade}
                  className={classes.upgradeButton}
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    appcues.action(CustomAppcuesActions.ClickedUpgradeButton);
                    comparePlans();
                  }}
                >
                  {formatMessage('FlowBuilder.button.upgrade')}
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
