import { productManagerService } from 'apps/Product';
import { MerchantTagsTypes } from 'models/Merchant.model';
import { ProductTypes } from 'models/Product.model';
import { selectMerchantTags } from 'state/merchant';
import { DocumentVerification } from '../services/DocumentVerification.service';

export const documentVerificationInit = () => (dispatch, getState): ProductTypes => {
  const merchantTags = selectMerchantTags(getState());
  const documentVerification = new DocumentVerification({
    isOnlyFreemiumCountries: merchantTags.includes(MerchantTagsTypes.CanUseOnlyFreemiumDocumentCountries),
  });
  productManagerService.register(documentVerification);
  return documentVerification.id;
};
