import React from 'react';
import { Theme, makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

export const AntTabs = withStyles((theme: Theme) => ({
  root: {
    marginLeft: '24px',
  },
  indicator: {
    backgroundColor: theme.palette.common.black,
  },
}))(Tabs);

export const AntTab = withStyles((theme: Theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    marginRight: theme.spacing(4),
    fontSize: '16px !important',
    fontFamily: 'Inter, Lato, "Helvetica Neue", "sans-serif"',
    fontWeight: 400,
    color: `${theme.palette.common.black} !important`,
    '&:hover': {
      color: theme.palette.common.black,
      opacity: 1,
    },
    '&$selected': {
      color: theme.palette.common.black,
      fontWeight: 700,
    },
    '&:focus': {
      color: theme.palette.common.black,
    },
  },
  selected: {},
  wrapper: {
    flexDirection: 'row-reverse',
  },
}))((props: any) => <Tab disableRipple {...props} />);

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  disabled: {
    opacity: 0.7,
    pointerEvents: 'none',
  },
  tagsBeta: {
    margin: '0px 0px 0px 10px !important',
  },
  freeTrialBanner: {
    width: '65%',
    wordWrap: 'break-word',
    display: 'inline-flex',
    background: theme.palette.common.lightyellow,
    padding: '4px 4px 4px 8px',
    borderRadius: '8px',
  },
  freeTrialBannerInfoIcon: {
    margin: '4px 16px 4px 8px',
    width: '21px',
    height: '21px',
  },
  freeTrialBannerText: {
    fontSize: '12px',
    fontWeight: 500,
    color: theme.palette.common.black90,
    fontFamily: 'Inter, Lato, "Helvetica Neue", "sans-serif"',
  },
  tabsWrapper: {
    backgroundColor: theme.palette.background.paper,
  },
  bottomSectionContainer: {
    padding: '12px',
    height: '64px',
    borderRadius: '0px 0px 12px 12px',
    background: theme.palette.common.white,
    boxShadow: '0px -4px 8px 0px rgba(237, 240, 245, 0.50)',
  },
  bottomSectionSetToDefaultBtn: {
    '&.MuiButtonBase-root': {
      maxHeight: '40px',
      minHeight: '40px',
      padding: '12px',
      fontWeight: 600,
      lineHeight: '24px',
      fontSize: '16px',
      fontFamily: 'Inter, Lato, "Helvetica Neue", "sans-serif"',
      color: theme.palette.common.lightblue,
      backgroundColor: theme.palette.common.white,
      marginRight: '16px',
    },
    '&:hover': {
      color: theme.palette.common.lightblue,
      backgroundColor: theme.palette.common.white,
    },
    '&:focus': {
      backgroundColor: theme.palette.common.white,
    },
  },
  bottomSectionSaveChangesBtn: {
    '&.MuiButtonBase-root': {
      maxHeight: '40px',
      minHeight: '40px',
      fontWeight: 600,
      lineHeight: '24px',
      fontSize: '16px',
      padding: '12px',
      fontFamily: 'Inter, Lato, "Helvetica Neue", "sans-serif"',
      color: theme.palette.common.white,
      backgroundColor: theme.palette.common.lightblue,
    },
    '&.MuiButton-outlined': {
      border: 'none',
    },
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.common.lightblue,
    },
    '&:focus': {
      backgroundColor: theme.palette.common.lightblue,
    },
  },
}));
