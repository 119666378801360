import { notification } from 'apps/ui';
import { ErrorMessagesTokenTypes } from 'models/Error.model';
import { selectCanSeePremiumInsights, selectProfileInsightModel, selectProfileInsightState } from './ProfileInsights.selectors';
import { ProfileInsightsActionTypes } from './ProfileInsights.store';
import * as client from '../client/ProfileInsights.client';

export const profileInsightGetuserHistory = (profileId: string) => async (dispatch) => {
  dispatch({ type: ProfileInsightsActionTypes.profileInsightUserHistory_REQUEST });
  try {
    const { data } = await client.getProfileHistroy({ profileId });
    dispatch({ type: ProfileInsightsActionTypes.profileInsightUserHistory_SUCCESS, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: ProfileInsightsActionTypes.profileInsightUserHistory_FAILURE, error });
    notification.errorFormatMessage(ErrorMessagesTokenTypes.ERROR_COMMON);
  }
};

export const profileInsightGetUserContact = (profileId: string) => async (dispatch) => {
  dispatch({ type: ProfileInsightsActionTypes.profileInsightUserContact_REQUEST });
  try {
    const { data } = await client.getProfileContact({ profileId });
    dispatch({ type: ProfileInsightsActionTypes.profileInsightUserContact_SUCCESS, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: ProfileInsightsActionTypes.profileInsightUserContact_FAILURE, error });
    notification.errorFormatMessage(ErrorMessagesTokenTypes.ERROR_COMMON);
  }
};

export const profileInsightGetUserMerits = (profileId: string) => async (dispatch) => {
  dispatch({ type: ProfileInsightsActionTypes.profileInsightUserMerits_REQUEST });
  try {
    const { data } = await client.getProfileMerits({ profileId });
    const normalizedData = {
      ...data,
      uniqueMerits: data?.uniqueMerits?.map((merit) => merit.replace(/\s|-/g, '')),
    };
    dispatch({ type: ProfileInsightsActionTypes.profileInsightUserMerits_SUCCESS, payload: normalizedData });
  } catch (error) {
    console.error(error);
    dispatch({ type: ProfileInsightsActionTypes.profileInsightUserMerits_FAILURE, error });
    notification.errorFormatMessage(ErrorMessagesTokenTypes.ERROR_COMMON);
  }
};

export const profileInsightGetUserVerificationHistory = (profileId: string) => async (dispatch) => {
  dispatch({ type: ProfileInsightsActionTypes.profileInsightVerificationHistory_REQUEST });
  try {
    const { data } = await client.getProfileVerificationHistory({ profileId });
    dispatch({ type: ProfileInsightsActionTypes.profileInsightVerificationHistory_SUCCESS, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: ProfileInsightsActionTypes.profileInsightVerificationHistory_FAILURE, error });
    notification.errorFormatMessage(ErrorMessagesTokenTypes.ERROR_COMMON);
  }
};

export const profileInsightGetProfileRiskAssessment = (profileId: string) => async (dispatch) => {
  dispatch({ type: ProfileInsightsActionTypes.profileInsightRiskAssessment_REQUEST });
  try {
    const { data } = await client.getProfileRiskAssessment({ profileId });
    dispatch({ type: ProfileInsightsActionTypes.profileInsightRiskAssessment_SUCCESS, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: ProfileInsightsActionTypes.profileInsightRiskAssessment_FAILURE, error });
    notification.errorFormatMessage(ErrorMessagesTokenTypes.ERROR_COMMON);
  }
};

export const getProfileInsights = (profileId: string) => async (dispatch, getState) => {
  const canSeePremiumInsights = selectCanSeePremiumInsights(getState());

  dispatch(profileInsightGetuserHistory(profileId));
  dispatch(profileInsightGetUserContact(profileId));
  dispatch(profileInsightGetUserMerits(profileId));
  dispatch(profileInsightGetUserVerificationHistory(profileId));

  if (canSeePremiumInsights) {
    dispatch(profileInsightGetProfileRiskAssessment(profileId));
  }
};

export const openProfileInsights = (profileId: string) => async (dispatch, getState) => {
  const storeProfileId = selectProfileInsightModel(getState());
  if (storeProfileId === profileId) {
    dispatch({ type: ProfileInsightsActionTypes.OPEN_PROFILE_INSIGHT });
  } else {
    dispatch({ type: ProfileInsightsActionTypes.ADD_PROFILE_INSIGHT_ID, payload: profileId });
    dispatch(getProfileInsights(profileId));
  }
};

export const closeProfileInsights = () => async (dispatch) => {
  dispatch({ type: ProfileInsightsActionTypes.CLOSE_PROFILE_INSIGHT });
};

export const toggleProfileInsight = (profileId?: string) => async (dispatch, getState) => {
  const isProfileInsightVisible = selectProfileInsightState(getState());
  if (isProfileInsightVisible) {
    dispatch(closeProfileInsights());
  } else {
    dispatch(openProfileInsights(profileId));
  }
};

export const clearProfileInsights = () => async (dispatch) => {
  dispatch({ type: ProfileInsightsActionTypes.CLEAR_PROFILE_INSIGHT_ID });
};
