import { makeStyles } from '@material-ui/core/styles';
import { DRAWER_WIDTH } from '../../model/ProfileInsights.model';

export const useStyles = makeStyles((theme) => ({
  drawer: {
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: 'transparent',
    left: 'auto !important',
    padding: 8,
  },
  drawerWrapper: {
    background: theme.palette.common.black7,
    borderRadius: 12,
    padding: 12,
    paddingBottom: 0,
    minHeight: '100%',
    overflow: 'hidden',
    overflowY: 'auto',
  },
  drawerOpenMobile: {
    width: 'calc(100% - 60px)',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  button: {
    position: 'fixed',
    top: 16,
    right: 16,
    borderRadius: '50%',
    background: theme.palette.common.black7,
    padding: 6,
    '&:hover': {
      background: theme.palette.common.black7,
    },
  },
  icon: {
    width: 35,
    height: 40,
    color: theme.palette.common.darkgrey,
    padding: 10,
    marginRight: 10,
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    position: 'relative',
  },
  title: {
    fontSize: 21,
    lineHeight: '25px',
    flexGrow: 1,
    color: theme.palette.common.darkgrey,
  },
  listSubheading: {
    display: 'inline-block',
    marginLeft: 8,
  },
  drawerContent: {
    transform: 'translateX(0)',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.easeInOut,
      duration: 600,
    }),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    overflowY: 'auto',
    color: theme.palette.common.darkgrey,
    fontWeight: 400,
  },

  insightsBlocks: {
    marginTop: 20,
    minHeight: 100,
  },
  insightTitle: {
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '22px',
    color: theme.palette.common.darkgrey,
  },
  insightSubTitle: {
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '15px',
    color: theme.palette.common.darkgrey,
    marginTop: 4,
  },
}));
