import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Spinner } from 'apps/ui';
import { useStyles } from './ProfileInsightCard.styles';

export function ProfileInsightCard({ title, subtitle = null, isLoading = true, isLoaded = false, dataQa, children }: {
  title: string;
  subtitle?: Nullable<string>;
  isLoading?: boolean;
  isLoaded?: boolean;
  dataQa?: string;
  children: React.ReactNode;
}) {
  const classes = useStyles();

  return (
    <Card data-qa={dataQa} raised={false} className={classes.card}>
      <CardContent>
        <Typography variant="body1" className={classes.title}>
          {title}
        </Typography>
        {subtitle && (
          <Typography
            variant="subtitle2"
            className={classes.subtitle}
          >
            {subtitle}
          </Typography>
        )}
        {isLoading && (
          <Box mt={2} mb={2}>
            <Spinner size="medium" />
          </Box>
        )}
        {isLoaded && children}
      </CardContent>
    </Card>
  );
}
