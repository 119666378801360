import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Switch from '@material-ui/core/Switch';
import { BoxBordered, ExtendedDescription, RadioButton, TextFieldName, HOCIsAccessAllowed } from 'apps/ui';
import { useDebounce } from 'lib/debounce.hook';
import { ONLY_NUMBERS_REG_EXP, validateMaxLength, validateEmpty } from 'lib/validations';
import cloneDeep from 'lodash/cloneDeep';
import { ProductSettingsProps } from 'models/Product.model';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { PhoneOwnershipValidationTypes } from 'models/PhoneCheck.model';
import { useFormatMessage } from 'apps/intl';
import { COMPANY_NAME_LENGTH_LIMIT, getDefaultRiskThresholdMode, PhoneCheckSettingTypes, PhoneRiskPredefinedThreshold, PhoneRiskThresholdModes, ScoreMapping, validateRiskThreshold } from '../../models/PhoneCheck.model';
import { TextFieldInputScore, useStyles } from './PhoneCheckSettings.styles';
import { selectIsPhoneValidationDisabled } from '../../state/PhoneCheck.selectors';

export function PhoneCheckSettings({ settings, onUpdate, disabled }: ProductSettingsProps<PhoneCheckSettingTypes>) {
  const formatMessage = useFormatMessage();
  const classes = useStyles();
  const debounced = useDebounce();
  const [isPhoneRiskEnabled, setIsPhoneRiskEnabled] = useState<boolean>(settings[PhoneCheckSettingTypes.PhoneRiskValidation].value);
  const [companyName, setCompanyName] = useState<string>(settings[PhoneCheckSettingTypes.CompanyName].value);
  const [riskScore, setRiskScore] = useState<PhoneRiskPredefinedThreshold | number>(settings[PhoneCheckSettingTypes.PhoneRiskThreshold].value);
  const [currentMethod, setCurrentMethod] = useState<PhoneOwnershipValidationTypes>(settings[PhoneCheckSettingTypes.PhoneOwnershipValidation].value);
  const isPhoneValidationDisabled = useSelector<any, boolean>(selectIsPhoneValidationDisabled);
  const [riskThresholdMode, setRiskThresholdMode] = useState<PhoneRiskThresholdModes>(getDefaultRiskThresholdMode(settings[PhoneCheckSettingTypes.PhoneRiskThreshold].value));
  const [riskThresholdError, setRiskThresholdError] = useState<string>();
  const [companyNameError, setCompanyNameError] = useState<string>('');

  const handleUpdate = useCallback((settingId: PhoneCheckSettingTypes, value: unknown) => {
    const newSettings = cloneDeep(settings);
    newSettings[settingId].value = value;
    onUpdate(newSettings);
  }, [onUpdate, settings]);

  const handleModeChange = useCallback((modeOn: PhoneOwnershipValidationTypes, modeOff?: PhoneOwnershipValidationTypes) => async ({ target: { checked } }) => {
    const value = checked ? modeOn : modeOff;
    setCurrentMethod(value);
    if (value === PhoneOwnershipValidationTypes.None) {
      setIsPhoneRiskEnabled(false);
    }
    handleUpdate(PhoneCheckSettingTypes.PhoneOwnershipValidation, value);
  }, [handleUpdate]);

  const handleCompanyNameChange = useCallback(({ target: { value } }) => {
    const validationError = validateMaxLength(value, COMPANY_NAME_LENGTH_LIMIT) || validateEmpty(value);
    setCompanyNameError(validationError);
    setCompanyName(value);
    debounced(() => handleUpdate(PhoneCheckSettingTypes.CompanyName, value));
  }, [handleUpdate, debounced]);

  const handlePhoneRiskModeChange = useCallback(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setRiskThresholdMode(value as PhoneRiskThresholdModes);
    setRiskScore(ScoreMapping[value]);
    handleUpdate(PhoneCheckSettingTypes.PhoneRiskThreshold, ScoreMapping[value]);
  }, [handleUpdate]);

  const handlePhoneRiskModeToggle = useCallback(({ target: { checked } }) => {
    setIsPhoneRiskEnabled(checked);
    handleUpdate(PhoneCheckSettingTypes.PhoneRiskValidation, checked);
  }, [handleUpdate]);

  const handleRiskScoreChange = useCallback(({ target: { value } }) => {
    const result: PhoneRiskPredefinedThreshold | string = value ? value.replace(ONLY_NUMBERS_REG_EXP, '') : '';
    setRiskScore(Number(result));
    setRiskThresholdError('');
    debounced(() => handleUpdate(PhoneCheckSettingTypes.PhoneRiskThreshold, Number(result)));
  }, [handleUpdate, debounced]);

  const handleRiskScoreBlur = useCallback(({ target: { value } }) => {
    setRiskThresholdError(validateRiskThreshold(parseInt(value, 10)));
    handleUpdate(PhoneCheckSettingTypes.PhoneRiskThreshold, parseInt(value, 10));
  }, [handleUpdate]);

  return (
    <HOCIsAccessAllowed isAccessAllowed={!disabled} meritName={formatMessage('PhoneCheck.card.title')}>
      <Box>
        <Box mb={4}>
          <ExtendedDescription
            title={formatMessage('PhoneCheck.settings.phoneValidation.title')}
            text={formatMessage('PhoneCheck.settings.phoneValidation.description')}
          />
        </Box>
        <Box mb={4}>
          <ExtendedDescription
            title={formatMessage('PhoneCheck.settings.optionalStep.title')}
            info={formatMessage('PhoneCheck.settings.optionalStep.description')}
            prefix={(
              <Switch
                name="makeStepSmsOptional"
                color="primary"
                size="small"
                checked={currentMethod === PhoneOwnershipValidationTypes.SmsOptional}
                onChange={handleModeChange(PhoneOwnershipValidationTypes.SmsOptional, PhoneOwnershipValidationTypes.Sms)}
                disabled={currentMethod === PhoneOwnershipValidationTypes.None || disabled || isPhoneValidationDisabled}
              />
          )}
          />
        </Box>
        <Box mb={4}>
          <ExtendedDescription
            title={formatMessage('PhoneCheck.settings.companyName.title')}
            text={formatMessage('PhoneCheck.settings.companyName.description')}
          />
          <TextFieldName
            type="text"
            value={companyName}
            onChange={handleCompanyNameChange}
            error={!!companyNameError}
            className={classes.companyName}
            helperText={companyNameError && formatMessage(`PhoneCheck.settings.companyName.${companyNameError}`)}
            disabled={currentMethod === PhoneOwnershipValidationTypes.None || disabled}
          />
        </Box>
        <Box mb={4}>
          <ExtendedDescription
            title={formatMessage('PhoneCheck.settings.riskAnalysis.title')}
            info={formatMessage('PhoneCheck.settings.riskAnalysis.description')}
            prefix={(
              <Switch
                name="riskAnalysis"
                color="primary"
                size="small"
                checked={isPhoneRiskEnabled}
                onChange={handlePhoneRiskModeToggle}
                disabled={currentMethod === PhoneOwnershipValidationTypes.None || disabled}
              />
          )}
          />
          {isPhoneRiskEnabled && (
            <RadioGroup
              aria-label="risk-analysis-configuration"
              name="risk-analysis-configuration"
              value={riskThresholdMode}
              onChange={handlePhoneRiskModeChange}
            >
              {Object.values(PhoneRiskThresholdModes).map((mode) => (
                <BoxBordered key={mode} mt={1} width="100%">
                  <FormControlLabel
                    value={mode}
                    control={<RadioButton color="primary" />}
                    className={classes.riskAnalysis}
                    label={(
                      <>
                        <Box mb={0.5} color="common.black90" fontWeight="bold">
                          {formatMessage(`PhoneCheck.settings.riskAnalysis.${mode}.title`, { messageValues: { score: ScoreMapping[mode] } })}
                        </Box>
                        {mode !== PhoneRiskThresholdModes.Custom && (
                        <Box color="common.black75" lineHeight={1.2}>
                          {formatMessage(`PhoneCheck.settings.riskAnalysis.${mode}.description`)}
                        </Box>
                        )}
                        {mode === PhoneRiskThresholdModes.Custom && (
                        <TextFieldInputScore
                          value={riskThresholdMode === mode ? riskScore : ''}
                          onBlur={handleRiskScoreBlur}
                          onChange={handleRiskScoreChange}
                          placeholder={`${PhoneRiskPredefinedThreshold.Min}-${PhoneRiskPredefinedThreshold.Max}`}
                          error={!!riskThresholdError}
                          helperText={riskThresholdError && formatMessage(`PhoneCheck.settings.riskAnalysis.${mode}.validations.${riskThresholdError}`)}
                          disabled={!isPhoneRiskEnabled || disabled}
                        />
                        )}
                      </>
                )}
                    disabled={!isPhoneRiskEnabled || disabled}
                  />
                </BoxBordered>
              ))}
            </RadioGroup>
          )}
        </Box>
      </Box>
    </HOCIsAccessAllowed>
  );
}
