import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { FiChevronDown, FiInfo } from 'react-icons/fi';
import { DateFormatTypes, formatDate } from 'lib/date';
import { useFormatMessage } from 'apps/intl';
import { GaugeChart, GaugeChartTypes } from 'apps/ui';
import { QATags } from 'models/QA.model';
import { Loadable } from 'models/Loadable.model';
import { selectProfileRiskAssessment } from '../../store/ProfileInsights.selectors';
import { IProfileRiskAssessmentResponse, riskScoreLimits, RiskScoreTypes, ProfileRiskInsightTransationsLimit } from '../../model/ProfileInsights.model';
import { ProfileInsightCard } from '../ProfileInsightCard/ProfileInsightCard';
import { useStyles } from './ProfileRiskAssessment.styles';

export function ProfileRiskAssessment() {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const riskAssessmentData = useSelector<any, Loadable<IProfileRiskAssessmentResponse>>(selectProfileRiskAssessment);

  if (riskAssessmentData?.isFailed || riskAssessmentData?.isLoading) return null;

  return (
    <ProfileInsightCard dataQa={QATags.ProfileInsights.Cards.ProfileRiskAssessment.CardName} title={formatMessage('ProfileInsights.profileRiskAssessment.title')} isLoading={riskAssessmentData?.isLoading} isLoaded={riskAssessmentData?.isLoaded}>
      <Box className={classes.root}>
        <Box mb={3.6}>
          <GaugeChart value={riskAssessmentData?.value?.riskScore} type={GaugeChartTypes.RiskAssessment} />
          <Typography className={classes.title}>{formatMessage('ProfileInsights.profileRiskAssessment.chart.title')}</Typography>
          <Typography className={classes.subtitle}>{formatMessage('ProfileInsights.profileRiskAssessment.chart.subtitle')}</Typography>
        </Box>
        <Accordion className={classes.accordion} defaultExpanded>
          <AccordionSummary
            expandIcon={<FiChevronDown />}
          >
            <Typography className={classes.heading}>{formatMessage('ProfileInsights.profileRiskAssessment.mainReasons')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {!riskAssessmentData?.value?.profileRisk && (
              <Box data-qa={QATags.ProfileInsights.Cards.ProfileRiskAssessment.Reason} mt={1} mb={2}>
                <Typography className={classNames(classes.reason, classes.noRiskColor)}>
                  {formatMessage('ProfileInsights.profileRiskAssessment.noReason')}
                </Typography>
              </Box>
            )}
            {riskAssessmentData?.value?.profileRisk && riskAssessmentData?.value?.reason?.length > 0 && (
              <Grid container justifyContent="space-between" alignItems="center">
                {/* Only top 5 reasons need to display */}
                {riskAssessmentData?.value?.reason?.slice(0, ProfileRiskInsightTransationsLimit).filter((reason) => !!reason)
                  .map((reason) => (
                    <React.Fragment data-qa={QATags.ProfileInsights.Cards.ProfileRiskAssessment.Reason} key={reason}>
                      <Grid item xs={11}>
                        <Box mt={1} mb={2}>
                          <Typography className={classes.reason}>{formatMessage(`ProfileInsights.profileRiskAssessment.fraudReason.${reason}.short`)}</Typography>
                        </Box>
                      </Grid>
                      <Grid item container justifyContent="flex-end" xs={1}>
                        <Tooltip arrow title={formatMessage(`ProfileInsights.profileRiskAssessment.fraudReason.${reason}.long`)} placement="top">
                          <Box><FiInfo className={classes.info} /></Box>
                        </Tooltip>
                      </Grid>
                    </React.Fragment>
                  ))}
              </Grid>
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<FiChevronDown />}
          >
            <Typography className={classes.heading}>{formatMessage('ProfileInsights.profileRiskAssessment.transactions')}</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            {riskAssessmentData?.value?.transactions?.slice(0, ProfileRiskInsightTransationsLimit).map((transaction) => (
              <Grid key={transaction.verificationId} data-qa={QATags.ProfileInsights.Cards.ProfileRiskAssessment.Transaction} container spacing={1} justifyContent="space-between" alignItems="center" className={classes.accordionItem}>
                <Grid item xs={2}>
                  <Box className={classNames(classes.riskScore, {
                    [classes.noRisk]: transaction.riskScore <= riskScoreLimits[RiskScoreTypes.NO_RISK],
                    [classes.warningRisk]: (transaction.riskScore > riskScoreLimits[RiskScoreTypes.NO_RISK]) && (transaction.riskScore <= riskScoreLimits[RiskScoreTypes.WARNING]),
                    [classes.highRisk]: transaction.riskScore > riskScoreLimits[RiskScoreTypes.WARNING],
                  })}
                  >
                    {transaction.riskScore}
                  </Box>
                </Grid>
                <Grid item xs={6}><Typography>{transaction.verificationId}</Typography></Grid>
                <Grid item container xs={4} justifyContent="center"><Typography>{formatDate(transaction.completedAt, DateFormatTypes.DateFull)}</Typography></Grid>
              </Grid>
            ))}
          </AccordionDetails>
        </Accordion>
      </Box>
    </ProfileInsightCard>
  );
}
