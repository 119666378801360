import React from 'react';
import { IFlow } from 'models/Flow.model';
import { IconType } from 'react-icons';
import { VerificationResponse } from 'models/VerificationOld.model';
import { VerificationPatternsConfigType } from 'models/VerificationPatternsConfigs.model';
import { ICustomDocumentCURLExampleInputs } from 'apps/customDocument/models/CustomDocument.model';
import { IESignatureApiTemplate } from 'models/ESignature.model';
import { IDocumentCURLExampleInputs } from './Document.model';
import { VerificationPatternTypes } from './VerificationPatterns.model';
import { MerchantTagsTypes } from './Merchant.model';

export enum ProductTypes {
  DocumentVerification = 'DocumentVerification',
  BiometricVerification = 'BiometricVerification',
  AmlCheck = 'AmlCheck',
  LocationIntelligence = 'LocationIntelligence',
  ReVerification = 'ReVerification',
  CreditCheck = 'CreditCheck',
  DeviceFingerPrint = 'DeviceFingerPrint',
  PhoneCheck = 'PhoneCheck',
  EmailCheck = 'EmailCheck',
  CustomDocuments = 'CustomDocuments',
  CertifiedTimestamp = 'CertifiedTimestamp',
  BackgroundCheck = 'BackgroundCheck',
  CustomField = 'CustomField',
  CustomWatchlist = 'CustomWatchlist',
  ESignatureCheck = 'eSignatureCheck',
  BankAccountData = 'BankAccountData',
  Facematch = 'Facematch',
  IdentityValidation = 'IdentityValidation',
  LegalRecords = 'LegalRecords',
  EmploymentInformation = 'EmploymentInformation',
  DrivingLicenseValidation = 'DrivingLicenseValidation',
  TaxChecks = 'TaxChecks',
  BusinessRecordsChecks = 'BusinessRecordsChecks',
  // additional
  Metadata = 'Metadata',
  VideoAgreement = 'VideoAgreement',
  ADFraudScore = 'AlterationDetectionFraudScore',
}

export const GOVERNMENT_CHECKS_PRODUCT_TYPES = [
  ProductTypes.IdentityValidation,
  ProductTypes.LegalRecords,
  ProductTypes.DrivingLicenseValidation,
  ProductTypes.TaxChecks,
  ProductTypes.BusinessRecordsChecks,
];

export const GOVERNMENT_CHECKS_PRODUCT_TYPES_TAGS_MAP = {
  [ProductTypes.IdentityValidation]: MerchantTagsTypes.CanNotUseIdentityValidation,
  [ProductTypes.LegalRecords]: MerchantTagsTypes.CanNotUseLegalRecords,
  [ProductTypes.DrivingLicenseValidation]: MerchantTagsTypes.CanNotUseDrivingLicenceValidation,
  [ProductTypes.TaxChecks]: MerchantTagsTypes.CanNotUseTaxChecks,
  [ProductTypes.BusinessRecordsChecks]: MerchantTagsTypes.CanNotUseBusinessRecordsChecks,
};

export interface ProductSetting {
  value: any;
  error?: Nullable<string>;
  isDisabled?: boolean;
  isRequireOtherProduct?: boolean;
  isCantBeUsedWithOtherSetting?: boolean;
}

export interface ProductCheck<T extends string = string> {
  isActive: boolean;
  id: T;
}

export type ProductSettings<K extends string = string> = Record<K, ProductSetting>;

export interface ProductConfig {
  settings: ProductSettings;
}

export interface ProductSettingsProps<ES extends string = string> {
  settings: ProductSettings<ES>;
  onUpdate: (settings: ProductSettings<ES>) => void;
  disabled?: boolean;
}

export enum ProductIntegrationTypes {
  Sdk = 'sdk',
  Api = 'api',
}

export function isSdkOnly(integrationTypes?: ProductIntegrationTypes[]): boolean {
  return integrationTypes?.length === 1 && integrationTypes?.[0] === ProductIntegrationTypes.Sdk;
}

export enum ProductInputTypes {
  Documents = 'documents',
  NoActiveInputs = 'noActiveInputs',
  PhoneNumber = 'phoneNumber',
  Selfie = 'selfie',
  Liveness = 'liveness',
  NationalId = 'nationalId',
  NameAndDobOrDocument = 'nameAndDobOrDocument',
  EmailAddress = 'emailAddress',
  CustomDataEntry = 'customDataEntry',
  CustomWatchlist = 'customWatchlist',
  CustomDocuments = 'customDocuments',
  Sign = 'sign',
  AccountCredentials = 'accountCredentials',
  BankStatements = 'bankStatements',
  ImageSources = 'imageSources',
  VideoSelfie = 'videoSelfie',
  VoiceRecording = 'voiceRecording'
}

export interface IProductCard {
  id: ProductTypes;
  disabled: boolean;
  icon: IconType | (() => React.ReactNode);
  order: number;
  title: string;
  description: string;
  isShowDescription: boolean;
  inputs: ProductInputTypes[];
  checks: ProductCheck[];
  integrationTypes: ProductIntegrationTypes[];
  requiredProductType?: ProductTypes | ProductTypes[];
  dependentProductTypes?: ProductTypes[];
  isExternal: boolean;
  requiredProductTypeFromSubStep?: Partial<Record<VerificationPatternTypes, ProductTypes>>;
  customChecks?: string[];
  iconUrl?: string;
  getTranslations?: (onLoaded?: (translations: Record<string, any>) => void) => Promise<Record<string, any>>;
}

export interface IRegistryProduct {
  name: string;
  webContainerConfig: {
    buildConfig: any;
    buildName: string;
    resultsBuildUri: string;
    settingsBuildUri: string;
    stepBuildUri: string;
    version: string;
  };
}

export interface IProductSettingsPermissions {
  read: string[];
  modify: string[];
}

export interface IProductTranslations {
  [lang: string]: {
    [key: string]: string;
  };
}

export interface IProductCURLExampleInputs {
  inputType: string;
  data: {
    type: string;
    filename: string;
  };
}

export interface IProductCURLExampleParams {
  inputs?: (IProductCURLExampleInputs | IDocumentCURLExampleInputs | ICustomDocumentCURLExampleInputs)[];
  files?: Nullable<string>[];
  data?: IESignatureApiTemplate;
  productType?: ProductTypes;
}
export interface Product<S = IFlow, T = VerificationResponse> {
  icon: React.ReactNode;
  id: ProductTypes;
  permissions?: IProductSettingsPermissions;
  order: number;
  checks: ProductCheck[];
  integrationTypes: ProductIntegrationTypes[];
  component: any;
  componentVerification: any;
  disabled: boolean;
  isConfigurable: boolean;
  isExternal: boolean;
  verificationPatternsConfig?: VerificationPatternsConfigType;
  parser(flow: S, productsInGraph?: ProductTypes[]): ProductSettings;
  serialize(settings: ProductSettings, flow?: S): Partial<S>;
  onRemove(flow: S): Partial<S>;
  onAdd(flow?: S): Partial<S>;
  getRemovingAlertComponent?(flow: S, productsInGraph?: ProductTypes[]): any;
  getIssuesComponent?(flow: S, productsInGraph?: ProductTypes[]): Nullable<string>;
  getTitle(): string;
  getCard(): IProductCard;
  getVerification(verification: T): any;
  getCURLExampleParams?(flow: S): IProductCURLExampleParams;
  isInFlow(flow: S): boolean;
  hasFailedCheck(verification: T): boolean;
  isInVerification(verification: T): boolean;
  onAddEmpty?(flow?: S): Partial<S>;
}

export const TIME_TO_INTERACTIVE_TIMEOUT = 60 * 1000;

export enum ProductMessageTypes {
  Overlay = 'Overlay',
  Resize = 'Resize',
  Handshake = 'Handshake',
  PushSettings = 'PushSettings',
  PushSettingsTo = 'PushSettingsTo',
  PullSettings = 'PullSettings',
  PullResults = 'PullResults',
  Error = 'Error',
  AddProduct = 'AddProduct',
  ContactSupport = 'ContactSupport',
  Close = 'Close',
}

export function getProductBuildUrl(url: string): string {
  return process.env.REACT_APP_CORS_URL
    ? `/prs/${url}`
    : `${process.env.REACT_APP_PRODUCT_REGISTRY_URL}/${url}`;
}

// LivenessMovement, Email, Phone orders if can-use-reusage and can-use-verification-reusage tags provided
export const REUSAGE_PRODUCT_ORDER = 60;
