import { http } from 'lib/client/http';
import { IProfileMeritResponse, IProfileHistoryResponse, IProfileContactReponse, IProfileVerificationHistoryResponse, IProfileRiskAssessmentResponse, IReusageRequest } from '../model/ProfileInsights.model';

export function getProfileMerits(body: IReusageRequest) {
  return http.post<IProfileMeritResponse>('/reusage/v1/profile/merit', body);
}

export function getProfileHistroy(body: IReusageRequest) {
  return http.post<IProfileHistoryResponse>('/reusage/v1/profile/history', body);
}

export function getProfileContact(body: IReusageRequest) {
  return http.post<IProfileContactReponse>('/reusage/v1/profile/contact', body);
}

export function getProfileVerificationHistory(body: IReusageRequest) {
  return http.post<IProfileVerificationHistoryResponse>('/reusage/v1/profile/status/verification', body);
}

export function getProfileRiskAssessment(body: IReusageRequest) {
  return http.post<IProfileRiskAssessmentResponse>('/reusage/v1/profile/risk', body);
}
