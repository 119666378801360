import React, { ReactNode } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import { IconButton, Tooltip } from '@material-ui/core';
import { FlowError } from '../FlowError/FlowError';
import { ContainerAccessAllowed } from '../HOCIsAccessAllowed';
import { useStyles } from './ExtendedDescription.styles';
import { ReactComponent as QuestionIcon } from './Question.svg';

export function ExtendedDescription({ title, text, prefix, postfix, className, children, error, badge, isDisabled, textFontSize, titleColor = 'common.black90', postfixColor, postfixFontWeight, info }: {
  title: ReactNode;
  text?: ReactNode;
  prefix?: ReactNode;
  postfix?: ReactNode;
  badge?: ReactNode;
  className?: string;
  children?: ReactNode;
  error?: ReactNode;
  isDisabled?: boolean;
  textFontSize?: number;
  titleColor?: string;
  postfixColor?: string;
  postfixFontWeight?: string;
  info?: string | ReactNode;
}) {
  const classes = useStyles();

  return (
    <ContainerAccessAllowed disabled={isDisabled}>
      <Grid container className={classNames(classes.extendedDescription, className)}>
        <Grid item xs={12} container alignItems="flex-end" wrap="nowrap">
          {prefix && <Box mr={0.8}>{prefix}</Box>}
          <Box>
            <Box fontWeight="bold" color={titleColor}>{title}</Box>
          </Box>
          {info && (
          <>
            <Box ml={0.8}>
              <Tooltip
                title={<span style={{ whiteSpace: 'pre-line' }}>{info}</span>}
                arrow
                placement="top"
                classes={{
                  tooltip: classes.tooltip,
                  arrow: classes.customArrow,
                }}
              >
                <IconButton className={classes.infoButton}>
                  <QuestionIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </>
          )}
          <Box ml="auto">
            <Grid item container alignItems="center" wrap="nowrap">
              {badge && <Box mr={1} fontSize={12} fontWeight="bold" className={classes.badge}>{badge}</Box>}
              {postfix && (
                <Box ml={0.7} color={postfixColor} fontWeight={postfixFontWeight}>
                  {postfix}
                </Box>
              )}
            </Grid>
          </Box>
        </Grid>
        {!isDisabled && error && (
          <Box width="100%" mb={1} mt={1}>
            <FlowError text={error} />
          </Box>
        )}
        {text && <Box mr={postfix ? 2.5 : 0} fontSize={textFontSize} color="common.black75">{text}</Box>}
        {children && (
          <Grid item xs={12}>
            <Box mt={2}>
              {children}
            </Box>
          </Grid>
        )}
      </Grid>
    </ContainerAccessAllowed>
  );
}
