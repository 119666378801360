import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  modal: {
    [theme.breakpoints.up('lg')]: {
      width: '90vw',
      height: '82vh',
      maxHeight: '100vh',
      backgroundColor: theme.palette.common.whiteblue,
      marginLeft: 100,
      padding: '16px 16px 0px 16px',
    },
  },
  container: {
    marginTop: 30,
  },
  bottomContainer: {
    height: '100%',
    maxHeight: 700,
    columnGap: '24px',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    padding: 24,
  },
  sdkLeftPanel: {
    maxWidth: 408,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  sdkRightPanel: {
    maxWidth: 907,
    width: '100%',
    zIndex: 1000,
  },
  previewContainer: {
    height: '90%',
  },
  titleWrapper: {
    textAlign: 'left',
    marginLeft: 20,
  },
  subtitle: {
    width: '100%',
    fontSize: 16,
    fontWeight: 400,
  },
}));
