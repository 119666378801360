import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  newFeature: {
    backgroundColor: theme.palette.common.lightyellow,
    color: theme.palette.common.yellow,
    borderRadius: 30,
    display: 'flex',
    flexDirection: 'row',
    padding: '4px 10px',
    marginLeft: 6,
    fontSize: 12,
  },
}));
