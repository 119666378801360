import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  swiper: {
    width: '305px',
    paddingBottom: '8px',
    display: 'flex',
    paddingTop: '20px',
    '& .swiper-wrapper': {
      width: '305px',
    },
  },
  swiperSlide: {
    width: '305px',
    borderRadius: '5px',
    backgroundColor: theme.palette.common.white,
    filter: `border: 1,5px solid ${theme.palette.common.blueopacity}`,
    minWidth: '305px',
    maxWidth: '305px',
    minHeight: '540px',
    maxHeight: '540px',
  },
  card: {
    maxWidth: '100%',
    height: '540px',
  },
  controlsContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  navigationControlLeft: {
    borderRadius: '60px',
    zIndex: 1,
    width: '51px',
    height: '51px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    left: 131,
  },
  navigationControlRight: {
    borderRadius: '60px',
    zIndex: 1,
    width: '51px',
    height: '51px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    left: 509,
  },
  navigationControlHidden: {
    opacity: 0,
    pointerEvents: 'none',
  },
}));
