import { makeStyles } from '@material-ui/core';
import { whiteDrawerWidth } from '../../../dashboard/components/DashboardMenu/DashboardMenu.styles';

export const useStyles = makeStyles((theme) => ({
  link: {
    width: '100%',
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: 50,
    alignItems: 'center',
    lineHeight: 1,
    fontWeight: 'bold',
    padding: '18px 0px 14px 0px',
    transition: 'none',
    color: theme.palette.common.black75,
    borderColor: theme.palette.common.white,
    '&:hover': {
      color: theme.palette.common.black90,
      backgroundColor: theme.palette.common.white,
    },
  },
  active: {
    '& [role="menuitem"]': {
      borderRight: `4px solid ${theme.palette.primary.main}`,
      marginLeft: 4,
      transition: 'border-color 0.33s',
      color: theme.palette.common.black90,
    },
  },
  textBox: {
    fontWeight: 400,
    fontSize: 12,
    maxWidth: whiteDrawerWidth,
  },
}));
