import React from 'react';
import { IconButton } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { ReactComponent as CancelIcon } from '../../../flowBuilder/assets/icon-cancel-button.svg';
import { ReactComponent as SubmitIcon } from '../../../flowBuilder/assets/icon-check-button.svg';

const defaultFontSize = 20;

export const useStyles = makeStyles((theme) => ({
  flowName: {
    flexGrow: 3,
    padding: '16px 0',
    fontSize: defaultFontSize,
    '&:hover': {
      color: theme.palette.common.lightblue,
    },
  },
  editableField: {
    flexGrow: 3,
    '& input': {
      '&::placeholder': {
        color: theme.palette.common.lightblue,
      },
    },
  },
  cssOutlinedInput: {
    paddingRight: 5,
    fontSize: defaultFontSize,
    '&$cssFocused $notchedOutline': {
      borderColor: 'transparent',
    },
    '&:hover $notchedOutline': {
      borderColor: 'transparent',
    },
    '& input': {
      padding: '18px 0 18px 18px',
    },
  },
  cssFocused: {},
  notchedOutline: {
    borderColor: 'transparent',
  },
}));

const buttonMaker = ({ icon }) => withStyles((theme) => ({
  root: {
    width: 25,
    height: 25,
    borderRadius: 4,
    margin: 5,
  },
  label: {
    width: 'auto',
    color: theme.palette.common.darkgrey,
    '& svg': {
      strokeWidth: 1,
    },
  },
}))(({ ...props }: any) => (
  <IconButton {...props} component="span">
    {icon}
  </IconButton>
));

export const SubmitButton = buttonMaker({ icon: <SubmitIcon /> });
export const CancelButton = buttonMaker({ icon: <CancelIcon /> });
