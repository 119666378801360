import { Box, MenuItem } from '@material-ui/core';
import { LanguageList, SupportedLocales } from 'models/Intl.model';
import { QATags } from 'models/QA.model';
import React, { ReactNode, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import { changeLanguage } from 'state/merchant/merchant.actions';
import { selectLanguage } from 'state/merchant/merchant.selectors';
import { IoEarthOutline } from 'react-icons/io5';
import { SelectLight, useStyles } from 'apps/intl/components/IntlButton/IntlButton.styles';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { ReactComponent as TranslationIcon } from '../../assets/translation.svg';
import { ReactComponent as ChevronDown } from '../../assets/chevron-down.svg';
import { ReactComponent as WorldIcon } from '../../assets/world.svg';

const localeLabelsMap: Record<SupportedLocales, string> = {
  [SupportedLocales.EN]: 'English',
  [SupportedLocales.ES]: 'Spanish',
  [SupportedLocales.ptBR]: 'Portuguese-BR',
};

export function IntlButton({ isSync = true, isOldDesign = false, radio, isAlt = false, icon }: {
  isSync?: boolean;
  isOldDesign?: boolean;
  radio?: boolean;
  isAlt?: boolean;
  icon?: ReactNode;
}) {
  const currentLocale: SupportedLocales = useSelector(selectLanguage);
  const dispatch = useDispatch();
  const classes = useStyles({ fullLabel: isOldDesign });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const otherLocales = LanguageList.filter((obj) => obj.locale !== currentLocale);

  const handleLangChange = useCallback((e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    dispatch(changeLanguage(e.target.value, isSync));
  }, [dispatch, isSync]);

  const handleLangChangeRadio = useCallback((_, value) => {
    dispatch(changeLanguage(value, isSync));
  }, [dispatch, isSync]);

  const handleRenderLocale = () => LanguageList.find((obj) => obj.locale === currentLocale).label;

  const handleToggleOpen = () => setIsOpen((prev) => !prev);
  const handleClose = () => setIsOpen(false);
  const handleSelectLanguage = (value: SupportedLocales) => () => {
    dispatch(changeLanguage(value, isSync));
    handleClose();
  };

  if (radio) {
    return (
      <Box>
        <ToggleButtonGroup
          color="standard"
          value={currentLocale}
          exclusive
          onChange={handleLangChangeRadio}
          aria-label="language-picker"
          data-value={currentLocale}
          data-qa={QATags.Menu.LanguageSelect}
          className={classes.radio}
        >
          {LanguageList.map((item) => (
            <ToggleButton key={item.locale} value={item.locale}>{item.locale}</ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Box>
    );
  }

  if (isOldDesign) { // old design sidebar
    return (
      <Box mb={0} display="flex" className={classes.topMenuItem}>
        <IoEarthOutline className={classes.menuIcon} />
        <SelectLight
          disableUnderline
          className={classes.select}
          MenuProps={{ classes: { paper: classes.menuList } }}
          value={currentLocale}
          renderValue={handleRenderLocale}
          onChange={handleLangChange}
          data-value={currentLocale}
          data-qa={QATags.Menu.LanguageSelect}
        >
          {LanguageList.map((item) => (
            <MenuItem key={item.locale} value={item.locale} className={classes.selectItem}>
              {item.label}
            </MenuItem>
          ))}
        </SelectLight>
      </Box>
    );
  }

  if (isAlt) {
    return (
      <Box className={classes.container}>
        <Box onClick={handleToggleOpen} className={classNames(classes.buttonAlt, { [classes.buttonOpenAlt]: isOpen })}>
          <WorldIcon />
          {localeLabelsMap[currentLocale]}
          <Box ml="8px" mr="8px" display="flex" alignItems="center">
            <ChevronDown />
          </Box>
        </Box>
        {isOpen && (
          <Paper className={classes.content}>
            {otherLocales.map((item) => (
              <Box key={item.locale} onClick={handleSelectLanguage(item.locale)} className={classes.itemAlt}>
                {localeLabelsMap[item.locale]}
              </Box>
            ))}
          </Paper>
        )}
      </Box>
    );
  }

  return (
    <Box className={classes.container}>
      <Box onClick={handleToggleOpen} className={classNames(classes.button, { [classes.buttonOpen]: isOpen })}>
        { icon || <TranslationIcon /> }
        {localeLabelsMap[currentLocale]}
        <Box ml="8px" mr="8px" display="flex" alignItems="center">
          <ChevronDown />
        </Box>
      </Box>
      {isOpen && (
        <Paper className={classes.content}>
          {otherLocales.map((item) => (
            <Box key={item.locale} onClick={handleSelectLanguage(item.locale)} className={classes.item}>
              {localeLabelsMap[item.locale]}
            </Box>
          ))}
        </Paper>
      )}
    </Box>
  );
}
