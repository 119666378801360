import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  formControl: {
    marginLeft: '0px',
    '& .MuiFormControlLabel-label': {
      fontFamily: 'Inter, Lato, "Helvetica Neue", "sans-serif"',
      fontSize: '16px',
      fontWeight: 500,
      color: theme.palette.common.black,
    },
  },
  logoPreviewSectionHeading: {
    fontFamily: 'Inter, Lato, "Helvetica Neue", "sans-serif"',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
    color: theme.palette.common.black75,
  },
  logoPreviewSectionText: {
    fontFamily: 'Inter, Lato, "Helvetica Neue", "sans-serif"',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    color: theme.palette.common.black75,
  },
  defaultLogoPreviewSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoPreviewSection: {
    padding: '42px 21px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '6px',
    border: `2px dashed ${theme.palette.common.wfBase500}`,
    margin: '16px 0px',
  },
  uploadButton: {
    '&.MuiButtonBase-root': {
      marginBottom: '24px !important',
      height: '40px',
      minHeight: '40px',
      maxHeight: '40px',
      padding: '4px 8px',
      justifyContent: 'center',
      alignItems: 'center',
      background: `${theme.palette.common.lightblue} !important`,
      color: `${theme.palette.common.white} !important`,
      fontFamily: 'Inter, Lato, "Helvetica Neue", "sans-serif"',
      fontSize: '16px',
      fontWeight: 400,
      borderRadius: '6px',
    },
    '& .MuiButton-label': {
      background: theme.palette.common.lightblue,
      color: theme.palette.common.white,
    },
    '&.MuiButton-outlined': {
      border: 'none',
    },
    '&:hover': {
      background: theme.palette.common.lightblue,
      color: theme.palette.common.white,
    },
    '&:focus': {
      background: theme.palette.common.lightblue,
      color: theme.palette.common.white,
    },
  },
  addLogo: {
    maxWidth: 300,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    wordBreak: 'break-all',
    whiteSpace: 'normal',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    outline: 'none',
    flexGrow: 1,
  },
  logoPreview: {
    maxWidth: 110,
    maxHeight: 60,
    backgroundSize: 'cover',
    width: 'auto',
    height: 'auto',
  },
}));
