import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  subTitle: {
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.palette.common.black90,
  },
  title: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14px',
  },
  displayItem: {
    color: theme.palette.common.black75,
  },
}));
